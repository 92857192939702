export let Global = {
  home: 'Início',
  property: 'Imóvel',
  properties: 'Imóveis',
  buy: 'Comprar',
  rent: 'Arrendar',
  favorites: 'Favoritos',
  price: 'Preço',
  priceRequest: 'Sob consulta',
  bedrooms: 'Quartos',
  bathRooms: 'WC´s',
  area: 'Área',
  areas: 'Áreas',
  aream2: 'Área m2',
  floorArea: 'Área bruta',
  nettArea: 'Área útil',
  m2: 'm2',
  garages: 'Garagens',
  condition: 'Estado',
  purpose: 'Finalidade',
  type: 'Tipo',
  district: 'Distrito',
  state: 'Estado',
  island: 'Ilha',
  province: 'Província',
  county: 'Concelho',
  countyMZ: 'Município',
  parish: 'Freguesia',
  city: 'Cidade',
  neighborhood: 'Bairro',
  description: 'Descrição',
  noDescription: 'Sem descrição.',
  reference: 'Referência',
  announce: 'Anunciar',
  contacts: 'Contactos',
  haveQuestions: 'Tem alguma dúvida?',
  contactUs: 'Contacte-nos',
  cookieConsent: 'Nós usamos cookies para melhorar a experiência de utilização no nosso site.',
  cookieConsentInfo: 'Ao utilizar o website, confirma que aceita o uso de cookies.',
  cookieConsentMore: 'Saber mais',
  cookieConsentAccept: 'Aceitar',
  copyright: 'Copyright © 2019 - Casa e Sol',
  developer: 'Improxy - Software e Internet',
  developed: 'desenvolvido por: ',
  search: 'Procurar',
  location: 'Localização',
  features: 'Características',
  interest: 'Interessado neste imóvel?',
  contactAgent: 'Contacte o Anunciante',
  taxNumber: 'Nº contribuinte',
  taxNumberMZ: 'NUIT',
  taxNumberBR: 'CPF',
  lastVisitedTitle: 'Ultimos imóveis vistos',
  copyright: '© 2019 Casa e Sol. Todos os direitos reservados',
  terms: 'Termos de Uso',
  policy: 'Política de Privacidade',
  resolution: 'Resolução Alternativa de Litígios',
  design: 'Designed and Developed by Improxy',
  findProperty: 'Encontre o imóvel perfeito para si',
  highlights: 'Destaques',
  findLocation: 'Encontre o local perfeito',
  cities: 'Cidades Populares',
  sellRent: 'Tem um imóvel para vender/arrendar?',
  discloseProperty:
    'Nós iremos divulgar o seu imóvel pelos melhores profissionais do imobiliário localizados no concelho onde está localizado o seu imóvel.',
  discloseHere: 'Divulgue aqui',
  welcome: 'Bem vindo ao portal Casa e Sol',
  findBuild: 'Encontre',
  dreamHome: 'O imóvel perfeito para si!',
  discover: 'Descubra o que temos para oferecer',
  gallery: 'Galeria',
  suggestForm: 'Recomendar Imóvel',
  next: 'Seguinte',
  previous: 'Anterior',
  noResults: 'Sem favoritos',
  noResultsSub: 'Não selecionou qualquer imóvel como favorito.',
  seePhoneContact: 'Ver número',
  agent: 'Anunciante',
  addFavorite: 'Imóvel marcado como favorito!',
  removeFavorite: 'Imóvel removido dos favoritos!',
  favoritesFull: 'A lista de favoritos está cheia !'
};
export let GlobalForm = {
  required: 'Campo obrigatório',
  name: 'Nome',
  email: 'Email',
  phone: 'Telefone',
  message: 'Mensagem',
  send: 'Enviar',
  close: 'Fechar',
  select: 'Selecionar',
  minimum: 'Mínimo',
  maximum: 'Máximo',
  moreFilters: 'Mais filtros',
  lessFilters: 'Menos filtros',
  address: 'Morada',
  address_location: 'Localidade',
  auth:
    'Aceito a Política de Privacidade, os Termos e Condições do Portal Casa e Sol assim como o uso dos meus dados de acordo com a função do formulário. Aceito igualmente a utilização dos dados para envio de comunicação, quer do portal, quer de parceiros comerciais.',
  name_to: 'Nome destinatário',
  email_to: 'Email destinatário',
  password: 'Senha de acesso',
  passwordConfirm: 'Confirmar senha de acesso',
  dataForAccess: 'Dados para acesso',
  contactDetails: 'Dados de contacto',
  otherPhone: 'Outro número de contacto',
  registerType: 'Registo como',
  agency: 'Agência imobiliária',
  agent: 'Agente imobiliário',
  user: 'Particular',
  website: 'website',
  company: 'Empresa',
  postcode: 'Código Postal',
  feedback: {
    success: 'O seu pedido foi enviado com sucesso! Obrigado!',
    error: 'Lamentamos, não foi possível enviar o seu pedido!',
    passwordDoNotMatch: 'As senhas introduzidas não coincidem!'
  },
  defaultMessage: 'Olá, tenho interesse em receber mais informações deste imóvel.\n\nObrigado!'
};
export const PropertiesListPage = {
  noResults: 'Nenhum imóvel encontrado',
  noResultsSub: 'Não foram encontrados imóveis com as características especificadas',
  properties: 'Imóveis'
};
export const PropertiesLastVisited = {
  noResults: 'Sem registos',
  noResultsSub: 'Ainda não consultou nenhum imóvel.'
};
