import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { propertyFetchData, propertyFeaturesFetchData } from '../actions/property';
import PropertyPrintItem from '../components/properties/PropertyPrintItem';

class PropertyPrintPage extends Component {
  async componentDidMount() {
    this.props.propertyFetchData(this.props.match.params.id);
    this.props.propertyFeaturesFetchData(this.props.match.params.id);
  }

  renderProperty() {
    return <PropertyPrintItem {...this.props.property} />;
  }

  render() {
    const propertyTitle =
      this.props.property.detail &&
      (this.props.property.detail.NOTE
        ? `Casa e Sol - ${this.props.property.detail.NOTE}`
        : `Casa e Sol - ${this.props.property.detail.TYPE}`);

    return (
      <Fragment>
        <Helmet key={Math.random()}>
          <title>{propertyTitle}</title>
          <meta property="og:title" content={propertyTitle} />
        </Helmet>
        {this.props.property.detail && this.renderProperty()}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    lastList: state.properties.lastList,
    property: {
      propertyHasErrored: state.propertyHasErrored,
      propertyIsLoading: state.propertyIsLoading,
      propertyFeaturesHasErrored: state.propertyFeaturesHasErrored,
      propertyFeaturesIsLoading: state.propertyFeaturesIsLoading,
      detail: state.property.detail[0],
      descriptions: state.property.descriptions,
      notes: state.property.notes,
      address: state.property.address[0],
      images: state.property.images,
      families: state.property.families,
      subsidiary: state.property.subsidiary[0],
      features: state.property.features
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    propertyFetchData: propertyID => dispatch(propertyFetchData(propertyID)),
    propertyFeaturesFetchData: propertyID => dispatch(propertyFeaturesFetchData(propertyID))
  };
};

export default {
  component: connect(mapStateToProps, {
    propertyFetchData,
    propertyFeaturesFetchData
  })(withRouter(PropertyPrintPage)),
  mapDispatchToProps
};
