import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import CookieConsent from 'react-cookie-consent';
import { globalResources } from '../../resources/resources';
import ScrollToTop from '../helpers/ScrollToTop';

const Master = props => {
  return (
    <div className="master">
      <ScrollToTop />
      <Header />
      {props.children}
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText={globalResources.cookieConsentAccept}
        cookieName="CookieConsent"
        disableStyles={true}
        expires={150}
      >
        {globalResources.cookieConsent}
        {globalResources.cookieConsentInfo}
        <Link to={'/privacypolicy'}>{globalResources.cookieConsentMore}</Link>
      </CookieConsent>
    </div>
  );
};

export default Master;
