// PROPERTIES
export const PROPERTIES_HAS_ERRORED = 'properties_has_errored';
export const PROPERTIES_IS_LOADING = 'properties_is_loading';
export const PROPERTIES_FETCH_DATA_SUCCESS = 'properties_fetch_data_success';
export const PROPERTIES_CLEAR_DATA = 'properties_clear_data';

// PROPERTIES
export const HIGHLIGHTS_HAS_ERRORED = 'highlights_has_errored';
export const HIGHLIGHTS_IS_LOADING = 'highlights_is_loading';
export const HIGHLIGHTS_FETCH_DATA_SUCCESS = 'highlights_fetch_data_success';
export const HIGHLIGHTS_CLEAR_DATA = 'highlights_clear_data';

// PROPERTY
export const PROPERTY_HAS_ERRORED = 'property_has_errored';
export const PROPERTY_IS_LOADING = 'property_is_loading';
export const PROPERTY_FETCH_DATA_SUCCESS = 'property_fetch_data_success';

export const PROPERTY_FEATURES_HAS_ERRORED = 'property_features_has_errored';
export const PROPERTY_FEATURES_IS_LOADING = 'property_features_is_loading';
export const PROPERTY_FEATURES_FETCH_DATA_SUCCESS = 'property_features_fetch_data_success';

export const PROPERTY_CLEAR_DATA = 'property_clear_data';

// FILTERS
export const FILTERS_HAS_ERRORED = 'filters_has_errored';
export const FILTERS_IS_LOADING = 'filters_is_loading';
export const FILTERS_FETCH_DATA_SUCCESS = 'filters_fetch_data_success';

export const LOCATIONS_FETCH_DATA_SUCCESS = 'locations_fetch_data_success';
export const LOCATIONS_CLEAR_DATA = 'locations_clear_data';

export const BEDROOMS_FETCH_DATA_SUCCESS = 'bedrooms_fetch_data_success';
export const BEDROOMS_CLEAR_DATA = 'bedrooms_clear_data';

export const PRICES_BUY_FETCH_DATA_SUCCESS = 'prices_buy_fetch_data_success';
export const PRICES_RENT_FETCH_DATA_SUCCESS = 'prices_rent_fetch_data_success';
export const PRICES_CLEAR_DATA = 'prices_clear_data';

// SEARCH
export const SEARCH_HAS_ERRORED = 'search_has_errored';
export const SEARCH_IS_LOADING = 'search_is_loading';
export const SEARCH_FETCH_DATA_SUCCESS = 'search_fetch_data_success';

// PROPERTY FORM
export const PROPERTY_FORM_HAS_ERRORED = 'property_form_has_errored';
export const PROPERTY_FORM_IS_LOADING = 'property_form_is_loading';
export const PROPERTY_FORM_FETCH_DATA_SUCCESS = 'property_form_fetch_data_success';

// ANNOUNCE FORM
export const ANNOUNCE_FORM_HAS_ERRORED = 'announce_form_has_errored';
export const ANNOUNCE_FORM_IS_LOADING = 'announce_form_is_loading';
export const ANNOUNCE_FORM_FETCH_DATA_SUCCESS = 'announce_form_fetch_data_success';

// CONTACT FORM
export const CONTACT_FORM_HAS_ERRORED = 'contact_form_has_errored';
export const CONTACT_FORM_IS_LOADING = 'contact_form_is_loading';
export const CONTACT_FORM_FETCH_DATA_SUCCESS = 'contact_form_fetch_data_success';

// REGISTER FORM
export const REGISTER_FORM_HAS_ERRORED = 'register_form_has_errored';
export const REGISTER_FORM_IS_LOADING = 'register_form_is_loading';
export const REGISTER_FORM_FETCH_DATA_SUCCESS = 'register_form_fetch_data_success';
export const REGISTER_FORM_INITIAL_STATE = 'register_form_initial_state';

// RESOURCES
export const SET_LANGUAGE = 'set_language';

// SUGGEST FORM
export const SUGGEST_FORM_HAS_ERRORED = 'suggest_form_has_errored';
export const SUGGEST_FORM_IS_LOADING = 'suggest_form_is_loading';
export const SUGGEST_FORM_FETCH_DATA_SUCCESS = 'suggest_form_fetch_data_success';
