import {
  FILTERS_HAS_ERRORED,
  FILTERS_IS_LOADING,
  FILTERS_FETCH_DATA_SUCCESS,
  LOCATIONS_FETCH_DATA_SUCCESS,
  LOCATIONS_CLEAR_DATA,
  BEDROOMS_FETCH_DATA_SUCCESS,
  BEDROOMS_CLEAR_DATA,
  PRICES_BUY_FETCH_DATA_SUCCESS,
  PRICES_RENT_FETCH_DATA_SUCCESS,
  PRICES_CLEAR_DATA
} from '../actions/types';
import { renameKeys } from '../assets/utils';
import {
  bedroomsRange,
  pricesBuyCountryRange,
  pricesRentCountryRange,
  areasRange,
  keysMap
} from '../assets/constants';

const filtersReducerDefaultState = {
  purposes: [],
  types: [],
  districts: [],
  counties: [],
  parishes: [],
  bedrooms: bedroomsRange,
  bedroomsMin: [],
  bedroomsMax: [],
  pricesMin: [],
  pricesMax: [],
  floorAreaMin: areasRange,
  floorAreaMax: areasRange
};

export function filtersHasErrored(state = false, action) {
  switch (action.type) {
    case FILTERS_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function filtersIsLoading(state = false, action) {
  switch (action.type) {
    case FILTERS_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function filters(state = filtersReducerDefaultState, action) {
  switch (action.type) {
    case FILTERS_FETCH_DATA_SUCCESS:
      const purposesList = action.filters.FFPropertiesPurposes.map(purpose =>
        renameKeys(keysMap, purpose)
      );
      const purposesListFiltered = purposesList.filter(
        purpose =>
          purpose.label.toLowerCase() === 'compra' ||
          purpose.label.toLowerCase() === 'arrendamento' ||
          purpose.label.toLowerCase() === 'comprar' ||
          purpose.label.toLowerCase() === 'arrendar'
      );
      const typesList = action.filters.FFPropertiesTypes.map(type => renameKeys(keysMap, type));
      const districtsList = action.filters.FFDistricts.map(district =>
        renameKeys(keysMap, district)
      );

      return {
        ...state,
        purposes: [...purposesListFiltered],
        types: [...typesList],
        districts: [...districtsList]
      };

    case LOCATIONS_FETCH_DATA_SUCCESS:
      switch (action.table) {
        case 'County':
          const countiesList = action.filters.FFCounties.map(county => renameKeys(keysMap, county));

          return {
            ...state,
            counties: [...countiesList]
          };
        case 'Parish':
          const parishesList = action.filters.FFParishes.map(parish => renameKeys(keysMap, parish));
          return {
            ...state,
            parishes: [...parishesList]
          };
        default:
          return state;
      }

    case LOCATIONS_CLEAR_DATA:
      switch (action.table) {
        case 'parishes':
          return {
            ...state,
            parishes: []
          };
        default:
          return {
            ...state,
            counties: [],
            parishes: []
          };
      }

    case BEDROOMS_FETCH_DATA_SUCCESS:
      return {
        ...state,
        bedroomsMin: bedroomsRange,
        bedroomsMax: bedroomsRange
      };

    case BEDROOMS_CLEAR_DATA:
      return {
        ...state,
        bedroomsMin: [],
        bedroomsMax: []
      };

    case PRICES_BUY_FETCH_DATA_SUCCESS:
      return {
        ...state,
        pricesMin: pricesBuyCountryRange,
        pricesMax: pricesBuyCountryRange
      };

    case PRICES_RENT_FETCH_DATA_SUCCESS:
      return {
        ...state,
        pricesMin: pricesRentCountryRange,
        pricesMax: pricesRentCountryRange
      };

    case PRICES_CLEAR_DATA:
      return {
        ...state,
        pricesMin: [],
        pricesMax: []
      };

    default:
      return state;
  }
}
