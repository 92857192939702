import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { properties, propertiesHasErrored, propertiesIsLoading } from './properties';
import { property, propertyHasErrored, propertyIsLoading } from './property';
import { highlights, highlightsHasErrored, highlightsIsLoading } from './highlights';
import { propertyForm, propertyFormHasErrored, propertyFormIsLoading } from './propertyForm';
import { suggestForm, suggestFormHasErrored, suggestFormIsLoading } from './suggestForm';
import { announceForm, announceFormHasErrored, announceFormIsLoading } from './announceForm';
import { contactForm, contactFormHasErrored, contactFormIsLoading } from './contactForm';
import { registerForm, registerFormHasErrored, registerFormIsLoading } from './registerForm';
import { search } from './search';
import resourcesReducer from './resources';
import { filters, filtersHasErrored, filtersIsLoading } from './filters';

export default combineReducers({
  properties,
  propertiesHasErrored,
  propertiesIsLoading,
  highlights,
  highlightsHasErrored,
  highlightsIsLoading,
  property,
  propertyHasErrored,
  propertyIsLoading,
  filters,
  filtersHasErrored,
  filtersIsLoading,
  propertyForm,
  propertyFormHasErrored,
  propertyFormIsLoading,
  announceForm,
  announceFormHasErrored,
  announceFormIsLoading,
  contactForm,
  contactFormHasErrored,
  contactFormIsLoading,
  suggestForm,
  suggestFormHasErrored,
  suggestFormIsLoading,
  registerForm,
  registerFormHasErrored,
  registerFormIsLoading,
  search,
  resources: resourcesReducer,
  form: formReducer
});
