import { Handler, DefaultParameters, Modules, Actions } from '../assets/config';
import { apiRequest } from './api';
import {
  PROPERTY_HAS_ERRORED,
  PROPERTY_IS_LOADING,
  PROPERTY_FETCH_DATA_SUCCESS,
  PROPERTY_FEATURES_HAS_ERRORED,
  PROPERTY_FEATURES_IS_LOADING,
  PROPERTY_FEATURES_FETCH_DATA_SUCCESS,
  PROPERTY_CLEAR_DATA
} from './types';

function propertyHasErrored(bool) {
  return {
    type: PROPERTY_HAS_ERRORED,
    hasErrored: bool
  };
}

function propertyIsLoading(bool) {
  return {
    type: PROPERTY_IS_LOADING,
    isLoading: bool
  };
}

function propertyFetchDataSuccess(property) {
  return {
    type: PROPERTY_FETCH_DATA_SUCCESS,
    property
  };
}

export function propertyFetchData(propertyID) {
  const moduleAction = {
    module: Modules.properties,
    action: Actions.detail
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    propertyID: propertyID
  };

  return async dispatch => {
    dispatch(propertyHasErrored(false));
    dispatch(propertyIsLoading(true));
    await apiRequest(Handler, data)
      .then(response => {
        if (response.data.message) {
          throw Error(response.statusText);
        }
        dispatch(propertyIsLoading(false));
        return response;
      })
      .then(response => response.data)
      .then(property => dispatch(propertyFetchDataSuccess(property)))
      .catch(e => {
        dispatch(propertyHasErrored(true));
        dispatch(propertyIsLoading(false));
      });
  };
}

function propertyFeaturesHasErrored(bool) {
  return {
    type: PROPERTY_FEATURES_HAS_ERRORED,
    hasErrored: bool
  };
}

function propertyFeaturesIsLoading(bool) {
  return {
    type: PROPERTY_FEATURES_IS_LOADING,
    isLoading: bool
  };
}

function propertyFeaturesFetchDataSuccess(propertyFeatures) {
  return {
    type: PROPERTY_FEATURES_FETCH_DATA_SUCCESS,
    propertyFeatures
  };
}

export function propertyFeaturesFetchData(propertyID) {
  const moduleAction = {
    module: Modules.fillForms,
    action: Actions.detail
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    propertyID: propertyID
  };

  return async dispatch => {
    dispatch(propertyFeaturesIsLoading(true));
    await apiRequest(Handler, data)
      .then(response => {
        if (response.data.message) {
          throw Error(response.statusText);
        }
        dispatch(propertyFeaturesIsLoading(false));
        return response;
      })
      .then(response => response.data)
      .then(propertyFeatures => dispatch(propertyFeaturesFetchDataSuccess(propertyFeatures)))
      .catch(e => {
        dispatch(propertyFeaturesHasErrored(true));
        dispatch(propertyFeaturesIsLoading(false));
      });
  };
}

export function propertyClearData() {
  return {
    type: PROPERTY_CLEAR_DATA
  };
}
