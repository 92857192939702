import HomePage from './pages/HomePage';
import TermsUsePage from './pages/TermsUsePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import PropertiesListPage from './pages/PropertiesListPage';
import PropertiesFavoritesPage from './pages/PropertiesFavoritesPage';
import PropertyDetailPage from './pages/PropertyDetailPage';
import PropertyPrintPage from './pages/PropertyPrintPage';
import AnnouncePage from './pages/AnnouncePage';
import ContactPage from './pages/ContactPage';
import RegisterPage from './pages/RegisterPage';
import NotFoundPage from './pages/NotFoundPage';
import App from './App';

export default [
  {
    ...App,
    routes: [
      {
        ...HomePage,
        path: '/',
        exact: true
      },
      {
        ...PropertiesFavoritesPage,
        path: '/favoritos',
        exact: true
      },
      {
        ...PropertiesListPage,
        path: '/imoveis/:purpose(comprar|arrendar|compra|arrendamento|-)/:location/:type?',
        exact: true
      },
      {
        ...PropertyDetailPage,
        path: '/imovel/:type?/:purpose?/:location?/:id',
        exact: true
      },
      {
        ...PropertyDetailPage,
        path: '/#/imovel/:type?/:purpose?/:location?/:id',
        exact: true
      },
      {
        ...PropertyPrintPage,
        path: '/print/:id',
        exact: true
      },
      {
        ...AnnouncePage,
        path: '/anunciar',
        exact: true
      },
      {
        ...ContactPage,
        path: '/contacto',
        exact: true
      },
      {
        ...RegisterPage,
        path: '/registo',
        exact: true
      },
      {
        path: '/termos-de-uso',
        ...TermsUsePage,
        exact: true
      },
      {
        path: '/politica-de-privacidade',
        ...PrivacyPolicyPage,
        exact: true
      },
      {
        path: '/:erro',
        ...HomePage,
        exact: true
      },
      {
        ...NotFoundPage
      }
    ]
  }
];
