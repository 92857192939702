import React from 'react';
import { globalResources } from '../../resources/resources';
import { setPrice } from '../../assets/utils';

const PropertyMainFeatures = ({
  price,
  Bedrooms,
  BathRooms,
  FloorArea,
  GarageCars,
  CONDITION,
  MAINPURPOSE_PRICE,
  MAINPURPOSE_COIN
}) => {
  price = setPrice(MAINPURPOSE_PRICE, MAINPURPOSE_COIN);

  return (
    <div className="info-wrapper">
      {price && (
        <div className="info-item">
          <div className="translate">{globalResources.price}</div>
          <div>{price}</div>
        </div>
      )}
      {(Bedrooms || Bedrooms === 0) && (
        <div className="info-item">
          <div className="translate">{globalResources.bedrooms}</div>
          <div>{Bedrooms}</div>
        </div>
      )}
      {(BathRooms || BathRooms === 0) && (
        <div className="info-item">
          <div className="translate">{globalResources.bathRooms}</div>
          <div>{BathRooms}</div>
        </div>
      )}
      {GarageCars && (
        <div className="info-item garages">
          <div className="translate">{globalResources.garages}</div>
          <div>{GarageCars}</div>
        </div>
      )}
      {FloorArea && (
        <div className="info-item">
          <div className="translate">{globalResources.area}</div>
          <div>
            {FloorArea} <span>m2</span>
          </div>
        </div>
      )}
      {CONDITION && (
        <div className="info-item condition">
          <div className="translate">{globalResources.condition}</div>
          <div>{CONDITION}</div>
        </div>
      )}
    </div>
  );
};

export default PropertyMainFeatures;
