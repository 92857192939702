import React, { Component } from 'react';
import Select from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import Cookies from 'js-cookie';
import { countryPhoneInput } from '../../assets/constants';

class GoogleTranslator extends Component {
  constructor(props) {
    super(props);
    this.onScriptLoad = this.onScriptLoad.bind(this);
    this.state = {
      google: undefined
    };
  }

  onScriptLoad() {
    setTimeout(() => {
      if (
        this.state.google &&
        this.state.google.translate.TranslateElement &&
        document.getElementById('google_translate_element') &&
        document.getElementById('google_translate_element').children.length === 0
      ) {
        new this.state.google.translate.TranslateElement(
          {
            pageLanguage: 'pt',
            layout: this.state.google.translate.TranslateElement.FloatPosition.TOP_LEFT
          },
          'google_translate_element'
        );
      }
    }, 600);
  }

  remountGoogle() {
    if (this.state.google) {
      this.onScriptLoad();
    } else {
      this.mountGoogle();
    }
  }

  mountGoogle() {
    if (!this.state.google) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);

      s.addEventListener(
        'load',
        e => {
          this.setState({ google: window.google });
          this.onScriptLoad();
        },
        { once: true }
      );
    } else {
      this.onScriptLoad();
    }
  }

  componentDidMount() {
    this.mountGoogle();
  }

  handleLanguageChange(event) {
    if (event.value === 'pt') {
      var iframe = document.getElementsByClassName('goog-te-banner-frame')[0];
      if (!iframe) return;

      var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
      var restore_el = innerDoc.getElementsByTagName('button');

      for (var i = 0; i < restore_el.length; i++) {
        if (restore_el[i].id.indexOf('restore') >= 0) {
          restore_el[i].click();
          var close_el = innerDoc.getElementsByClassName('goog-close-link');
          close_el[0].click();
          return;
        }
      }
    } else {
      var select = document.querySelector('#google_translate_element select');
      if (select != null) {
        select.value = event.value;
        select.dispatchEvent(new Event('change'));
      }
    }
  }

  languages() {
    return [
      { value: 'pt', label: 'Português', code: countryPhoneInput },
      { value: 'en', label: 'English', code: 'gb' },
      { value: 'fr', label: 'Français', code: 'fr' },
      { value: 'ru', label: 'Русский', code: 'ru' },
      { value: 'zh-CN', label: '中文', code: 'cn' }
    ];
  }

  googleCookieLanguage() {
    let lng = Cookies.get('googtrans');

    if (lng && lng.length > 0) {
      return lng.substr(lng.lastIndexOf('/') + 1);
    } else {
      return 'pt';
    }
  }

  getGoogleLanguage() {
    return this.languages().find(lng => lng.value === this.googleCookieLanguage());
  }

  render() {
    const languages = this.languages();

    this.remountGoogle();

    const customSingleValue = ({ data }) => (
      <div className="input-select">
        <div className="input-select__single-value">
          <span className="input-select__icon">
            <ReactCountryFlag
              styleProps={{
                width: '20px',
                height: '20px'
              }}
              code={data.code}
              svg
            />{' '}
          </span>
          <span>{data.label}</span>
        </div>
      </div>
    );

    const customOptionValue = props => {
      const { innerProps, innerRef } = props;
      return (
        <article ref={innerRef} {...innerProps} className="custom-option">
          <div className="input-select__option-value">
            <span className="input-select__icon">
              <ReactCountryFlag
                styleProps={{
                  width: '20px',
                  height: '20px'
                }}
                code={props.data.code}
                svg
              />{' '}
            </span>
            <span>{props.data.label}</span>
          </div>
        </article>
      );
    };

    return (
      <div className="notranslate">
        <Select
          defaultValue={this.getGoogleLanguage()}
          options={languages}
          onChange={this.handleLanguageChange}
          components={{
            SingleValue: customSingleValue,
            Option: customOptionValue
          }}
        />
        <div id="google_translate_element"></div>
      </div>
    );
  }
}

export default GoogleTranslator;
