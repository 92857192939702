import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import queryString from 'query-string';
import FormSelect from '../forms/FormSelect';
import {
  filtersFetchData,
  locationsFetchData,
  locationsClearData,
  bedroomsFetchData,
  bedroomsClearData
} from '../../actions/filters';
import { filterFormFetchData } from '../../actions/filterForm';
import { propertiesFetchData, propertiesClearData } from '../../actions/properties';
import { searchFetchData } from '../../actions/search';
import {
  orderBy as orderByList,
  districtLabel,
  countyLabel,
  parishLabel
} from '../../assets/constants';
import { removeEmptyStrings, getParameterValue, getFilterObject } from '../../assets/utils';
import { Country, currencySymbol } from '../../assets/config';
import { RenderInput } from '../../assets/forms';
import { globalResources, formResources } from '../../resources/resources';

class PropertiesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secondaryFields: this.props.secondaryFields,
      toogleSearch: false,
      filters: {
        pageNumber: ''
      }
    };
  }

  async componentDidMount() {
    await this.props.filtersFetchData({
      withAssociatedProperties: true
    });
    if (Object.entries(this.props.match.params).length !== 0) {
      this.getUrlParameters();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.getUrlParameters();
    }
  }

  async getUrlParameters() {
    const queryStringUrlParams = queryString.parse(this.props.location.search);
    const { purpose, type, location } = this.props.match.params;
    //Purpose
    const purposeObj = purpose ? getParameterValue(this.props.purposes, purpose) : '';
    //Type
    const typeObj = type ? getParameterValue(this.props.types, type) : '';
    //Location
    let county = '';
    let parish = '';
    const locationUrlParam = location ? location.split(',') : [];
    const district = locationUrlParam[0]
      ? getParameterValue(this.props.districts, locationUrlParam[0])
      : '';
    if (district) {
      await this.props.locationsFetchData(
        {
          table: 'County',
          districtID: district.value
        },
        'County',
        true
      );

      county = locationUrlParam[1]
        ? getParameterValue(this.props.counties, locationUrlParam[1])
        : '';
      if (county) {
        await this.props.locationsFetchData(
          {
            table: 'Parish',
            countyID: county.value
          },
          'Parish',
          true
        );

        parish = locationUrlParam[2]
          ? getParameterValue(this.props.parishes, locationUrlParam[2])
          : '';
      }
    }
    const locationObj = parish ? parish : county ? county : district ? district : Country;
    //Bedrooms
    const bedroomsMin = getFilterObject(queryStringUrlParams.roomsMin);
    const bedroomsMax = getFilterObject(queryStringUrlParams.roomsMax);
    //Prices
    const pricesMin = getFilterObject(queryStringUrlParams.pricesMin);
    const pricesMax = getFilterObject(queryStringUrlParams.pricesMax);
    //Areas
    const floorAreaMin = getFilterObject(queryStringUrlParams.floorAreaMin);
    const floorAreaMax = getFilterObject(queryStringUrlParams.floorAreaMax);
    //Ordering
    const orderBy = queryStringUrlParams.orderBy
      ? getParameterValue(orderByList, queryStringUrlParams.orderBy)
      : '';
    //Page Number
    const pageNumber = queryStringUrlParams.pageNumber
      ? parseInt(queryStringUrlParams.pageNumber) - 1 > 0
        ? queryStringUrlParams.pageNumber - 1
        : 0
      : 0;

    const filtersParameters = {
      purposeID: purposeObj ? purposeObj.value : '',
      typeID: typeObj ? typeObj.value : '',
      locationID: locationObj ? locationObj.value : '',
      roomsMin: bedroomsMin ? bedroomsMin.value : '',
      roomsMax: bedroomsMax ? bedroomsMax.value : '',
      priceMin: pricesMin ? pricesMin.value : '',
      priceMax: pricesMax ? pricesMax.value : '',
      floorAreaMin: floorAreaMin ? floorAreaMin.value : '',
      floorAreaMax: floorAreaMax ? floorAreaMax.value : '',
      reference: queryStringUrlParams.reference,
      orderBy: orderBy ? orderBy.value : '',
      pageNumber: pageNumber
    };

    const searchParameters = {
      purpose: purposeObj,
      type: typeObj,
      district,
      county,
      parish,
      bedroomsMin,
      bedroomsMax,
      pricesMin,
      pricesMax,
      floorAreaMin,
      floorAreaMax,
      orderBy
    };

    this.props.searchFetchData(removeEmptyStrings(searchParameters));
    this.props.propertiesFetchData(removeEmptyStrings(filtersParameters));

    localStorage.setItem(
      'filtersParameters',
      JSON.stringify(removeEmptyStrings(filtersParameters))
    );

    localStorage.setItem('lastListUrl', this.props.location.pathname + this.props.location.search);
  }

  toggleSearch() {
    this.setState({ toogleSearch: !this.state.toogleSearch });
  }

  handleFormFields() {
    this.setState({ secondaryFields: !this.state.secondaryFields });
  }

  render() {
    const {
      handleSubmit,
      submitting,
      history,
      purposes,
      types,
      districts,
      counties,
      parishes,
      bedroomsMin,
      bedroomsMax,
      pricesMin,
      pricesMax,
      floorAreaMin,
      floorAreaMax
    } = this.props;

    return (
      <Fragment>
        <button className="btn-toggleSearch translate" onClick={this.toggleSearch.bind(this)}>
          {this.state.toogleSearch ? formResources.close : globalResources.search}
        </button>
        <div className={this.state.toogleSearch ? 'filter-content active' : 'filter-content'}>
          <div className="filter">
            <div className="  filter-wrapper">
              <form
                onSubmit={handleSubmit(values => {
                  filterFormFetchData(values, history);
                })}
              >
                <div className="form-body">
                  <div className="main-fields translate">
                    <Field
                      label={globalResources.purpose}
                      name="purpose"
                      placeholder={formResources.select}
                      isClearable={false}
                      isDisabled={false}
                      component={FormSelect}
                      options={purposes}
                    />
                    <Field
                      label={globalResources.type}
                      name="type"
                      placeholder={formResources.select}
                      isClearable={true}
                      isDisabled={false}
                      component={FormSelect}
                      options={types}
                    />
                    <Field
                      label={districtLabel}
                      name="district"
                      placeholder={formResources.select}
                      isClearable={true}
                      isDisabled={false}
                      component={FormSelect}
                      options={districts}
                    />
                    <Field
                      label={countyLabel}
                      name="county"
                      placeholder={formResources.select}
                      isClearable={true}
                      isDisabled={true}
                      component={FormSelect}
                      options={counties}
                    />
                    <Field
                      class="form-control-parish"
                      label={parishLabel}
                      name="parish"
                      placeholder={formResources.select}
                      isClearable={true}
                      isDisabled={true}
                      component={FormSelect}
                      options={parishes}
                    />
                  </div>
                  <div
                    className={
                      this.state.secondaryFields
                        ? 'secondary-fields active translate'
                        : 'secondary-fields translate'
                    }
                  >
                    <div className="row">
                      <div className="col">
                        <Field
                          label={globalResources.bedrooms}
                          name="bedroomsMin"
                          placeholder={formResources.minimum}
                          isClearable={true}
                          isDisabled={true}
                          component={FormSelect}
                          options={bedroomsMin}
                        />
                      </div>
                      <div className="col">
                        <Field
                          label=""
                          name="bedroomsMax"
                          placeholder={formResources.maximum}
                          isClearable={true}
                          isDisabled={true}
                          component={FormSelect}
                          options={bedroomsMax}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Field
                          label={`${globalResources.price} ${currencySymbol}`}
                          name="pricesMin"
                          placeholder={formResources.minimum}
                          isClearable={true}
                          isDisabled={true}
                          component={FormSelect}
                          options={pricesMin}
                        />
                      </div>
                      <div className="col">
                        <Field
                          label=""
                          name="pricesMax"
                          placeholder={formResources.maximum}
                          isClearable={true}
                          isDisabled={true}
                          component={FormSelect}
                          options={pricesMax}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <Field
                          label={globalResources.aream2}
                          name="floorAreaMin"
                          placeholder={formResources.minimum}
                          isClearable={true}
                          isDisabled={false}
                          component={FormSelect}
                          options={floorAreaMin}
                        />
                      </div>
                      <div className="col">
                        <Field
                          label=""
                          name="floorAreaMax"
                          placeholder={formResources.maximum}
                          isClearable={true}
                          isDisabled={false}
                          component={FormSelect}
                          options={floorAreaMax}
                        />
                      </div>
                    </div>
                    <Field
                      name="reference"
                      label={globalResources.reference}
                      placeholder={globalResources.reference}
                      component={RenderInput}
                    />
                  </div>
                </div>
                <div className="form-adicional translate" onClick={() => this.handleFormFields()}>
                  {this.state.secondaryFields
                    ? formResources.lessFilters
                    : formResources.moreFilters}
                </div>
                <div className="form-footer translate">
                  <input
                    type="submit"
                    disabled={submitting}
                    className="btn-default btn-primary"
                    value={globalResources.search}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasErrored: state.filtersHasErrored,
    isLoading: state.filtersIsLoading,
    purposes: state.filters.purposes,
    types: state.filters.types,
    districts: state.filters.districts,
    counties: state.filters.counties,
    parishes: state.filters.parishes,
    bedroomsMin: state.filters.bedroomsMin,
    bedroomsMax: state.filters.bedroomsMax,
    pricesMin: state.filters.pricesMin,
    pricesMax: state.filters.pricesMax,
    floorAreaMin: state.filters.floorAreaMin,
    floorAreaMax: state.filters.floorAreaMax,
    initialValues: state.search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    filtersFetchData: params => dispatch(filtersFetchData(params)),
    locationsFetchData: (params, table, withAssociatedProperties) =>
      dispatch(locationsFetchData(params, table, withAssociatedProperties)),
    locationsClearData: table => dispatch(locationsClearData(table)),
    propertiesFetchData: filtersParameters => dispatch(propertiesFetchData(filtersParameters)),
    propertiesClearData: () => dispatch(propertiesClearData()),
    bedroomsFetchData: () => dispatch(bedroomsFetchData()),
    bedroomsClearData: () => dispatch(bedroomsClearData()),
    searchFetchData: searchParams => dispatch(searchFetchData(searchParams))
  };
};

// const loadData = store => {
//   return store.dispatch(propertiesFetchData());
// };

const connectedPropertiesFilter = reduxForm({
  form: 'filter',
  destroyOnUnmount: false,
  enableReinitialize: true
})(PropertiesFilter);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(connectedPropertiesFilter));

// (PropertiesFilter = connect(mapStateToProps, {
//   filtersFetchData,
//   locationsFetchData,
//   locationsClearData,
//   propertiesFetchData,
//   propertiesClearData,
//   bedroomsFetchData,
//   bedroomsClearData,
//   searchFetchData
// })(withRouter(PropertiesFilter))),
//   loadData;

// export default reduxForm({
//   form: 'filter',
//   destroyOnUnmount: false,
//   enableReinitialize: true
// })(PropertiesFilter);
