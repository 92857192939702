import {
  SUGGEST_FORM_HAS_ERRORED,
  SUGGEST_FORM_IS_LOADING,
  SUGGEST_FORM_FETCH_DATA_SUCCESS
} from '../actions/types';

let formFeedback = [];

const suggestFormReducerDefaultState = {
  formFeedback
};

export function suggestFormHasErrored(state = false, action) {
  switch (action.type) {
    case SUGGEST_FORM_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function suggestFormIsLoading(state = false, action) {
  switch (action.type) {
    case SUGGEST_FORM_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function suggestForm(state = suggestFormReducerDefaultState, action) {
  switch (action.type) {
    case SUGGEST_FORM_FETCH_DATA_SUCCESS:
      formFeedback = action.formFeedback;

      return {
        formFeedback
      };

    default:
      return state;
  }
}
