import React from 'react';
import { FacebookShareButton, WhatsappShareButton } from 'react-share';
import { urlShare, portalLink } from '../../assets/config';
import { PortalUrl } from '../../assets/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const PropertySocialShare = ({ title, image, propertyID }) => {
  return (
    <ul>
      <li>
        <WhatsappShareButton
          url={`${PortalUrl}/imovel/${propertyID}`}
          title={title}
          separator=":: "
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </WhatsappShareButton>
      </li>
      <li>
        <FacebookShareButton
          url={`${urlShare}?id=${propertyID}&title=${encodeURIComponent(
            title
          )}&image=${encodeURIComponent(image)}&portal=${encodeURIComponent(portalLink)}`}
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </FacebookShareButton>
      </li>
    </ul>
  );
};

export default PropertySocialShare;
