import {
  Handler,
  DefaultParameters,
  ContactDefaultParameters,
  Modules,
  Actions,
  improxyTO
} from '../assets/config';
import { apiRequest } from './api';
import { reset } from 'redux-form';
import { notifySuccess, notifyError } from '../components/elements/Notify';
import {
  CONTACT_FORM_HAS_ERRORED,
  CONTACT_FORM_IS_LOADING,
  CONTACT_FORM_FETCH_DATA_SUCCESS
} from './types';
import { JsonUrlEncode } from '../assets/utils';
import { formResources } from '../resources/resources';

function contactFormHasErrored(bool) {
  return {
    type: CONTACT_FORM_HAS_ERRORED,
    hasErrored: bool
  };
}

function contactFormIsLoading(bool) {
  return {
    type: CONTACT_FORM_IS_LOADING,
    isLoading: bool
  };
}

function contactFormFetchDataSuccess(formFeedback) {
  return {
    type: CONTACT_FORM_FETCH_DATA_SUCCESS,
    formFeedback
  };
}

export function contactFormFetchData(values, dispatch) {
  let replacements = Object.assign({}, values, {
    user_message: values.user_message ? values.user_message : ''
  });

  const moduleAction = {
    module: Modules.contacts,
    action: Actions.sendEmail
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    ...ContactDefaultParameters,
    template: 'contact_info',
    to: `['${improxyTO}']`,
    replacements: JsonUrlEncode({
      ...replacements
    })
  };

  dispatch(contactFormIsLoading(true));
  return apiRequest(Handler, data)
    .then(response => {
      if (response.data.message) {
        throw Error(response.statusText);
      }
      dispatch(contactFormIsLoading(false));
      return response;
    })
    .then(response => response.data)
    .then(formFeedback => {
      switch (formFeedback) {
        case true:
          notifySuccess(formResources.feedback.success);
          dispatch(reset('contact'));
          break;
        default:
          notifyError(formResources.feedback.error);
          break;
      }
      dispatch(contactFormFetchDataSuccess(formFeedback));
    })
    .catch(e => {
      notifyError(formResources.feedback.error);
      dispatch(contactFormHasErrored(true));
      dispatch(contactFormIsLoading(false));
    });
}
