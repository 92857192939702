import { globalResources } from '../resources/resources';
import { CountryID } from './config';
import { phoneMask, postCodeMask, taxMask } from './forms';

// PORTALS
export const Portals = {
  portugal: '6824',
  caboVerde: '7638',
  mocambique: '7619',
  brasil: '7556'
};

// PORTALS URL
const PortalsUrl = {
  portugal: 'https://www.casaesol.com/',
  caboVerde: 'https://www.casaesol.cv/',
  mocambique: 'https://www.casaesol.co.mz/',
  brasil: 'https://imoveisesol.com.br/'
};
export const PortalUrl =
  CountryID === Portals.portugal
    ? PortalsUrl.portugal
    : CountryID === Portals.caboVerde
    ? PortalsUrl.caboVerde
    : CountryID === Portals.mocambique
    ? PortalsUrl.mocambique
    : PortalsUrl.brasil;

// SERVER
export const RequestTimeout = 20000;

// PAGINATION
export const recordsPerPage = '9';
export const recordsPerPageFavorites = 12;
export const recordsPerPageLastVisited = 12;

// IMAGES
export const propertyNoImage = '/images/no-img.jpg';

// SOCIAL LINKS
const socialLinks = {
  portugal: 'https://www.facebook.com/casaesol.imoveis/',
  caboVerde: 'https://www.facebook.com/casaesolcaboverde/',
  mocambique: 'https://www.facebook.com/casaesol.mocambique/',
  brasil: 'https://www.facebook.com/imoveisesol/'
};
export const facebookUrl =
  CountryID === Portals.portugal
    ? socialLinks.portugal
    : CountryID === Portals.caboVerde
    ? socialLinks.caboVerde
    : CountryID === Portals.mocambique
    ? socialLinks.mocambique
    : socialLinks.brasil;

// LOCATIONS
export const districtLabel =
  CountryID === Portals.portugal
    ? globalResources.district
    : CountryID === Portals.caboVerde
    ? globalResources.island
    : CountryID === Portals.mocambique
    ? globalResources.province
    : globalResources.state;

export const countyLabel =
  CountryID === Portals.mocambique || CountryID === Portals.brasil
    ? globalResources.countyMZ
    : globalResources.county;

export const parishLabel =
  CountryID === Portals.mocambique
    ? globalResources.city
    : CountryID === Portals.brasil
    ? globalResources.neighborhood
    : globalResources.parish;

// MASKS
export const phoneMaskInput =
  CountryID === Portals.portugal
    ? phoneMask.portugal
    : CountryID === Portals.caboVerde
    ? phoneMask.caboVerde
    : CountryID === Portals.mocambique
    ? phoneMask.mocambique
    : phoneMask.brasil;

export const postCodeMaskInput =
  CountryID === Portals.mocambique
    ? postCodeMask.mocambique
    : CountryID === Portals.brasil
    ? postCodeMask.brasil
    : CountryID === Portals.caboVerde
    ? postCodeMask.caboVerde
    : postCodeMask.default;

export const countryPhoneInput =
  CountryID === Portals.portugal
    ? 'pt'
    : CountryID === Portals.caboVerde
    ? 'cv'
    : CountryID === Portals.mocambique
    ? 'mz'
    : 'br';

export const taxNumber = {
  MaskInput:
    CountryID === Portals.brasil
      ? taxMask.brasil
      : CountryID === Portals.mocambique
      ? taxMask.mocambique
      : taxMask.portugal,
  Label:
    CountryID === Portals.mocambique
      ? globalResources.taxNumberMZ
      : CountryID === Portals.brasil
      ? globalResources.taxNumberBr
      : globalResources.taxNumber,
  MinLength: CountryID === Portals.brasil ? 11 : CountryID === Portals.mocambique ? 0 : 9
};

// FILTERS
export const purposes = [
  {
    value: 1605,
    label: 'Comprar',
    priceRange: 'buy'
  },
  {
    value: 1606,
    label: 'Arrendar',
    priceRange: 'rent'
  }
];
export const orderBy = [
  {
    value: 0,
    label: 'Recente'
  },
  {
    value: 1,
    label: 'Menor tipologia'
  },
  {
    value: 2,
    label: 'Maior tipologia'
  },
  {
    value: 3,
    label: 'Mais baratos'
  },
  {
    value: 4,
    label: 'Mais caros'
  }
];
export const bedroomsRange = [
  {
    value: '0',
    label: 0
  },
  {
    value: '1',
    label: 1
  },
  {
    value: '2',
    label: 2
  },
  {
    value: '3',
    label: 3
  },
  {
    value: '4',
    label: 4
  },
  {
    value: '5',
    label: 5
  },
  {
    value: '6',
    label: 6
  }
];
export const pricesBuyRange = {
  portugal: [
    {
      value: '60000',
      label: '60 000'
    },
    {
      value: '80000',
      label: '80 000'
    },
    {
      value: '100000',
      label: '100 000'
    },
    {
      value: '120000',
      label: '120 000'
    },
    {
      value: '140000',
      label: '140 000'
    },
    {
      value: '160000',
      label: '160 000'
    },
    {
      value: '180000',
      label: '180 000'
    },
    {
      value: '200000',
      label: '200 000'
    },
    {
      value: '220000',
      label: '220 000'
    },
    {
      value: '240000',
      label: '240 000'
    },
    {
      value: '260000',
      label: '260 000'
    },
    {
      value: '280000',
      label: '280 000'
    },
    {
      value: '300000',
      label: '300 000'
    },
    {
      value: '320000',
      label: '320 000'
    },
    {
      value: '340000',
      label: '340 000'
    },
    {
      value: '360000',
      label: '360 000'
    },
    {
      value: '380000',
      label: '380 000'
    },
    {
      value: '400000',
      label: '400 000'
    },
    {
      value: '450000',
      label: '450 000'
    },
    {
      value: '500000',
      label: '500 000'
    },
    {
      value: '550000',
      label: '550 000'
    },
    {
      value: '600000',
      label: '600 000'
    },
    {
      value: '650000',
      label: '650 000'
    },
    {
      value: '700000',
      label: '700 000'
    },
    {
      value: '750000',
      label: '750 000'
    },
    {
      value: '800000',
      label: '800 000'
    },
    {
      value: '850000',
      label: '850 000'
    },
    {
      value: '900000',
      label: '900 000'
    },
    {
      value: '950000',
      label: '950 000'
    },
    {
      value: '1000000',
      label: '1 000 000'
    },
    {
      value: '1100000',
      label: '1 100 000'
    },
    {
      value: '1200000',
      label: '1 200 000'
    },
    {
      value: '1300000',
      label: '1 300 000'
    },
    {
      value: '1400000',
      label: '1 400 000'
    },
    {
      value: '1500000',
      label: '1 500 000'
    },
    {
      value: '1600000',
      label: '1 600 000'
    },
    {
      value: '1700000',
      label: '1 700 000'
    },
    {
      value: '1800000',
      label: '1 800 000'
    },
    {
      value: '1900000',
      label: '1 900 000'
    },
    {
      value: '2000000',
      label: '2 000 000'
    },
    {
      value: '2250000',
      label: '2 250 000'
    },
    {
      value: '2500000',
      label: '2 500 000'
    },
    {
      value: '2750000',
      label: '2 750 000'
    },
    {
      value: '3000000',
      label: '3 000 000'
    }
  ],
  caboverde: [
    {
      value: '250000',
      label: '250 000'
    },
    {
      value: '500000',
      label: '500 000'
    },
    {
      value: '750000',
      label: '750 000'
    },
    {
      value: '1000000',
      label: '1 000 000'
    },
    {
      value: '1250000',
      label: '1 250 000'
    },
    {
      value: '1500000',
      label: '1 500 000'
    },
    {
      value: '1750000',
      label: '1 750 000'
    },
    {
      value: '2000000',
      label: '2 000 000'
    },
    {
      value: '3000000',
      label: '3 000 000'
    },
    {
      value: '4000000',
      label: '4 000 000'
    },
    {
      value: '5000000',
      label: '5 000 000'
    },
    {
      value: '6000000',
      label: '6 000 000'
    },
    {
      value: '7000000',
      label: '7 000 000'
    },
    {
      value: '8000000',
      label: '8 000 000'
    },
    {
      value: '9000000',
      label: '9 000 000'
    },
    {
      value: '10000000',
      label: '10 000 000'
    },
    {
      value: '12500000',
      label: '12 500 000'
    },
    {
      value: '13000000',
      label: '13 000 000'
    },
    {
      value: '13500000',
      label: '13 500 000'
    },
    {
      value: '14000000',
      label: '14 000 000'
    },
    {
      value: '14500000',
      label: '14 500 000'
    },
    {
      value: '15000000',
      label: '15 000 000'
    },
    {
      value: '15500000',
      label: '15 500 000'
    },
    {
      value: '16000000',
      label: '16 000 000'
    },
    {
      value: '16500000',
      label: '16 500 000'
    },
    {
      value: '17000000',
      label: '17 000 000'
    },
    {
      value: '17500000',
      label: '17 500 000'
    },
    {
      value: '18000000',
      label: '18 000 000'
    },
    {
      value: '18500000',
      label: '18 500 000'
    },
    {
      value: '19000000',
      label: '19 000 000'
    },
    {
      value: '19500000',
      label: '19 500 000'
    },
    {
      value: '20000000',
      label: '20 000 000'
    },
    {
      value: '21000000',
      label: '21 000 000'
    },
    {
      value: '22000000',
      label: '22 000 000'
    },
    {
      value: '23000000',
      label: '23 000 000'
    },
    {
      value: '24000000',
      label: '24 000 000'
    },
    {
      value: '25000000',
      label: '25 000 000'
    },
    {
      value: '26000000',
      label: '26 000 000'
    },
    {
      value: '27000000',
      label: '27 000 000'
    },
    {
      value: '28000000',
      label: '28 000 000'
    },
    {
      value: '29000000',
      label: '29 000 000'
    },
    {
      value: '30000000',
      label: '30 000 000'
    },
    {
      value: '35000000',
      label: '35 000 000'
    },
    {
      value: '40000000',
      label: '40 000 000'
    },
    {
      value: '50000000',
      label: '50 000 000'
    },
    {
      value: '75000000',
      label: '75 000 000'
    },
    {
      value: '1000000000',
      label: '1 000 000 000'
    }
  ]
};
export const pricesRentRange = {
  portugal: [
    {
      value: '100',
      label: '100'
    },
    {
      value: '150',
      label: '150'
    },
    {
      value: '200',
      label: '200'
    },
    {
      value: '250',
      label: '250'
    },
    {
      value: '300',
      label: '300'
    },
    {
      value: '350',
      label: '350'
    },
    {
      value: '400',
      label: '400'
    },
    {
      value: '450',
      label: '450'
    },
    {
      value: '500',
      label: '500'
    },
    {
      value: '550',
      label: '550'
    },
    {
      value: '600',
      label: '600'
    },
    {
      value: '650',
      label: '650'
    },
    {
      value: '700',
      label: '700'
    },
    {
      value: '750',
      label: '750'
    },
    {
      value: '800',
      label: '800'
    },
    {
      value: '850',
      label: '850'
    },
    {
      value: '900',
      label: '900'
    },
    {
      value: '950',
      label: '950'
    },
    {
      value: '1000',
      label: '1 000'
    },
    {
      value: '1100',
      label: '1 100'
    },
    {
      value: '1200',
      label: '1 200'
    },
    {
      value: '1300',
      label: '1 300'
    },
    {
      value: '1400',
      label: '1 400'
    },
    {
      value: '1500',
      label: '1 500'
    },
    {
      value: '1600',
      label: '1 600'
    },
    {
      value: '1700',
      label: '1 700'
    },
    {
      value: '1800',
      label: '1 800'
    },
    {
      value: '1900',
      label: '1 900'
    },
    {
      value: '2000',
      label: '2 000'
    },
    {
      value: '2250',
      label: '2 250'
    },
    {
      value: '2500',
      label: '2 500'
    },
    {
      value: '2750',
      label: '2 750'
    },
    {
      value: '3000',
      label: '3 000'
    }
  ],
  caboverde: [
    {
      value: '10000',
      label: '10 000'
    },
    {
      value: '15000',
      label: '15 000'
    },
    {
      value: '20000',
      label: '20 000'
    },
    {
      value: '25000',
      label: '25 000'
    },
    {
      value: '30000',
      label: '30 000'
    },
    {
      value: '35000',
      label: '35 000'
    },
    {
      value: '40000',
      label: '40 000'
    },
    {
      value: '45000',
      label: '45 000'
    },
    {
      value: '50000',
      label: '50 000'
    },
    {
      value: '55000',
      label: '55 000'
    },
    {
      value: '60000',
      label: '60 000'
    },
    {
      value: '65000',
      label: '65 000'
    },
    {
      value: '70000',
      label: '70 000'
    },
    {
      value: '75000',
      label: '75 000'
    },
    {
      value: '80000',
      label: '80 000'
    },
    {
      value: '85000',
      label: '85 000'
    },
    {
      value: '90000',
      label: '90 000'
    },
    {
      value: '95000',
      label: '95 000'
    },
    {
      value: '100000',
      label: '100 000'
    },
    {
      value: '110000',
      label: '110 000'
    },
    {
      value: '120000',
      label: '120 000'
    },
    {
      value: '130000',
      label: '130 000'
    },
    {
      value: '140000',
      label: '140 000'
    },
    {
      value: '150000',
      label: '150 000'
    },
    {
      value: '160000',
      label: '160 000'
    },
    {
      value: '170000',
      label: '170 000'
    },
    {
      value: '180000',
      label: '180 000'
    },
    {
      value: '190000',
      label: '190 000'
    },
    {
      value: '200000',
      label: '200 000'
    },
    {
      value: '225000',
      label: '225 000'
    },
    {
      value: '25000',
      label: '250 000'
    },
    {
      value: '275000',
      label: '275 000'
    },
    {
      value: '300000',
      label: '300 000'
    }
  ]
};
export const areasRange = [
  {
    value: '40',
    label: '40'
  },
  {
    value: '60',
    label: '60'
  },
  {
    value: '80',
    label: '80'
  },
  {
    value: '100',
    label: '100'
  },
  {
    value: '120',
    label: '120'
  },
  {
    value: '140',
    label: '140'
  },
  {
    value: '160',
    label: '160'
  },
  {
    value: '180',
    label: '180'
  },
  {
    value: '200',
    label: '200'
  },
  {
    value: '250',
    label: '250'
  },
  {
    value: '300',
    label: '300'
  },
  {
    value: '350',
    label: '350'
  },
  {
    value: '400',
    label: '400'
  },
  {
    value: '450',
    label: '450'
  },
  {
    value: '500',
    label: '500'
  },
  {
    value: '600',
    label: '600'
  },
  {
    value: '700',
    label: '700'
  },
  {
    value: '800',
    label: '800'
  },
  {
    value: '900',
    label: '900'
  },
  {
    value: '1000',
    label: '1 000'
  }
];
export const keysMap = {
  ID: 'value',
  DESCRIPTION: 'label'
};

export const pricesBuyCountryRange =
  CountryID === Portals.portugal ? pricesBuyRange.portugal : pricesBuyRange.caboverde;

export const pricesRentCountryRange =
  CountryID === Portals.portugal ? pricesRentRange.portugal : pricesRentRange.caboverde;

// ADVERTISEMENTS
export const advertisements =
  CountryID === Portals.caboVerde
    ? {
        slot_1: {
          to: '',
          duration: 500,
          autoPlayInterval: 8800,
          images: ['/images/advertisements/gimobgif.gif', '/images/advertisements/gecondgif.gif']
        },
        slot_2: {
          to: 'http://www.murdeiravillageresort.com/cabo-verde-sal.html',
          images: ['/images/advertisements/mvilla.gif']
        }
      }
    : CountryID === Portals.mocambique
    ? {
        slot_1: {
          to: '',
          duration: 500,
          autoPlayInterval: 8800,
          images: ['/images/advertisements/gimobgif.gif', '/images/advertisements/gecondgif.gif']
        }
      }
    : {
        slot_1: {
          to: 'https://www.expertautopecas.pt/pecas-online',
          images: ['/images/advertisements/cartlogo_pt.jpg']
        },
        slot_2: {
          to: 'https://www.lusopay.com/',
          images: [
            '/images/advertisements/Logo-lusopay1.jpg',
            '/images/advertisements/Logo-lusopay2.jpg',
            '/images/advertisements/Logo-lusopay3.jpg'
          ]
        }
      };
