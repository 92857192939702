import {
  PROPERTIES_HAS_ERRORED,
  PROPERTIES_IS_LOADING,
  PROPERTIES_FETCH_DATA_SUCCESS,
  PROPERTIES_CLEAR_DATA
} from '../actions/types';

const propertiesReducerDefaultState = {
  lastList: null,
  list: null,
  lastCounters: [],
  counters: []
};

export function propertiesHasErrored(state = false, action) {
  switch (action.type) {
    case PROPERTIES_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function propertiesIsLoading(state = false, action) {
  switch (action.type) {
    case PROPERTIES_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function properties(state = propertiesReducerDefaultState, action) {
  switch (action.type) {
    case PROPERTIES_FETCH_DATA_SUCCESS:
      const propertiesList = action.properties.Properties.map(property => property);
      const propertiesCounters = action.properties.PropertyCounters[0];

      return {
        lastList: [...propertiesList],
        list: [...propertiesList],
        lastCounters: propertiesCounters,
        counters: propertiesCounters
      };
    case PROPERTIES_CLEAR_DATA:
      return {
        ...state,
        list: null,
        counters: []
      };
    default:
      return state;
  }
}
