import React, { Component, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { globalResources } from '../../resources/resources';
import { propertiesFetchData } from '../../actions/properties';
import { removeEmptyStrings, isRunningOnServerSide } from '../../assets/utils';
import { recordsPerPage } from '../../assets/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';

class PropertiesNavigate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customList: this.props.customList,
      customListPath: this.props.customListPath,
      navigate: this.props.navigate ? this.props.navigate : true,
      navigate_list_save: undefined
    };
  }

  componentDidUpdate() {
    this.updateNavigateListState();
  }

  updateNavigateListState() {
    if (this.state.navigate) {
      const navigate = this.getNavigation();
      if (
        navigate.count > 0 &&
        (!this.state.navigate_list_save ||
          JSON.stringify(this.state.navigate_list_save) !== JSON.stringify(navigate))
      ) {
        localStorage.setItem(
          'navigate_list_save',
          JSON.stringify(this.simplifyNavigateProperties(navigate))
        );
        this.setState({
          navigate_list_save: navigate
        });
      } else {
        this.setNavigateListState(navigate);
      }
    }
  }

  setNavigateListState(navigate) {
    if (!this.state.navigate_list_save && navigate.count === 0) {
      let local_list = localStorage.getItem('navigate_list_save');
      if (local_list && local_list !== null && local_list.length > 0) {
        let nav = JSON.parse(local_list);
        if (nav && nav.list && nav.list.length > 0) {
          nav.list.forEach(property => {
            if (property && property !== null && property.PROPERTYID === parseInt(this.props.id)) {
              if (nav.row !== property.ROWNUMBER) {
                nav.row = property.ROWNUMBER;
                localStorage.setItem(
                  'navigate_list_save',
                  JSON.stringify(this.simplifyNavigateProperties(nav))
                );
              }
              this.setState({
                navigate_list_save: nav,
                rowNumber: nav.row
              });
            }
          });
        } else {
          localStorage.removeItem('navigate_list_save');
        }
      }
    }
  }

  simplifyNavigateProperties(navigate) {
    if (navigate.list && navigate.list !== null && navigate.list.length > 0) {
      let simplified_navigate = {
        ...navigate,
        list: []
      };
      navigate.list.forEach(property => {
        simplified_navigate.list.push({
          PROPERTYID: property.PROPERTYID,
          ROWNUMBER: property.ROWNUMBER
        });
      });
      return simplified_navigate;
    }
    return navigate;
  }

  async requestPropertiesList(requestedRow) {
    const pageByRow = parseInt(requestedRow) / parseInt(recordsPerPage);
    const pageNumber = Number.isInteger(pageByRow) ? pageByRow - 1 : Math.floor(pageByRow);
    let filtersParameters = localStorage.getItem('filtersParameters');

    await this.props.propertiesFetchData(
      removeEmptyStrings({
        ...JSON.parse(filtersParameters),
        pageNumber: pageNumber
      })
    );

    return this.getPropertyDetail(requestedRow);
  }

  getPropertyDetail(requestedRow) {
    return this.getNavigation().list.find(
      property => parseInt(property.ROWNUMBER) === requestedRow
    );
  }

  async handleClick(e) {
    e.preventDefault();
    let actionValue = 0;
    let propertyDetail = {};

    switch (e.currentTarget.value) {
      case 'prev':
        actionValue = -1;
        break;
      case 'next':
        actionValue = 1;
        break;
      default:
        break;
    }

    const row = parseInt(this.props.rowNumber ? this.props.rowNumber : this.state.rowNumber);

    let requestedRow = row + actionValue;

    propertyDetail = this.getPropertyDetail(requestedRow);

    if (!propertyDetail) {
      propertyDetail = await this.requestPropertiesList(requestedRow);
    }

    if (propertyDetail) {
      const queryHistory = {
        pathname: `/imovel/${propertyDetail.PROPERTYID}`,
        state: {
          rowNumber: requestedRow
        }
      };
      this.props.history.push(queryHistory);
    }
  }

  setBackToListButton() {
    if (this.props.search && !this.state.customList) {
      const purpose = this.props.search.purpose ? this.props.search.purpose.label : '';
      const type = this.props.search.type ? this.props.search.type.label : '';
      const district = this.props.search.district ? this.props.search.district.label : '';
      const county = this.props.search.county ? this.props.search.county.label : '';
      const parish = this.props.search.parish ? this.props.search.parish.label : '';

      const location = parish ? parish : county ? county : district ? district : '';

      return (
        <Fragment>
          {purpose && <span>{purpose}</span>}
          {type && <span>{type}</span>}
          {location && <span>{location}</span>}
        </Fragment>
      );
    }
  }

  validateNavigationRow(navigate) {
    if (navigate.list && navigate.list !== null && navigate.list.length > 0) {
      for (let p = 0; p < navigate.list.length; p++) {
        const property = navigate.list[p];
        if (property.PROPERTYID === parseInt(this.props.id)) {
          if (navigate.row !== property.ROWNUMBER) navigate.row = property.ROWNUMBER;
          break;
        }
      }
    }
    return navigate;
  }

  getNavigation() {
    let navigate = {
      list: [],
      count: 0,
      row: 0
    };
    if (this.state.navigate) {
      const row = parseInt(this.props.rowNumber ? this.props.rowNumber : this.state.rowNumber);
      if (this.state.customList && this.state.customList.length > 0) {
        navigate = {
          list: this.state.customList,
          count: this.state.customList.length,
          row: row
        };
      } else {
        if (
          this.props.properties &&
          this.props.properties.list &&
          this.props.properties.count > 0
        ) {
          navigate = {
            ...this.props.properties,
            row: row
          };
        } else {
          if (this.state.navigate_list_save && this.state.navigate_list_save.count > 0) {
            navigate = {
              ...this.state.navigate_list_save,
              row: row
            };
          }
        }
      }
    }
    return this.validateNavigationRow(navigate);
  }

  render() {
    const backToListUrl =
      this.state.customListPath && this.state.customListPath.length > 0
        ? this.state.customListPath
        : `${localStorage.getItem('lastListUrl')}`;
    const backToListLabel = this.setBackToListButton();
    const navigation = this.getNavigation();

    return (
      <Fragment>
        {navigation.count > 0 && (
          <section className="property-detail-header">
            <div className="section-wrapper">
              <div className="section-content">
                <div className="section-content-wrapper">
                  <div className="property-detail-navigation notranslate">
                    <div>
                      <Link to={backToListUrl}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        <span className="translate">{backToListLabel}</span>
                      </Link>
                    </div>
                    <div>
                      {navigation.row > 1 && (
                        <button value="prev" onClick={e => this.handleClick(e)}>
                          <FontAwesomeIcon icon={faChevronLeft} />
                          <span className="translate">{globalResources.previous}</span>
                        </button>
                      )}
                      {navigation.row} - {navigation.count}
                      {navigation.row !== navigation.count && (
                        <button value="next" onClick={e => this.handleClick(e)}>
                          <span className="translate">{globalResources.next}</span>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    properties: {
      list: state.properties.lastList,
      count: state.properties.lastCounters.PropertyCount
    },
    search: state.search
  };
};

const mapDispatchToProps = store => {
  return {
    propertiesFetchData: filtersParameters => store.dispatch(propertiesFetchData(filtersParameters))
  };
};

(PropertiesNavigate = connect(mapStateToProps, { propertiesFetchData })(
  withRouter(PropertiesNavigate)
)),
  mapDispatchToProps;

export default PropertiesNavigate;
