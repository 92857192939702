import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import FormSelect from './FormSelect';
import CaptchaField from '../google/CaptchaField';
import { filtersFetchData } from '../../actions/filters';
import { announceFormFetchData } from '../../actions/announceForm';
import {
  RenderInput,
  RenderTextarea,
  RenderCheckbox,
  RenderPhoneInput,
  required,
  number,
  minLength10,
  email
} from '../../assets/forms';
import { currencySymbol, CountryID } from '../../assets/config';
import { districtLabel, countyLabel, parishLabel, Portals } from '../../assets/constants';
import { globalResources, formResources } from '../../resources/resources';

class AnnounceForm extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    window.recaptchaOptions = {
      useRecaptchaNet: true
    };
    await this.props.fetchData({
      withAssociatedProperties: false
    });
  }

  render() {
    const {
      isLoading,
      handleSubmit,
      submitting,
      purposes,
      types,
      districts,
      counties,
      parishes,
      bedrooms
    } = this.props;

    return (
      <div className="announce-form">
        <form onSubmit={handleSubmit(announceFormFetchData)}>
          <div className="form-body">
            <div className="row">
              <div className="col">
                <h5>A sua identificação</h5>
              </div>
            </div>
            <div className="row">
              <div className="col pad-right-m">
                <Field
                  name="user_name"
                  label={formResources.name}
                  validate={required}
                  component={RenderInput}
                />
              </div>
              <div className="col pad-left-m pad-right-m">
                <Field
                  name="user_email"
                  label={formResources.email}
                  validate={[required, email]}
                  component={RenderInput}
                />
              </div>
              <div className="col pad-left-m">
                <Field
                  name="user_phone"
                  label={formResources.phone}
                  validate={[required, minLength10]}
                  component={RenderPhoneInput}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h5>Características do imóvel</h5>
              </div>
            </div>
            <div className="row">
              <div className="col pad-right-m">
                <Field
                  label={districtLabel}
                  name="property_district"
                  placeholder={formResources.select}
                  isClearable={true}
                  isDisabled={false}
                  component={FormSelect}
                  options={districts}
                  validate={required}
                  withAssociatedProperties={false}
                />
              </div>
              <div className="col pad-left-m pad-right-m">
                <Field
                  label={countyLabel}
                  name="property_county"
                  placeholder={formResources.select}
                  isClearable={true}
                  isDisabled={false}
                  component={FormSelect}
                  options={counties}
                  validate={CountryID === Portals.caboVerde ? undefined : required}
                  withAssociatedProperties={false}
                />
              </div>
              <div className="col pad-left-m">
                <Field
                  label={parishLabel}
                  name="property_parish"
                  placeholder={formResources.select}
                  isClearable={true}
                  isDisabled={false}
                  component={FormSelect}
                  options={parishes}
                />
              </div>
            </div>
            <div className="row">
              <div className="col pad-right-m">
                <Field
                  label={globalResources.purpose}
                  name="property_purpose"
                  placeholder={formResources.select}
                  isClearable={true}
                  isDisabled={false}
                  component={FormSelect}
                  options={purposes}
                  validate={required}
                />
              </div>
              <div className="col pad-left-m pad-right-m">
                <Field
                  label={globalResources.type}
                  name="property_type"
                  placeholder={formResources.select}
                  isClearable={true}
                  isDisabled={false}
                  component={FormSelect}
                  options={types}
                  validate={required}
                />
              </div>
              <div className="col pad-left-m">
                <Field
                  name="property_price"
                  label={`${globalResources.price} ${currencySymbol}`}
                  validate={[required, number]}
                  component={RenderInput}
                />
              </div>
            </div>
            <div className="row">
              <div className="col pad-right-m">
                <Field
                  label={globalResources.bedrooms}
                  name="property_bedrooms"
                  placeholder={formResources.select}
                  isClearable={true}
                  isDisabled={false}
                  component={FormSelect}
                  options={bedrooms}
                  validate={required}
                />
              </div>
              <div className="col pad-left-m pad-right-m">
                <Field
                  name="property_area"
                  label={globalResources.aream2}
                  validate={[required, number]}
                  component={RenderInput}
                />
              </div>
              <div className="col pad-left-m"></div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  name="property_description"
                  label={globalResources.description}
                  component={RenderTextarea}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  name="auth"
                  label={formResources.auth}
                  validate={required}
                  component={RenderCheckbox}
                />
              </div>
            </div>
          </div>
          <div className="form-footer">
            <Field name="captcharesponse" component={CaptchaField} />
            <button
              type="submit"
              disabled={submitting || isLoading}
              className="btn-default btn-primary"
            >
              {formResources.send}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasErrored: state.announceFormHasErrored,
    isLoading: state.announceFormIsLoading,
    purposes: state.filters.purposes,
    types: state.filters.types,
    districts: state.filters.districts,
    counties: state.filters.counties,
    parishes: state.filters.parishes,
    bedrooms: state.filters.bedrooms,
    formFeedback: state.announceForm.formFeedback
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchData: params => dispatch(filtersFetchData(params)),
    dispatchChange: (formName, field, value) => dispatch(change(formName, field, value))
  };
};

export default reduxForm({
  form: 'announce',
  destroyOnUnmount: true
})(connect(mapStateToProps, mapDispatchToProps)(AnnounceForm));
