import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NoResults = props => (
  <section
    className={
      props.subclass !== undefined
        ? "section-no-results " + props.subclass
        : "section-no-results"
    }
  >
    <div className="section-wrapper">
      <h3 className="noResultsTitle">
        {props.title}
        <FontAwesomeIcon icon={props.icon} />
      </h3>
      <div className="noResultsSubTitle">{props.subtitle}</div>
    </div>
  </section>
);

export default NoResults;
