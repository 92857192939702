import {
  CONTACT_FORM_HAS_ERRORED,
  CONTACT_FORM_IS_LOADING,
  CONTACT_FORM_FETCH_DATA_SUCCESS
} from '../actions/types';

let formFeedback = [];

const contactFormReducerDefaultState = {
  formFeedback
};

export function contactFormHasErrored(state = false, action) {
  switch (action.type) {
    case CONTACT_FORM_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function contactFormIsLoading(state = false, action) {
  switch (action.type) {
    case CONTACT_FORM_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function contactForm(state = contactFormReducerDefaultState, action) {
  switch (action.type) {
    case CONTACT_FORM_FETCH_DATA_SUCCESS:
      formFeedback = action.formFeedback;

      return {
        formFeedback
      };

    default:
      return state;
  }
}
