import React from 'react';
import { Link } from 'react-router-dom';
import NoSSR from 'react-no-ssr';
import PropertyAgency from './PropertyAgency';
import PropertyMainFeatures from './PropertyMainFeatures';
import PropertyGallery from './PropertyGallery';
import PropertyDescription from './PropertyDescription';
import PropertyFeatures from './PropertyFeatures';
import PropertyLocation from './PropertyLocation';
import PropertySocialShare from './PropertySocialShare';
import PropertyFavorite from './PropertyFavorite';
import PropertyForm from '../forms/PropertyForm';
import SuggestForm from '../forms/SuggestForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { setLocation, setTitle } from '../../assets/utils';
import { globalResources } from '../../resources/resources';

const PropertyDetailItem = props => {
  const { detail, images, address, features, subsidiary, lastList } = props;
  const title = setTitle(detail.NOTE, detail.TYPE);
  const location = setLocation(detail.LOCATIONSECONDLEVEL, detail.LOCATIONTHIRDLEVEL);

  return (
    <article className="property-detail notranslate">
      <section>
        <div className="section-wrapper">
          <header className="section-header">
            <h6 className="translate">{detail.MAINPURPOSE}</h6>
            <h1 className="translate">{title}</h1>
            {location && <h2>{location}</h2>}
            <span className="translate">{globalResources.reference}</span>: {detail.Reference}
          </header>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="section-property-info">
                <div className="social-share">
                  <ul>
                    <PropertyFavorite detail={detail} lastList={lastList} />
                    <SuggestForm property={detail} />
                    <li>
                      <Link to={`/print/${detail.PROPERTYID}`} target="_blank">
                        <FontAwesomeIcon icon={faPrint} />
                      </Link>
                    </li>
                  </ul>
                  <PropertySocialShare
                    title={title}
                    image={detail.mainphoto}
                    propertyID={detail.PROPERTYID}
                  />
                </div>
                <PropertyMainFeatures {...detail} />
                <PropertyGallery gallery={images} />
                <PropertyDescription description={detail.DESCRIPTION} />
                <PropertyFeatures features={features} />
                <NoSSR>
                  <PropertyLocation location={location} {...address} />
                </NoSSR>
              </div>
              <div className="section-contact-request">
                <span className="anchor" id="contactar-anunciante"></span>
                <div className="contact-request translate">
                  <NoSSR>
                    <PropertyForm
                      property={detail}
                      subsidiaryEmail={subsidiary.email}
                      subsidiaryName={subsidiary.commercialdesignation}
                      subsidiaryPhone={subsidiary.mainphone}
                    />
                  </NoSSR>
                </div>
                <div className="contact-request translate">
                  <PropertyAgency {...subsidiary} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  );
};

export default PropertyDetailItem;
