import React from 'react';
import { Helmet } from 'react-helmet';
import AnnounceForm from '../components/forms/AnnounceForm';
import Breadcrumbs from '../components/elements/Breadcrumbs';
import Master from '../components/elements/Master';
import NoSSR from 'react-no-ssr';

const AnnouncePage = () => {
  const head = () => {
    return (
      <Helmet key={Math.random()}>
        <title>Casa e Sol - Anunciar</title>
        <meta property="og:title" content="Casa e Sol - Anunciar" />
      </Helmet>
    );
  };

  return (
    <Master>
      {head()}
      <Breadcrumbs page={`Anunciar`} />
      <section>
        <div className="section-wrapper">
          <header className="section-header">
            <h3>Anunciar</h3>
          </header>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="row">
                <div className="col">
                  <p>
                    Se tem algum imóvel que deseja vender/arrendar e gostaria de ter ajuda
                    profissional, por favor preencha o formulário seguinte.
                  </p>
                  <p>
                    Nós iremos divulgar o seu imóvel pelas melhores empresas de mediação imobiliária
                    localizadas no concelho onde está localizado o seu imóvel.
                  </p>
                </div>
              </div>
              <div className="section-announce-form">
                <NoSSR>
                  <AnnounceForm />
                </NoSSR>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Master>
  );
};

export default {
  component: AnnouncePage
};
