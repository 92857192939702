import React from 'react';
import { Link } from 'react-router-dom';
import removeAccents from 'remove-accents';
import LazyLoad from 'react-lazyload';
import PropertyMainFeatures from './PropertyMainFeatures';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faImages } from '@fortawesome/free-solid-svg-icons';
import {
  setThumbnail,
  setLocation,
  setTitle,
  setDescription,
  normalizeUrlParameter
} from '../../assets/utils';
import { propertyNoImage } from '../../assets/constants';

const PropertiesListItem = props => {
  const { customList, customListPath, gallery, gridType, property } = props;

  const {
    ROWNUMBER,
    PROPERTYID,
    Bedrooms,
    Description,
    Reference,
    Type,
    MAINPURPOSE,
    Note,
    MAINPHOTO,
    TOTAL_PHOTOS,
    LOCATIONSECONDLEVEL,
    LOCATIONTHIRDLEVEL,
    LOCATIONFOURTHLEVEL
  } = property;

  const characterLimit = gridType === 'property-row' ? 225 : 125;
  //Description
  const description = setDescription(Description, characterLimit);
  // Title
  const title = setTitle(Note, Type);
  // Location
  const location = setLocation(LOCATIONSECONDLEVEL, LOCATIONTHIRDLEVEL);
  // Thumbnail
  const thumbnail = MAINPHOTO ? setThumbnail(MAINPHOTO) : propertyNoImage;
  const PropertyItemImage = {
    backgroundImage: 'url(' + thumbnail + ')'
  };

  let locationUrlParameter = '';

  if (LOCATIONSECONDLEVEL) {
    locationUrlParameter = removeAccents(normalizeUrlParameter(LOCATIONSECONDLEVEL));
    if (LOCATIONTHIRDLEVEL) {
      locationUrlParameter = `${locationUrlParameter},${removeAccents(
        normalizeUrlParameter(LOCATIONTHIRDLEVEL)
      )}`;
      if (LOCATIONFOURTHLEVEL) {
        locationUrlParameter = `${locationUrlParameter},${removeAccents(
          normalizeUrlParameter(LOCATIONFOURTHLEVEL)
        )}`;
      }
    }
  }

  let typeUrl = removeAccents(normalizeUrlParameter(Type))
    .replace('/', ',')
    .toLowerCase();
  if (Bedrooms) {
    const bedLabel = Bedrooms > 1 ? 'quartos' : 'quarto';
    typeUrl = `${typeUrl},T${Bedrooms}-${Bedrooms}-${bedLabel}`;
  }

  const purposeUrl = removeAccents(normalizeUrlParameter(MAINPURPOSE)).toLowerCase();

  const pathUrl = `/imovel/${typeUrl}/${purposeUrl}/${locationUrlParameter.toLowerCase()}/${PROPERTYID}`;

  return (
    <article className={gallery ? 'item property' : 'property'}>
      <div className="property-item">
        <div className="property-item-wrapper">
          <figure className="property-item-thumbnail">
            <Link
              to={{
                pathname: pathUrl,
                state: {
                  customList: customList,
                  customListPath: customListPath,
                  rowNumber: ROWNUMBER
                }
              }}
            >
              <LazyLoad>
                <div className="img" style={PropertyItemImage} />
              </LazyLoad>
            </Link>
            <div className="property-item-purpose">{MAINPURPOSE}</div>
            <div className="property-item-total-photos">
              {TOTAL_PHOTOS} <FontAwesomeIcon icon={faImages} />
            </div>
          </figure>
          <div className="property-item-description">
            <div className="property-item-orientation">
              <div className="property-item-info">
                <PropertyMainFeatures {...property} />
              </div>
              <div className="property-item-title">
                <Link
                  to={{
                    pathname: pathUrl,
                    state: {
                      customList: customList,
                      customListPath: customListPath,
                      rowNumber: ROWNUMBER
                    }
                  }}
                >
                  {title}
                </Link>
                {location && (
                  <div className="property-item-location">
                    {location}
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </div>
                )}
              </div>
            </div>
            <div className="property-item-resume">{description}</div>
            <div className="property-item-reference">{Reference}</div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default PropertiesListItem;
