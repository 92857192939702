import { Handler, DefaultParameters, Modules, Actions } from '../assets/config';
import { apiRequest } from './api';
import {
  PROPERTIES_HAS_ERRORED,
  PROPERTIES_IS_LOADING,
  PROPERTIES_FETCH_DATA_SUCCESS,
  PROPERTIES_CLEAR_DATA
} from './types';
import { recordsPerPage } from '../assets/constants';
import { removeEmptyStrings } from '../assets/utils';

function propertiesHasErrored(bool) {
  return {
    type: PROPERTIES_HAS_ERRORED,
    hasErrored: bool
  };
}

function propertiesIsLoading(bool) {
  return {
    type: PROPERTIES_IS_LOADING,
    isLoading: bool
  };
}

function propertiesFetchDataSuccess(properties) {
  return {
    type: PROPERTIES_FETCH_DATA_SUCCESS,
    properties
  };
}

export function propertiesFetchData(filtersParameters = {}) {
  const moduleAction = {
    module: Modules.properties,
    action: Actions.list
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    recordsPerPage: recordsPerPage,
    ...filtersParameters
  };

  return async dispatch => {
    dispatch(propertiesIsLoading(true));
    await apiRequest(Handler, data)
      .then(response => {
        if (response.data.message) {
          throw Error(response.statusText);
        }
        dispatch(propertiesIsLoading(false));
        return response;
      })
      .then(response => response.data)
      .then(properties => dispatch(propertiesFetchDataSuccess(properties)))
      .catch(e => {
        dispatch(propertiesHasErrored(true));
        dispatch(propertiesIsLoading(false));
      });
  };
}

export async function propertiesGetData(filtersParameters = {}, success) {
  const moduleAction = {
    module: Modules.properties,
    action: Actions.list
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    recordsPerPage: recordsPerPage,
    ...filtersParameters
  };

  return await apiRequest(Handler, data)
    .then(response => {
      if (response.data.message) {
        throw Error(response.statusText);
      }
      if (success) success(response.data);
      return response;
    })
    .then(response => response.data)
    .catch(e => {});
}

export async function requestPropertyListDetail(reference, id, success) {
  propertiesGetData(
    removeEmptyStrings({
      reference: reference,
      pageNumber: 0
    }),
    result => {
      if (result && result.Properties && result.Properties.length > 0) {
        if (result.Properties.length > 1) {
          for (let j = 0; j < result.Properties.length; j++) {
            if (result.Properties[j].PROPERTYID === parseInt(id)) {
              if (success) success(result.Properties[j]);
            }
          }
        } else {
          if (success) success(result.Properties[0]);
        }
      }
    }
  );
}

export function propertiesClearData() {
  return {
    type: PROPERTIES_CLEAR_DATA
  };
}
