import axios from 'axios';

export const apiRequest = (url, data, method = 'get') => {
  return axios({
    method: method,
    url,
    withCredentials: true,
    responseType: 'json',
    responseEncoding: 'utf8',
    params: data
  });
};
