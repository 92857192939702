import {
  HIGHLIGHTS_HAS_ERRORED,
  HIGHLIGHTS_IS_LOADING,
  HIGHLIGHTS_FETCH_DATA_SUCCESS,
  HIGHLIGHTS_CLEAR_DATA
} from '../actions/types';

const highlightsReducerDefaultState = {
  list: null,
  counters: []
};

export function highlightsHasErrored(state = false, action) {
  switch (action.type) {
    case HIGHLIGHTS_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function highlightsIsLoading(state = false, action) {
  switch (action.type) {
    case HIGHLIGHTS_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function highlights(state = highlightsReducerDefaultState, action) {
  switch (action.type) {
    case HIGHLIGHTS_FETCH_DATA_SUCCESS:
      const highlightsList = action.highlights.Properties.map(property => property);
      const highlightsCounters = action.highlights.PropertyCounters[0];

      return {
        list: [...highlightsList],
        counters: highlightsCounters
      };
    case HIGHLIGHTS_CLEAR_DATA:
      return {
        list: null,
        counters: []
      };
    default:
      return state;
  }
}
