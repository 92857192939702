import {
  ANNOUNCE_FORM_HAS_ERRORED,
  ANNOUNCE_FORM_IS_LOADING,
  ANNOUNCE_FORM_FETCH_DATA_SUCCESS
} from '../actions/types';

let formFeedback = [];

const announceFormReducerDefaultState = {
  formFeedback
};

export function announceFormHasErrored(state = false, action) {
  switch (action.type) {
    case ANNOUNCE_FORM_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function announceFormIsLoading(state = false, action) {
  switch (action.type) {
    case ANNOUNCE_FORM_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function announceForm(state = announceFormReducerDefaultState, action) {
  switch (action.type) {
    case ANNOUNCE_FORM_FETCH_DATA_SUCCESS:
      formFeedback = action.formFeedback;

      return {
        formFeedback
      };

    default:
      return state;
  }
}
