import React from 'react';
import PropertiesFilter from '../properties/PropertiesFilter';
import { globalResources } from '../../resources/resources';

const Banner = () => {
  return (
    <div className="banner notranslate">
      <div className="banner-wrapper">
        <div className="banner-item">
          <div className="banner-image">
            <div className="img" />
          </div>
        </div>
      </div>
      <div className="row banner-content">
        <div className="banner-content-text">
          <div className="welcome-message translate">{globalResources.welcome}</div>
          <div className="main-message translate">
            <h1>{globalResources.findBuild}</h1>
            <h1>{globalResources.dreamHome}</h1>
          </div>
        </div>
        <div className="col banner-content-filters">
          <PropertiesFilter secondaryFields={false} />
        </div>
      </div>
    </div>
  );
};

export default Banner;
