import React, { Component, Fragment } from 'react';
import {
  setLocation,
  setPrice,
  setTitle,
  setDescription,
  numberWithSpaces
} from '../../assets/utils';
import { globalResources } from '../../resources/resources';
import ShowMoreText from 'react-show-more-text';

class PropertyPrintItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLoader: true,
      isExpanded: false,
      toggleContactForm: false,
      accordion: false,
      activeKey: []
    };
  }

  componentDidMount() {
    setTimeout(function() {
      window.print();
    }, 300);
  }

  componentWillUnmount() {
    this.state.resources.unmount();
  }

  renderFeatureChild(child) {
    return (
      <li className="featureC hildChild" key={child.ID}>
        {child.Description} {child.DataType !== 'B' && `- ${child.Value}`}
      </li>
    );
  }

  renderFeature(feature) {
    feature.children.map(
      childParent =>
        (childParent.children = this.props.features.filter(
          feature => feature.ParentID === childParent.ID
        ))
    );

    return (
      <div className="feature">
        <span>{feature.Description}</span>
        <ul>
          {feature.children &&
            feature.children.map(child => {
              return (
                <li className="featureChild" key={child.ID}>
                  {child.Description} {child.DataType !== 'B' && `- ${child.Value}`}
                  <ul>{child.children.map(child => this.renderFeatureChild(child))}</ul>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }

  renderFeatures() {
    const title = this.props.features.find(feature => feature.ParentID === 0);
    const parents = this.props.features.filter(
      feature => feature.ParentID === (title ? title.ID : 137)
    );

    parents.map(parent => {
      return (parent.children = this.props.features.filter(
        feature => feature.ParentID === parent.ID
      ));
    });

    return <Fragment>{parents.map(parent => this.renderFeature(parent))}</Fragment>;
  }

  toggleContactForm() {
    this.setState({ toggleContactForm: !this.state.toggleContactForm });
  }

  onChangePanel(activeKey) {
    this.setState({
      activeKey
    });
  }

  moreLessOnClick(isExpanded) {
    this.setState({ isExpanded });
  }

  render() {
    const { detail, features, subsidiary } = this.props;

    const {
      commercialdesignation,
      maincellphone,
      mainphone,
      mainphoto,
      address,
      address_zipcode,
      address_ziplocation,
      ami_license,
      apemip_license
    } = subsidiary;

    // Detail
    const price = setPrice(detail.MAINPURPOSE_PRICE, detail.MAINPURPOSE_COIN);
    const property_description = setDescription(detail.DESCRIPTION, 0);
    // Gallery
    const gallery = this.props.images;
    // Title
    const title = setTitle(detail.NOTE, detail.TYPE);
    // Location
    const location = setLocation(detail.LOCATIONSECONDLEVEL, detail.LOCATIONTHIRDLEVEL);

    return (
      <article className="property-detail-print">
        <section>
          <div className="section-wrapper-print">
            <header className="section-header">
              <div className="contact-agency">
                <div className="agency-description">
                  {commercialdesignation && (
                    <div className="agency-name">{commercialdesignation}</div>
                  )}
                  <div className="agency-section">
                    <div className="agency-info">
                      <div className="agency-license">
                        {ami_license && <span>AMI: {ami_license}</span>}
                        {ami_license && apemip_license && <span>/</span>}
                        {apemip_license && <span>AMI: {apemip_license}</span>}
                      </div>
                      {address && address_zipcode && address_ziplocation && (
                        <div className="agency-location">
                          <span>{address}</span>
                          <span>
                            {address_zipcode}, {address_ziplocation}
                          </span>
                        </div>
                      )}
                      <div className="agency-contats">
                        <Fragment>
                          {mainphone && (
                            <div className="agency-contact">{numberWithSpaces(mainphone)}</div>
                          )}
                          {maincellphone && (
                            <div className="agency-contact">{numberWithSpaces(maincellphone)}</div>
                          )}
                        </Fragment>
                      </div>
                    </div>
                    {mainphoto && (
                      <div className="agency-image">
                        <img src={mainphoto} alt={commercialdesignation} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <span>{detail.MAINPURPOSE} </span>
              <span>
                {globalResources.reference}: {detail.Reference}
              </span>
              <h1>{title}</h1>
              {location && <h2>{location}</h2>}
            </header>
            <div className="section-content">
              <div className="section-content-wrapper">
                <div className="section-property-info">
                  <div className="info-wrapper">
                    {price && (
                      <div className="info-item">
                        <div>{globalResources.price}</div>
                        <div>{price}</div>
                      </div>
                    )}
                    {(detail.Bedrooms || detail.Bedrooms === 0) && (
                      <div className="info-item">
                        <div>{globalResources.beds}</div>
                        <div>{detail.Bedrooms}</div>
                      </div>
                    )}
                    {(detail.BathRooms || detail.BathRooms === 0) && (
                      <div className="info-item">
                        <div>{globalResources.bathRooms}</div>
                        <div>{detail.BathRooms}</div>
                      </div>
                    )}
                    {detail.NettArea && (
                      <div className="info-item">
                        <div>{globalResources.nettArea}</div>
                        <div>
                          {detail.NettArea} <span>m2</span>
                        </div>
                      </div>
                    )}
                    {detail.FloorArea && (
                      <div className="info-item">
                        <div>{globalResources.area}</div>
                        <div>
                          {detail.FloorArea} <span>m2</span>
                        </div>
                      </div>
                    )}
                    {detail.GarageCars && (
                      <div className="info-item">
                        <div>{globalResources.garages}</div>
                        <div>{detail.GarageCars}</div>
                      </div>
                    )}
                    {detail.CONDITION && (
                      <div className="info-item">
                        <div>{globalResources.condition}</div>
                        <div>{detail.CONDITION}</div>
                      </div>
                    )}
                  </div>
                  {gallery.length > 0 && (
                    <div className="row gallery-print">
                      <div className="col">
                        <img src={gallery[0].Image} alt={gallery[0].DESCRIPTION} />
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            {gallery.length > 1 && (
                              <img src={gallery[1].Image} alt={gallery[1].DESCRIPTION} />
                            )}
                          </div>
                          <div className="col">
                            {gallery.length > 2 && (
                              <img src={gallery[2].Image} alt={gallery[2].DESCRIPTION} />
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            {gallery.length > 3 && (
                              <img src={gallery[3].Image} alt={gallery[3].DESCRIPTION} />
                            )}
                          </div>
                          <div className="col">
                            {gallery.length > 4 && (
                              <img src={gallery[4].Image} alt={gallery[4].DESCRIPTION} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {property_description && (
                    <div className="property-item-resume">
                      <h4>{globalResources.description}</h4>
                      <ShowMoreText
                        lines={999}
                        more="Ler mais"
                        less="Ler menos"
                        anchorClass={this.state.isExpanded ? 'read-less' : 'read-more'}
                        onClick={this.moreLessOnClick}
                        expanded={false}
                      >
                        {property_description.split('\n').map((item, key) => {
                          return (
                            <Fragment key={key}>
                              {item}
                              <br />
                            </Fragment>
                          );
                        })}
                      </ShowMoreText>
                    </div>
                  )}
                  {features.length > 0 && (
                    <div className="property-features">
                      <h4>{globalResources.features}</h4>
                      {this.renderFeatures()}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <footer>
              <span>{globalResources.copyright}</span>
              <span>
                {globalResources.developed} <b>{globalResources.developer}</b>
              </span>
            </footer>
          </div>
        </section>
      </article>
    );
  }
}

export default PropertyPrintItem;
