import { reset } from 'redux-form';
import { notifySuccess, notifyError } from '../components/elements/Notify';
import {
  Handler,
  DefaultParameters,
  ContactDefaultParameters,
  Modules,
  Actions
} from '../assets/config';
import { JsonUrlEncode } from '../assets/utils';
import { apiRequest } from './api';
import {
  SUGGEST_FORM_HAS_ERRORED,
  SUGGEST_FORM_IS_LOADING,
  SUGGEST_FORM_FETCH_DATA_SUCCESS
} from './types';
import { formResources } from '../resources/resources';

function suggestFormHasErrored(bool) {
  return {
    type: SUGGEST_FORM_HAS_ERRORED,
    hasErrored: bool
  };
}

function suggestFormIsLoading(bool) {
  return {
    type: SUGGEST_FORM_IS_LOADING,
    isLoading: bool
  };
}

function suggestFormFetchDataSuccess(formFeedback) {
  return {
    type: SUGGEST_FORM_FETCH_DATA_SUCCESS,
    formFeedback
  };
}

export function suggestFormFetchData(values, dispatch, propertyData) {
  const replacements = values;

  const moduleAction = {
    module: Modules.contacts,
    action: Actions.sendEmail
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    ...ContactDefaultParameters,
    template: 'recommend',
    replacements: JsonUrlEncode({
      ...replacements,
      ...propertyData
    })
  };

  dispatch(suggestFormIsLoading(true));
  return apiRequest(Handler, data)
    .then(response => {
      if (response.data.message) {
        throw Error(response.statusText);
      }
      dispatch(suggestFormIsLoading(false));
      return response;
    })
    .then(response => response.data)
    .then(formFeedback => {
      switch (formFeedback) {
        case true:
          notifySuccess(formResources.feedback.success);
          dispatch(reset('suggest'));
          break;
        default:
          notifyError(formResources.feedback.error);
          break;
      }
      dispatch(suggestFormFetchDataSuccess(formFeedback));
    })
    .catch(e => {
      notifyError(formResources.feedback.error);
      dispatch(suggestFormHasErrored(true));
      dispatch(suggestFormIsLoading(false));
    });
}
