import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import PropertiesListItem from '../components/properties/PropertiesListItem';
import PropertiesLastVisited from '../components/properties/PropertiesLastVisited';
import Master from '../components/elements/Master';
import NoResults from '../components/elements/NoResults';
import Breadcrumbs from '../components/elements/Breadcrumbs';
import { globalResources } from '../resources/resources';
import { recordsPerPageFavorites } from '../assets/constants';
import { getPageData, RemoveIdenticalFromArray, isRunningOnServerSide } from '../assets/utils';
import { CryptoKey } from '../assets/config';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { AES, enc } from 'crypto-js';

class PropertiesFavoritesPage extends Component {
  constructor(props) {
    super(props);
    this.onResize = this.onResize.bind(this);
    this.state = {
      gridType: 'property-row',
      radioSelected: 'property-row',
      count: 0,
      datasource: [],
      activePage: 1,
      recordsPerPage: recordsPerPageFavorites,
      isMobile: isRunningOnServerSide() ? false : window.innerWidth < 991
    };
  }

  componentDidMount() {
    this.getPropertiesFavorites();
    if (!isRunningOnServerSide()) {
      window.addEventListener('resize', this.onResize, false);
    }
  }

  componentWillUnmount() {
    if (!isRunningOnServerSide()) {
      window.removeEventListener('resize', this.onResize, false);
    }
  }

  onResize() {
    if (!isRunningOnServerSide()) {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }
  }

  getPropertiesFavorites() {
    var favorites = localStorage.getItem('favorites');

    if (!favorites || favorites === '') {
      favorites = [];
      localStorage.setItem('favorites', CryptoKey ? AES.encrypt('[]', CryptoKey) : '[]');
    } else {
      favorites = JSON.parse(
        CryptoKey ? AES.decrypt(favorites, CryptoKey).toString(enc.Utf8) : favorites
      );
    }

    if (favorites.length > 0) {
      favorites = RemoveIdenticalFromArray(favorites, 'PROPERTYID');
      this.setState({
        datasource: getPageData(favorites, this.state.activePage, this.state.recordsPerPage),
        count: favorites.length
      });
    } else {
      this.setState({ datasource: [], count: 0 });
    }
  }

  renderList() {
    return (
      <Fragment>
        {this.state.datasource.map(property => (
          <PropertiesListItem
            customList={this.state.datasource}
            customListPath="/favoritos"
            key={property.PROPERTYID}
            property={property}
            gridType={this.state.gridType}
          />
        ))}
      </Fragment>
    );
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    this.getPropertiesFavorites();
  }

  render() {
    let grid_type_class = this.state.isMobile ? 'property-column' : this.state.gridType;
    return (
      <Master>
        <Helmet key={Math.random()}>
          <title>Casa e Sol - Favoritos</title>
          <meta property="og:title" content="Casa e Sol - Favoritos" />
        </Helmet>
        <div id="properties-favorites">
          <Breadcrumbs page={globalResources.favorites} />
          <section className="section-properties-list">
            <div className="section-wrapper">
              <header className="section-header">
                <h3>{globalResources.favorites}</h3>
              </header>
              <div className="section-content">
                <div className="section-content-wrapper">
                  <div className="properties-list">
                    <div className={`properties-list-wrapper ${grid_type_class}`}>
                      {this.state.datasource && this.state.datasource.length > 0 ? (
                        this.renderList()
                      ) : (
                        <NoResults
                          icon={faStar}
                          title={globalResources.noResults}
                          subtitle={globalResources.noResultsSub}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-properties-list">
            <div className="section-wrapper">
              <header className="section-header">
                <h3>{globalResources.lastVisitedTitle}</h3>
              </header>
              <div className="section-content">
                <div className="section-content-wrapper">
                  <div className="properties-list">
                    <div className={`properties-list-wrapper ${grid_type_class}`}>
                      <PropertiesLastVisited />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Master>
    );
  }
}

export default {
  component: PropertiesFavoritesPage
};
