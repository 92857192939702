import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AES, enc } from 'crypto-js';
import NoSSR from 'react-no-ssr';
import Master from '../components/elements/Master';
import PropertyDetailItem from '../components/properties/PropertyDetailItem';
import PropertiesNavigate from '../components/properties/PropertiesNavigate';
import Loader from '../components/elements/Loader';
import {
  propertyFetchData,
  propertyFeaturesFetchData,
  propertyClearData
} from '../actions/property';
import { propertiesFetchData, requestPropertyListDetail } from '../actions/properties';
import { RemoveFromArray, RemoveIdenticalFromArray, isEmpty } from '../assets/utils';
import { recordsPerPageLastVisited } from '../assets/constants';
import { CryptoKey } from '../assets/config';

class PropertyDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.location.state,
      lastVisitId: undefined,
      listProperty: null,
      isMounted: false
    };
  }

  async componentDidMount() {
    await this.props.propertyFetchData(this.props.match.params.id);
    this.props.propertyFeaturesFetchData(this.props.match.params.id);
    this.addLastVisit();
    this.setState({
      isMounted: true
    });
  }

  componentWillUnmount() {
    this.props.propertyClearData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.props.propertyFetchData(this.props.match.params.id);
      this.props.propertyFeaturesFetchData(this.props.match.params.id);
      this.setState({
        ...this.props.location.state
      });
    }
    if (this.state.lastVisitId !== this.props.match.params.id) {
      this.addLastVisit();
    }
  }

  renderProperty() {
    switch (true) {
      case this.props.property.propertyIsLoading:
        return <Loader />;
      default:
        if (!isEmpty(this.props.property.detail) && !this.props.property.propertyHasErrored) {
          return <PropertyDetailItem {...this.props.property} lastList={this.props.lastList} />;
        } else {
          return <Redirect to="/imovel-nao-encontrado" />;
        }
    }
  }

  async addLastVisit() {
    let property = null;
    const id = this.props.match.params.id;

    if (id) {
      if (
        this.state.customList &&
        this.state.customList != null &&
        this.state.customList.length > 0
      ) {
        property = this.state.customList.find(p => p.PROPERTYID === parseInt(id));
        if (property && property !== null && property.PROPERTYID) {
          property = Object.assign({}, property);
        }
      } else {
        if (
          !property &&
          this.props.lastList &&
          this.props.lastList != null &&
          this.props.lastList.length > 0
        ) {
          property = this.props.lastList.find(p => p.PROPERTYID === parseInt(id));
          if (property && property !== null && property.PROPERTYID) {
            property = Object.assign({}, property);
          }
        }
      }
      if (!property && this.props.property.detail) {
        property = await requestPropertyListDetail(this.props.property.detail.Reference, id);
      }

      if (property && property !== null && property.PROPERTYID) {
        var propertieslastvisited = localStorage.getItem('propertieslastvisited');
        if (!propertieslastvisited || propertieslastvisited === '') {
          propertieslastvisited = [];
          localStorage.setItem(
            'propertieslastvisited',
            CryptoKey ? AES.encrypt('[]', CryptoKey) : '[]'
          );
        } else {
          propertieslastvisited = JSON.parse(
            CryptoKey
              ? AES.decrypt(propertieslastvisited, CryptoKey).toString(enc.Utf8)
              : propertieslastvisited
          );
          propertieslastvisited = RemoveIdenticalFromArray(propertieslastvisited, 'PROPERTYID');
          propertieslastvisited = RemoveFromArray(
            propertieslastvisited,
            'PROPERTYID',
            parseInt(id)
          );
        }

        if (propertieslastvisited.length >= recordsPerPageLastVisited) propertieslastvisited.pop();
        propertieslastvisited.unshift(property);
        for (let i = 0; i < propertieslastvisited.length; i++) {
          propertieslastvisited[i].ROWNUMBER = i + 1;
        }
        localStorage.setItem(
          'propertieslastvisited',
          CryptoKey
            ? AES.encrypt(JSON.stringify(propertieslastvisited), CryptoKey)
            : JSON.stringify(propertieslastvisited)
        );

        this.setState({
          lastVisitId: id
        });
      }
    }
  }

  render() {
    const propertyTitle =
      this.props.property.detail &&
      (this.props.property.detail.NOTE
        ? `Casa e Sol - ${this.props.property.detail.NOTE}`
        : `Casa e Sol - ${this.props.property.detail.TYPE}`);

    return (
      <Master>
        <Helmet key={Math.random()}>
          <title>{propertyTitle}</title>
          <meta property="og:title" content={propertyTitle} />
        </Helmet>
        <NoSSR>
          <PropertiesNavigate
            id={this.props.match.params.id}
            customList={this.state.customList}
            customListPath={this.state.customListPath}
            rowNumber={this.state.rowNumber}
          ></PropertiesNavigate>
        </NoSSR>
        {this.state.isMounted && this.renderProperty()}
      </Master>
    );
  }
}

const mapStateToProps = state => {
  return {
    lastList: state.properties.lastList,
    property: {
      propertyHasErrored: state.propertyHasErrored,
      propertyIsLoading: state.propertyIsLoading,
      propertyFeaturesHasErrored: state.propertyFeaturesHasErrored,
      propertyFeaturesIsLoading: state.propertyFeaturesIsLoading,
      detail: state.property.detail[0],
      descriptions: state.property.descriptions,
      notes: state.property.notes,
      address: state.property.address[0],
      images: state.property.images,
      families: state.property.families,
      subsidiary: state.property.subsidiary[0],
      features: state.property.features
    },
    properties: {
      list: state.properties.lastList,
      count: state.properties.lastCounters.PropertyCount
    },
    search: state.search
  };
};

const mapDispatchToProps = store => {
  return {
    propertyFetchData: propertyID => store.dispatch(propertyFetchData(propertyID)),
    propertyClearData: () => store.dispatch(propertyClearData()),
    propertyFeaturesFetchData: propertyID => store.dispatch(propertyFeaturesFetchData(propertyID)),
    propertiesFetchData: filtersParameters => store.dispatch(propertiesFetchData(filtersParameters))
  };
};

export default {
  component: connect(mapStateToProps, {
    propertyFetchData,
    propertyClearData,
    propertyFeaturesFetchData,
    propertiesFetchData
  })(withRouter(PropertyDetailPage)),
  mapDispatchToProps
};
