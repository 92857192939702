import {
  Handler,
  DefaultParameters,
  ContactDefaultParameters,
  Modules,
  Actions,
  CountryID
} from '../assets/config';
import { apiRequest } from './api';
import { reset } from 'redux-form';
import { notifySuccess, notifyError } from '../components/elements/Notify';
import {
  PROPERTY_FORM_HAS_ERRORED,
  PROPERTY_FORM_IS_LOADING,
  PROPERTY_FORM_FETCH_DATA_SUCCESS
} from './types';
import { JsonUrlEncode, setLocation, numberWithSpaces } from '../assets/utils';
import { formResources } from '../resources/resources';

function propertyFormHasErrored(bool) {
  return {
    type: PROPERTY_FORM_HAS_ERRORED,
    hasErrored: bool
  };
}

function propertyFormIsLoading(bool) {
  return {
    type: PROPERTY_FORM_IS_LOADING,
    isLoading: bool
  };
}

function propertyFormFetchDataSuccess(formFeedback) {
  return {
    type: PROPERTY_FORM_FETCH_DATA_SUCCESS,
    formFeedback
  };
}

export function propertyFormFetchData(
  values,
  dispatch,
  propertyData,
  subsidiaryEmail,
  subsidiaryName,
  subsidiaryPhone
) {
  let replacements = values;

  const locationLevels = setLocation(
    replacements.property_district,
    replacements.property_county,
    replacements.property_parish
  );

  replacements = {
    ...replacements,
    locationLevels: locationLevels
  };

  const moduleAction = {
    module: Modules.contacts,
    action: Actions.sendEmail
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    ...ContactDefaultParameters,
    template: 'property_info',
    to: `['${subsidiaryEmail}']`,
    replacements: JsonUrlEncode({
      ...replacements,
      ...propertyData,
      agency_name: subsidiaryName,
      agency_phone:
        subsidiaryPhone && subsidiaryPhone.length > 0 ? numberWithSpaces(subsidiaryPhone) : ''
    }),
    countryID: CountryID
  };

  dispatch(propertyFormIsLoading(true));
  return apiRequest(Handler, data)
    .then(response => {
      if (response.data.message) {
        throw Error(response.statusText);
      }
      dispatch(propertyFormIsLoading(false));
      return response;
    })
    .then(response => response.data)
    .then(formFeedback => {
      switch (formFeedback) {
        case true:
          notifySuccess(formResources.feedback.success);
          dispatch(reset('property'));
          break;
        default:
          notifyError(formResources.feedback.error);
          break;
      }
      dispatch(propertyFormFetchDataSuccess(formFeedback));
    })
    .catch(e => {
      notifyError(formResources.feedback.error);
      dispatch(propertyFormHasErrored(true));
      dispatch(propertyFormIsLoading(false));
    });
}
