import { Handler, DefaultParameters, CountryID, Modules, Actions } from '../assets/config';
import { apiRequest } from './api';
import {
  FILTERS_HAS_ERRORED,
  FILTERS_IS_LOADING,
  FILTERS_FETCH_DATA_SUCCESS,
  LOCATIONS_FETCH_DATA_SUCCESS,
  LOCATIONS_CLEAR_DATA,
  BEDROOMS_FETCH_DATA_SUCCESS,
  BEDROOMS_CLEAR_DATA,
  PRICES_BUY_FETCH_DATA_SUCCESS,
  PRICES_RENT_FETCH_DATA_SUCCESS,
  PRICES_CLEAR_DATA
} from './types';

function filtersHasErrored(bool) {
  return {
    type: FILTERS_HAS_ERRORED,
    hasErrored: bool
  };
}

function filtersIsLoading(bool) {
  return {
    type: FILTERS_IS_LOADING,
    isLoading: bool
  };
}

function filtersFetchDataSuccess(filters) {
  return {
    type: FILTERS_FETCH_DATA_SUCCESS,
    filters
  };
}

function locationsFetchDataSuccess(filters, table) {
  return {
    type: LOCATIONS_FETCH_DATA_SUCCESS,
    filters,
    table
  };
}

export function filtersFetchData(params = {}) {
  const moduleAction = {
    module: Modules.fillForms,
    action: Actions.list
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    ...params,
    countryID: CountryID
  };

  return async dispatch => {
    dispatch(filtersIsLoading(true));
    await apiRequest(Handler, data)
      .then(response => {
        if (response.data.message) {
          throw Error(response.statusText);
        }
        dispatch(filtersIsLoading(false));
        return response;
      })
      .then(response => response.data)
      .then(filters => dispatch(filtersFetchDataSuccess(filters)))
      .catch(e => {
        dispatch(filtersHasErrored(true));
        dispatch(filtersIsLoading(false));
      });
  };
}

export function locationsFetchData(params = {}, table, withAssociatedProperties = true) {
  const moduleAction = {
    module: Modules.fillForms,
    action: Actions.location
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    ...params,
    withAssociatedProperties: withAssociatedProperties
  };

  return async dispatch => {
    dispatch(filtersIsLoading(true));
    await apiRequest(Handler, data)
      .then(response => {
        if (response.data.message) {
          throw Error(response.statusText);
        }
        dispatch(filtersIsLoading(false));
        return response;
      })
      .then(response => response.data)
      .then(filters => dispatch(locationsFetchDataSuccess(filters, table)))
      .catch(e => {
        dispatch(filtersHasErrored(true));
        dispatch(filtersIsLoading(false));
      });
  };
}

export function locationsClearData(table = '') {
  return {
    type: LOCATIONS_CLEAR_DATA,
    table
  };
}

export function bedroomsFetchData() {
  return {
    type: BEDROOMS_FETCH_DATA_SUCCESS
  };
}

export function bedroomsClearData() {
  return {
    type: BEDROOMS_CLEAR_DATA
  };
}

export function pricesBuyFetchData() {
  return {
    type: PRICES_BUY_FETCH_DATA_SUCCESS
  };
}

export function pricesRentFetchData() {
  return {
    type: PRICES_RENT_FETCH_DATA_SUCCESS
  };
}

export function pricesClearData() {
  return {
    type: PRICES_CLEAR_DATA
  };
}
