import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  locationsFetchData,
  locationsClearData,
  bedroomsFetchData,
  bedroomsClearData,
  pricesBuyFetchData,
  pricesRentFetchData,
  pricesClearData
} from '../../actions/filters';
import { orderByFormFetchData } from '../../actions/filterForm';

class FormSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: props.isDisabled
    };
  }

  componentDidMount() {
    if (this.props.options) {
      if (this.props.options.length <= 0) {
        this.setState({
          isDisabled: true
        });
      } else {
        this.setState({
          isDisabled: false
        });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      switch (this.props.input.name) {
        case 'district':
        case 'property_district':
          if (this.props.input.value) {
            await this.props.clearLocationData('parish');
            await this.props.fetchLocationsData(
              {
                table: 'County',
                districtID: this.props.input.value.value
              },
              'County',
              this.props.withAssociatedProperties
            );
          } else {
            this.props.clearLocationData();
          }
          break;
        case 'county':
        case 'property_county':
          if (this.props.input.value) {
            await this.props.fetchLocationsData(
              {
                table: 'Parish',
                countyID: this.props.input.value.value
              },
              'Parish',
              this.props.withAssociatedProperties
            );
          } else {
            this.props.clearLocationData('parishes');
          }
          break;
        case 'purpose':
        case 'property_purpose':
          if (this.props.input.value) {
            switch (this.props.input.value.value) {
              case 240:
              case 1605:
                await this.props.clearPricesData();
                this.props.fetchPricesBuyData();
                break;
              case 241:
              case 1606:
                await this.props.clearPricesData();
                this.props.fetchPricesRentData();
                break;
              default:
                this.props.clearPricesData();
                break;
            }
          } else {
            this.props.clearPricesData();
          }
          break;
        case 'type':
        case 'property_type':
          if (this.props.input.value) {
            if (this.props.input.value.ShowBedRooms) {
              this.props.fetchBedroomsData();
            } else {
              this.props.clearBedroomsData();
            }
          } else {
            this.props.clearBedroomsData();
          }
          break;
        case 'orderBy':
          orderByFormFetchData(
            this.props.input.value,
            this.props.location.search,
            this.props.history
          );
          break;
        default:
          break;
      }
    }

    if (prevProps.options !== this.props.options) {
      if (this.props.options.length <= 0) {
        this.props.input.onChange('');
        this.setState({
          isDisabled: true
        });
      } else {
        this.setState({
          isDisabled: false
        });
      }
    }
  }

  handleChange(event) {
    this.props.input.onChange(event);
  }

  handleBlur() {
    setTimeout(() => {
      this.props.input.onBlur(this.props.input.value);
    }, 1);
  }

  render() {
    const { touched, error, warning } = this.props.meta;

    return (
      <div className={`form-group ${this.props.class ? this.props.class : ''}`}>
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <Select
          {...this.props.input}
          onChange={e => this.handleChange(e)}
          onBlur={value => this.handleBlur(value)}
          options={this.props.options}
          isClearable={this.props.isClearable}
          isDisabled={this.state.isDisabled}
          placeholder={this.props.placeholder}
        />
        {touched &&
          ((error && (
            <div className="form-feedback">
              <span>{error}</span>
            </div>
          )) ||
            (warning && (
              <div className="form-feedback">
                <span>{warning}</span>
              </div>
            )))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasErrored: state.filtersHasErrored,
    isLoading: state.filtersIsLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchLocationsData: (params, table, withAssociatedProperties) =>
      dispatch(locationsFetchData(params, table, withAssociatedProperties)),
    clearLocationData: table => dispatch(locationsClearData(table)),
    fetchBedroomsData: () => dispatch(bedroomsFetchData()),
    clearBedroomsData: () => dispatch(bedroomsClearData()),
    fetchPricesBuyData: () => dispatch(pricesBuyFetchData()),
    fetchPricesRentData: () => dispatch(pricesRentFetchData()),
    clearPricesData: () => dispatch(pricesClearData())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FormSelect));
