import React from 'react';
import { Helmet } from 'react-helmet';
import NoSSR from 'react-no-ssr';
import RegisterForm from '../components/forms/RegisterForm';
import Breadcrumbs from '../components/elements/Breadcrumbs';
import Master from '../components/elements/Master';

const RegisterPage = () => {
  const head = () => {
    return (
      <Helmet key={Math.random()}>
        <title>Casa e Sol - Registo</title>
        <meta property="og:title" content="Casa e Sol - Registo" />
      </Helmet>
    );
  };

  return (
    <Master>
      {head()}
      <Breadcrumbs page={`Anunciar`} />
      <section>
        <div className="section-wrapper">
          <header className="section-header">
            <h3>Registo</h3>
          </header>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="row">
                <div className="col">
                  <p>
                    Quer anunciar no portal Casa e Sol? Aproveite a oferta temporária e anuncie
                    grátis os seus imóveis:
                  </p>
                  <p>
                    P.f. aguarde o nosso e-mail de confirmação. A informação será validada e só após
                    a validação será atribuído o acesso.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className="section-content-wrapper">
              <NoSSR>
                <RegisterForm />
              </NoSSR>
            </div>
          </div>
        </div>
      </section>
    </Master>
  );
};

export default {
  component: RegisterPage
};
