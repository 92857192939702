import { Countries, defaultSettings } from '../json/config';
import { JsonUrlEncode } from './utils';

let apiUrl;
export let CryptoKey;

switch (process.env.NODE_ENV) {
  case 'development':
  case 'test':
    // Run in development or __test__ mode
    apiUrl = 'http://localhost:3100';
    CryptoKey = 'Improxy 171 2019';
    break;

  case 'production':
  default:
    // Run in production mode
    apiUrl = 'https://api.casaesol.com';
    CryptoKey = 'Improxy 171 2019';
    break;
}

// GENERAL PARAMETERS
export const Handler = `${apiUrl}/app_handlers/json.ashx`;
export const urlShare = `${apiUrl}/sharer/share.aspx`;
export const culture = 'PT-pt';
export const Modules = {
  properties: 'Properties',
  fillForms: 'FillForms',
  contacts: 'Contacts',
};
export const Actions = {
  list: 'List',
  detail: 'Detail',
  location: 'Location',
  sendEmail: 'SendEmail',
};

// PORTAL PARAMETERS
export const portalGuid = Countries[defaultSettings.countryID].guid;
export const CountryID = Countries[defaultSettings.countryID].value;
export const Country = Countries[defaultSettings.countryID];
export const GTMID = Countries[defaultSettings.countryID].gtmId;
export const currencyID = Countries[defaultSettings.countryID].currencyID;
export const googleSiteKey = Countries[defaultSettings.countryID].googleSiteKey;
export const portalName = Countries[defaultSettings.countryID].name;
export const portalLink = Countries[defaultSettings.countryID].link;
export const portalLogo = Countries[defaultSettings.countryID].link + 'images/logo.png';
export const currencySymbol = Countries[defaultSettings.countryID].currencySymbol;
export const DefaultParameters = {
  portalGuid: portalGuid,
  culture: culture,
  currencyID: currencyID,
};
export const ContactDefaultParameters = {
  portalName: JsonUrlEncode(portalName),
  portalLink: JsonUrlEncode(portalLink),
  portalLogo: JsonUrlEncode(portalLogo),
};

// EMAIL SETTINGS
export const registerTO = 'com@improxy.com';
export const registerCC = 'paulo.alexandre@improxy.com';
export const improxyTO = 'marketing@improxy.com';
