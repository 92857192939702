import React from 'react';
import { Link, withRouter, matchPath } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { globalResources } from '../../resources/resources';
import { facebookUrl } from '../../assets/constants';

const Footer = props => {
  const getParams = pathname => {
    const matchProfile = matchPath(pathname, {
      path: `/imovel/:type?/:purpose?/:location?/:id`
    });
    return matchProfile && matchProfile.params ? true : false;
  };

  return (
    <footer>
      <div className="section-wrapper">
        <div className="footer-copyright">
          <div>{globalResources.copyright}</div>
          <div>
            <ul>
              <li>
                <Link to="/termos-de-uso">{globalResources.terms}</Link>
              </li>
              <li>
                <Link to="/politica-de-privacidade">{globalResources.policy}</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-contact-form">
          {getParams(props.location.pathname) ? (
            <HashLink to={`${props.location.pathname}#contactar-anunciante`}>
              <div className="question">{globalResources.haveQuestions}</div>
              <div>{globalResources.contactAgent}</div>
            </HashLink>
          ) : (
            <Link to="/contacto">
              <div className="question">{globalResources.haveQuestions}</div>
              <div>{globalResources.contactUs}</div>
            </Link>
          )}
        </div>
        <div className="footer-social">
          <div>
            <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </div>
        </div>
        <div className="footer-powered">
          <div>
            <a href="https://www.improxy.com/" target="_blank" rel="noopener noreferrer">
              {globalResources.design}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
