import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { toast } from 'react-toastify';
import TagManager from 'react-gtm-module';
import reducers from './reducers';
import Routes from './Routes';
import { GTMID } from './assets/config';
import 'normalize.css';
import 'rc-collapse/assets/index.css';
import 'leaflet/dist/leaflet.css';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/dist/style.css';
import './styles/scss/styles.scss';

const tagManagerArgs = {
  gtmId: GTMID
};

TagManager.initialize(tagManagerArgs);

toast.configure({
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
});

const state = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const store = createStore(reducers, state, applyMiddleware(thunk));
const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate;
const jsx = (
  <Provider store={store}>
    <BrowserRouter>
      <div>{renderRoutes(Routes)}</div>
    </BrowserRouter>
  </Provider>
);

renderMethod(jsx, document.getElementById('root'));
