import removeAccents from 'remove-accents';
import queryString from 'query-string';
import { removeEmptyStrings, normalizeUrlParameter } from '../assets/utils';
import { Country } from '../assets/config';

export function filterFormFetchData(values, history) {
  if (values.reference) {
    const queryHistory = {
      pathname: `/imoveis/-/${removeAccents(normalizeUrlParameter(Country.label))}`,
      search: queryString.stringify({ reference: values.reference })
    };
    history.push(queryHistory);
  } else {
    let locationUrlParameter = removeAccents(normalizeUrlParameter(Country.label));

    const purposeUrlParameter = values.purpose
      ? removeAccents(normalizeUrlParameter(values.purpose.label))
      : '-';

    const typeUrlParameter = values.type
      ? removeAccents(normalizeUrlParameter(values.type.label))
      : '';

    const districtUrlParameter = values.district
      ? removeAccents(normalizeUrlParameter(values.district.label))
      : '';
    if (districtUrlParameter) {
      locationUrlParameter = districtUrlParameter;
      const countyUrlParameter = values.county
        ? removeAccents(normalizeUrlParameter(values.county.label))
        : '';
      if (countyUrlParameter) {
        locationUrlParameter = `${locationUrlParameter},${countyUrlParameter}`;
        const parishUrlParameter = values.parish
          ? removeAccents(normalizeUrlParameter(values.parish.label))
          : '';
        if (parishUrlParameter) {
          locationUrlParameter = `${locationUrlParameter},${parishUrlParameter}`;
        }
      }
    }

    const bedrooms = orderMinMax(values.bedroomsMin, values.bedroomsMax);
    const prices = orderMinMax(values.pricesMin, values.pricesMax);
    const floorAreas = orderMinMax(values.floorAreaMin, values.floorAreaMax);

    const queryParameters = removeEmptyStrings({
      roomsMin: bedrooms.min ? bedrooms.min.value : '',
      roomsMax: bedrooms.max ? bedrooms.max.value : '',
      pricesMin: prices.min ? prices.min.value : '',
      pricesMax: prices.max ? prices.max.value : '',
      floorAreaMin: floorAreas.min ? floorAreas.min.value : '',
      floorAreaMax: floorAreas.max ? floorAreas.max.value : ''
    });

    const queryHistory = {
      pathname: `/imoveis/${purposeUrlParameter}/${locationUrlParameter}/${typeUrlParameter}`,
      search: queryString.stringify(queryParameters)
    };
    history.push(queryHistory);
  }
}

export function orderByFormFetchData(option, query, history) {
  const queryStringParams = queryString.parse(query);
  const queryHistory = {
    search: queryString.stringify({
      ...queryStringParams,
      orderBy: removeAccents(normalizeUrlParameter(option.label))
    })
  };
  history.push(queryHistory);
}

const orderMinMax = (min, max) => {
  let new_min = min;
  let new_max = max;
  if (min && max) {
    if (parseInt(min.value) > parseInt(max.value)) {
      new_min = max;
      new_max = min;
    }
  }
  return { min: new_min, max: new_max };
};
