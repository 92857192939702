import React, { Fragment, Component } from 'react';
import PropertiesListItem from './PropertiesListItem';
import { getPageData, RemoveIdenticalFromArray } from '../../assets/utils';
import { recordsPerPageLastVisited } from '../../assets/constants';
import { CryptoKey } from '../../assets/config';
import { AES, enc } from 'crypto-js';

class PropertiesLastVisited extends Component {
  constructor() {
    super();
    this.state = {
      datasource: { datasource: [], count: 0 }
    };
  }

  componentDidMount() {
    this.getPropertiesLastVisited();
  }

  getPropertiesLastVisited() {
    let propertieslastvisited = localStorage.getItem('propertieslastvisited');

    if (!propertieslastvisited || propertieslastvisited === '') {
      propertieslastvisited = [];
      localStorage.setItem(
        'propertieslastvisited',
        CryptoKey ? AES.encrypt('[]', CryptoKey) : '[]'
      );
    } else {
      propertieslastvisited = JSON.parse(
        CryptoKey
          ? AES.decrypt(propertieslastvisited, CryptoKey).toString(enc.Utf8)
          : propertieslastvisited
      );
    }

    if (propertieslastvisited.length > 0) {
      propertieslastvisited = RemoveIdenticalFromArray(propertieslastvisited, 'PROPERTYID');
      this.setState({
        datasource: {
          datasource: getPageData(propertieslastvisited, 1, recordsPerPageLastVisited),
          count: propertieslastvisited.length
        }
      });
    } else {
      this.setState({
        datasource: { datasource: [], count: 0 }
      });
    }
  }

  render() {
    return (
      <Fragment>
        {this.state.datasource.count > 0 && (
          <Fragment>
            {this.state.datasource.datasource.map(property => (
              <PropertiesListItem
                customList={this.state.datasource.datasource}
                customListPath="/favoritos"
                key={property.PROPERTYID}
                property={property}
                gridType={'property-row'}
              />
            ))}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default PropertiesLastVisited;
