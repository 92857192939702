import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import CaptchaField from '../google/CaptchaField';
import { formResources, globalResources } from '../../resources/resources';
import { propertyFormFetchData } from '../../actions/propertyForm';
import { numberWithSpaces } from '../../assets/utils';
import {
  RenderInput,
  RenderTextarea,
  RenderCheckbox,
  RenderPhoneInput,
  required,
  email,
  minLength1,
  minLength10
} from '../../assets/forms';
import { CountryID } from '../../assets/config';

class PropertyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleContactForm: false
    };
  }

  toggleContactForm() {
    this.setState({ toggleContactForm: !this.state.toggleContactForm });
  }

  render() {
    const {
      handleSubmit,
      submitting,
      property,
      subsidiaryEmail,
      subsidiaryName,
      subsidiaryPhone,
      isLoading
    } = this.props;
    const propertyData = {
      property_purpose: property.MAINPURPOSE,
      property_title: property.NOTE ? property.NOTE : property.TYPE,
      property_price:
        numberWithSpaces(property.MAINPURPOSE_PRICE) + ' ' + property.MAINPURPOSE_COIN,
      property_bedrooms: property.Bedrooms
        ? property.Bedrooms > 1
          ? property.Bedrooms + ' quartos'
          : property.Bedrooms + ' quarto'
        : '',
      property_district: property.LOCATIONSECONDLEVEL,
      property_county: property.LOCATIONTHIRDLEVEL,
      property_parish: property.LOCATIONFOURTHLEVEL,
      property_reference: property.Reference,
      property_image: property.mainphoto,
      property_url: property.PROPERTYID,
      property_id: property.PROPERTYID
    };

    window.recaptchaOptions = {
      useRecaptchaNet: true
    };

    return (
      <Fragment>
        <div
          id="property-form"
          className={
            this.state.toggleContactForm
              ? 'contact-request-wrapper active'
              : 'contact-request-wrapper'
          }
        >
          <span>{globalResources.interest}</span>
          <span>{globalResources.contactAgent}</span>
          <div className="property-request-contact">
            <form
              onSubmit={handleSubmit((values, dispatch) => {
                propertyFormFetchData(
                  values,
                  dispatch,
                  propertyData,
                  subsidiaryEmail,
                  subsidiaryName,
                  subsidiaryPhone
                );
              })}
            >
              <div className="form-body">
                <Field
                  name="user_name"
                  label={formResources.name}
                  validate={required}
                  component={RenderInput}
                />
                <Field
                  name="user_email"
                  label={formResources.email}
                  validate={[required, email]}
                  component={RenderInput}
                />
                <Field
                  name="user_phone"
                  label={formResources.phone}
                  validate={[required, minLength10]}
                  component={RenderPhoneInput}
                />
                <Field
                  name="user_message"
                  label={formResources.message}
                  validate={[required, minLength1]}
                  component={RenderTextarea}
                />
                {parseInt(CountryID) === 6824 && (
                  <Field
                    name="auth"
                    label={formResources.auth}
                    validate={required}
                    component={RenderCheckbox}
                  />
                )}
              </div>
              <div className="form-footer">
                <Field name="captcharesponse" component={CaptchaField} />
                <button
                  type="submit"
                  disabled={submitting || isLoading}
                  className="btn-default btn-tertiary"
                >
                  {formResources.send}
                </button>
              </div>
            </form>
          </div>
        </div>
        <button className="btn-toggleContactForm" onClick={this.toggleContactForm.bind(this)}>
          {this.state.toggleContactForm ? formResources.close : globalResources.contactUs}
        </button>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasErrored: state.propertyFormHasErrored,
    isLoading: state.propertyFormIsLoading,
    feedback: state.propertyForm.feedback
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFormData: formData => dispatch(propertyFormFetchData(formData))
  };
};

export default reduxForm({
  form: 'property',
  destroyOnUnmount: true,
  initialValues: { user_message: formResources.defaultMessage }
})(connect(mapStateToProps, mapDispatchToProps)(PropertyForm));
