import React from 'react';
import { Link } from 'react-router-dom';
import { globalResources } from '../../resources/resources';

const Breadcrumbs = ({ page, to, link }) => {
  return (
    <section className="section-breadcrumbs">
      <div className="section-wrapper">
        <div className="section-content">
          <div className="section-content-wrapper">
            <Link to={`/`}>{globalResources.home}</Link>
            {to && link && <button onClick={to}>{link}</button>}
            <span>{page}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumbs;
