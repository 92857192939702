import React, { useState, Fragment } from 'react';
import Collapse, { Panel } from 'rc-collapse';
import { globalResources } from '../../resources/resources';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const PropertyFeatures = props => {
  const [activeKey, setActiveKey] = useState([]);

  const renderFeatureChild = child => {
    return (
      <li key={child.ID}>
        {child.Description} {child.DataType !== 'B' && child.Value && `- ${child.Value}`}
      </li>
    );
  };

  const renderFeature = feature => {
    feature.children.map(
      childParent =>
        (childParent.children = props.features.filter(
          feature => feature.ParentID === childParent.ID
        ))
    );

    return (
      <Panel header={feature.Description} key={feature.ID}>
        <ul>
          {feature.children &&
            feature.children.map(child => {
              return (
                <li key={child.ID}>
                  {child.Description} {child.DataType !== 'B' && child.Value && `- ${child.Value}`}
                  <ul>{child.children.map(child => renderFeatureChild(child))}</ul>
                </li>
              );
            })}
        </ul>
      </Panel>
    );
  };

  const renderFeatures = features => {
    const title = features.find(feature => feature.ParentID === 0);
    const parents = features.filter(feature => feature.ParentID === (title ? title.ID : 137));

    parents.map(parent => {
      return (parent.children = features.filter(feature => feature.ParentID === parent.ID));
    });

    return (
      <Collapse
        accordion={false}
        onChange={activeKey => onChangePanel(activeKey)}
        activeKey={activeKey}
        expandIcon={expandIcon}
      >
        {parents.map(parent => renderFeature(parent))}
      </Collapse>
    );
  };

  const expandIcon = ({ isActive }) => {
    return <FontAwesomeIcon icon={faChevronDown} className={isActive ? 'up' : 'down'} />;
  };

  const onChangePanel = activeKey => {
    setActiveKey(activeKey);
  };

  return (
    <Fragment>
      {props.features.length > 0 && (
        <div className="property-features translate">
          <h4>{globalResources.features}</h4>
          {renderFeatures(props.features)}
        </div>
      )}
    </Fragment>
  );
};

export default PropertyFeatures;
