import React, { Component, Fragment } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import LazyLoad from 'react-lazyload';

class PropertyGallery extends Component {
  renderGallery(image) {
    return (
      <LazyLoad>
        <img src={image.Image} alt={image.DESCRIPTION} title={image.DESCRIPTION} />
      </LazyLoad>
    );
  }

  renderContent(gallery) {
    switch (true) {
      case gallery.length > 1:
        return (
          <div className="property-gallery">
            <AliceCarousel
              duration={300}
              autoPlayInterval={3000}
              showSlideInfo={false}
              mouseDragEnabled={false}
              infinite={true}
              autoPlay={true}
              buttonsDisabled={true}
              items={gallery.map(this.renderGallery)}
              ref={el => (this.Carousel = el)}
            />
            <button className="gallery-left-control" onClick={() => this.Carousel.slidePrev()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button className="gallery-right-control" onClick={() => this.Carousel.slideNext()}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        );
      case gallery.length > 0:
        return (
          <div className="property-gallery">
            <div className="alice-carousel">
              <div className="alice-carousel__wrapper">
                <img
                  src={gallery[0].Image}
                  alt={gallery[0].DESCRIPTION}
                  title={gallery[0].DESCRIPTION}
                />
              </div>
            </div>
          </div>
        );
      default:
        return <Fragment></Fragment>;
    }
  }

  render() {
    const { gallery } = this.props;

    return <Fragment>{this.renderContent(gallery)}</Fragment>;
  }
}

export default PropertyGallery;
