import { SEARCH_FETCH_DATA_SUCCESS } from './types';

function searchFetchDataSuccess(search) {
  return {
    type: SEARCH_FETCH_DATA_SUCCESS,
    search
  };
}

export function searchFetchData(searchParams) {
  return dispatch => {
    dispatch(searchFetchDataSuccess(searchParams));
  };
}
