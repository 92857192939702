import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const NoMatch = ({ message }) => (
  <section className="no-match">
    <div className="section-wrapper">
      <div className="row">
        <div className="col">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <span>{message}</span>
        </div>
      </div>
    </div>
  </section>
);

export default NoMatch;
