import {
  Handler,
  DefaultParameters,
  ContactDefaultParameters,
  Modules,
  Actions,
  CountryID,
  registerTO,
  registerCC
} from '../assets/config';
import { apiRequest } from './api';
import { reset, SubmissionError } from 'redux-form';
import { notifySuccess, notifyError } from '../components/elements/Notify';
import {
  REGISTER_FORM_HAS_ERRORED,
  REGISTER_FORM_IS_LOADING,
  REGISTER_FORM_FETCH_DATA_SUCCESS
} from './types';
import { JsonUrlEncode, setLocation, setPostCode } from '../assets/utils';
import { formResources } from '../resources/resources';

function registerFormHasErrored(bool) {
  return {
    type: REGISTER_FORM_HAS_ERRORED,
    hasErrored: bool
  };
}

function registerFormIsLoading(bool) {
  return {
    type: REGISTER_FORM_IS_LOADING,
    isLoading: bool
  };
}

function registerFormFetchDataSuccess(formFeedback) {
  return {
    type: REGISTER_FORM_FETCH_DATA_SUCCESS,
    formFeedback
  };
}

export function registerFormFetchData(values, dispatch) {
  if (values.user_password !== values.user_confirm_password) {
    throw new SubmissionError({
      user_password: formResources.feedback.passwordDoNotMatch,
      user_confirm_password: formResources.feedback.passwordDoNotMatch
    });
  }

  const registertype = values.register_type;

  switch (values.register_type) {
    case 'agency':
      values.register_type = formResources.agency;
      break;
    case 'agent':
      values.register_type = formResources.agent;
      break;
    case 'user':
      values.register_type = formResources.user;
      break;
    default:
      break;
  }

  let replacements = Object.assign({}, values, {
    register_type_value: registertype,
    district_id: values.district.value,
    district: values.district.label,
    county_id: values.county.value,
    county: values.county ? values.county.label : '',
    postcode: setPostCode(values.postcode)
  });

  const locationLevels = setLocation(replacements.district, replacements.county);

  replacements = {
    ...replacements,
    locationLevels: locationLevels,
    registertype: registertype === 'agency' ? 'block' : 'none'
  };

  const moduleAction = {
    module: Modules.contacts,
    action: Actions.sendEmail
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    ...ContactDefaultParameters,
    template: 'register',
    to: `['${registerTO}']`,
    cc: `['${registerCC}']`,
    replacements: JsonUrlEncode(replacements),
    countryID: CountryID
  };

  dispatch(registerFormIsLoading(true));
  return apiRequest(Handler, data)
    .then(response => {
      if (response.data.message) {
        throw Error(response.statusText);
      }
      dispatch(registerFormIsLoading(false));
      return response;
    })
    .then(response => response.data)
    .then(formFeedback => {
      switch (formFeedback) {
        case true:
          notifySuccess(formResources.feedback.success);
          dispatch(reset('register'));
          break;
        default:
          notifyError(formResources.feedback.error);
          break;
      }
      dispatch(registerFormFetchDataSuccess(formFeedback));
    })
    .catch(e => {
      notifyError(formResources.feedback.error);
      dispatch(registerFormHasErrored(true));
      dispatch(registerFormIsLoading(false));
    });
}
