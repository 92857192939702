import LocalizedStrings from 'react-localization';
import * as pt from './pt';

import { SET_LANGUAGE } from '../actions/types';

export let _localResources = [];
let _current = '';

// Global Resources
export const globalResources = new LocalizedStrings({
  pt: pt.Global
});

export const formResources = new LocalizedStrings({
  pt: pt.GlobalForm
});

// Local Resources [START]
export function PropertiesListPageResources() {
  return localResources({
    pt: pt.PropertiesListPage
  });
}
export function PropertiesLastVisitedResources() {
  return localResources({
    pt: pt.PropertiesLastVisited
  });
}

// Local Resources [END]
export function localResources(resources) {
  let resource = {
    key: require('uuid/v4')(),
    local: new LocalizedStrings(resources),
    unmount: function() {
      for (var i = 0; i < _localResources.length; i++) {
        if (_localResources[i].key === this.key) {
          _localResources.splice(i, 1);
          break;
        }
      }
    }
  };
  resource.local.setLanguage(_current);
  _localResources.push(resource);
  return resource;
}

export function setLanguage(lang) {
  globalResources.setLanguage(lang);
  for (var i = 0; i < _localResources.length; i++) {
    _localResources[i].local.setLanguage(lang);
  }
  _current = lang;
  return {
    type: SET_LANGUAGE,
    payload: lang
  };
}

export function startLanguage(lang) {
  globalResources.setLanguage(lang);
  for (var i = 0; i < _localResources.length; i++) {
    _localResources[i].local.setLanguage(lang);
  }
  _current = lang;
}
