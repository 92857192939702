import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { formResources } from '../../resources/resources';
import CaptchaField from '../google/CaptchaField';
import { contactFormFetchData } from '../../actions/contactForm';
import {
  RenderInput,
  RenderTextarea,
  RenderCheckbox,
  RenderPhoneInput,
  required,
  email,
  minLength10
} from '../../assets/forms';

class ContactForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.recaptchaOptions = {
      useRecaptchaNet: true
    };
  }

  render() {
    const { isLoading, handleSubmit, submitting } = this.props;

    return (
      <div className="contact-request-contact">
        <form
          onSubmit={handleSubmit((values, dispatch) => {
            contactFormFetchData(values, dispatch);
          })}
        >
          <div className="form-body">
            <div className="row">
              <div className="col pad-right-m">
                <Field
                  name="user_name"
                  label={formResources.name}
                  validate={required}
                  component={RenderInput}
                />
                <div className="row">
                  <div className="col pad-right-m">
                    <Field
                      name="user_email"
                      label={formResources.email}
                      validate={[required, email]}
                      component={RenderInput}
                    />
                  </div>
                  <div className="col pad-left-m">
                    <Field
                      name="user_phone"
                      label={formResources.phone}
                      validate={[required, minLength10]}
                      component={RenderPhoneInput}
                    />
                  </div>
                </div>
              </div>
              <div className="col pad-left-m">
                <Field
                  name="user_message"
                  label={formResources.message}
                  rows={6}
                  component={RenderTextarea}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  name="auth"
                  label={formResources.auth}
                  validate={required}
                  component={RenderCheckbox}
                />
              </div>
            </div>
          </div>
          <div className="form-footer">
            <Field name="captcharesponse" component={CaptchaField} />
            <button
              type="submit"
              disabled={submitting || isLoading}
              className="btn-default btn-primary"
            >
              {formResources.send}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasErrored: state.contactFormHasErrored,
    isLoading: state.contactFormIsLoading,
    feedback: state.contactForm.feedback
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFormData: formData => dispatch(contactFormFetchData(formData))
  };
};

export default reduxForm({ form: 'contact', destroyOnUnmount: true })(
  connect(mapStateToProps, mapDispatchToProps)(ContactForm)
);
