import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import Pagination from 'react-js-pagination';
import Master from '../components/elements/Master';
import PropertiesListItem from '../components/properties/PropertiesListItem';
import PropertiesFilter from '../components/properties/PropertiesFilter';
import NoResults from '../components/elements/NoResults';
import Loader from '../components/elements/Loader';
import Breadcrumbs from '../components/elements/Breadcrumbs';
import FormSelect from '../components/forms/FormSelect';
import Advertisement from '../components/elements/Advertisement';
import PropertiesLastVisited from '../components/properties/PropertiesLastVisited';
import {
  PropertiesListPageResources,
  formResources,
  globalResources
} from '../resources/resources';
import { recordsPerPage, advertisements } from '../assets/constants';
import { propertiesClearData } from '../actions/properties';
import { orderBy } from '../assets/constants';
import { isRunningOnServerSide } from '../assets/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { faFrown } from '@fortawesome/free-regular-svg-icons';

class PropertiesListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridType: 'property-row',
      radioSelected: 'property-row',
      activePage: 1,
      isMobile: isRunningOnServerSide() ? false : window.innerWidth < 991,
      resources: PropertiesListPageResources()
    };
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    const queryStringUrlParams = queryString.parse(this.props.location.search);
    queryStringUrlParams.activePage &&
      this.setState({
        activePage: parseInt(queryStringUrlParams.activePage)
      });

    if (!isRunningOnServerSide()) {
      window.addEventListener('resize', this.onResize, false);
    }
  }

  componentWillUnmount() {
    this.props.propertiesClearData();
    if (!isRunningOnServerSide()) {
      window.removeEventListener('resize', this.onResize, false);
    }
  }

  onResize() {
    if (!isRunningOnServerSide()) {
      this.setState({
        isMobile: window.innerWidth < 991
      });
    }
  }

  renderGridType() {
    return (
      <div className="grid-type">
        <input
          type="radio"
          id="property-row"
          className="hidden"
          value="property-row"
          name="gridType"
          checked={this.state.radioSelected === 'property-row'}
          onChange={this.setGridType.bind(this)}
        />
        <label htmlFor="property-row">
          <FontAwesomeIcon icon={faGripLines} />
        </label>
        <input
          type="radio"
          id="property-column"
          className="hidden"
          value="property-column"
          name="gridType"
          checked={this.state.radioSelected === 'property-column'}
          onChange={this.setGridType.bind(this)}
        />
        <label htmlFor="property-column">
          <FontAwesomeIcon icon={faThLarge} />
        </label>
      </div>
    );
  }

  renderFilters() {
    return <PropertiesFilter activePage={this.state.activePage} secondaryFields={true} />;
  }

  renderList() {
    return (
      <Fragment>
        {this.props.properties.map(property => (
          <PropertiesListItem
            key={property.PROPERTYID}
            property={property}
            gridType={this.state.gridType}
          />
        ))}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={recordsPerPage}
          totalItemsCount={this.props.propertiesCount}
          pageRangeDisplayed={3}
          onChange={pageNumber => this.handlePageChange(pageNumber)}
        />
      </Fragment>
    );
  }

  renderContent() {
    let grid_type_class = this.state.isMobile ? 'property-column' : this.state.gridType;

    switch (true) {
      case this.props.propertiesIsLoading:
        return <Loader />;
      case this.props.propertiesCount === 0:
        return (
          <div className="properties-list-wrapper no-results">
            <NoResults
              icon={faFrown}
              title={this.state.resources.local.noResults}
              subtitle={this.state.resources.local.noResultsSub}
            />
            <section className="section-last-visited">
              <div className="section-wrapper">
                <h3>{globalResources.lastVisitedTitle}</h3>
                <div className={`properties-list-wrapper ${grid_type_class}`}>
                  <PropertiesLastVisited />
                </div>
              </div>
            </section>
          </div>
        );
      case this.props.propertiesCount > 0:
        return (
          <div className={`properties-list-wrapper ${grid_type_class}`}>{this.renderList()}</div>
        );
      default:
        return <Loader />;
    }
  }

  setGridType(e) {
    this.setState({
      gridType: e.target.value,
      radioSelected: e.target.value
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    let queryStringUrlParams = queryString.parse(this.props.location.search);
    queryStringUrlParams = {
      ...queryStringUrlParams,
      pageNumber: pageNumber
    };
    this.props.history.push({
      search: queryString.stringify(queryStringUrlParams)
    });
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Master>
        <Helmet key={Math.random()}>
          <title>Casa e Sol - Lista de Imóveis</title>
          <meta property="og:title" content="Casa e Sol - Lista de Imóveis" />
        </Helmet>
        <div id="properties-list">
          <Breadcrumbs page={globalResources.properties} />
          <section className="section-properties-list">
            <div className="section-wrapper">
              <header className="section-header">
                <h3>{globalResources.properties}</h3>
              </header>
              <div className="section-content">
                <div className="section-content-wrapper">
                  <div className="properties-filter">
                    {this.renderFilters()}
                    <Advertisement slot={advertisements.slot_1} />
                    <Advertisement slot={advertisements.slot_2} />
                  </div>
                  <div className="properties-list">
                    <div className="row">
                      <div className="col">
                        <div className="form-grid-type">
                          <label>Tipo de lista:</label>
                          {this.renderGridType()}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-order-by">
                          <label>Ordenar:</label>
                          <form>
                            <Field
                              name="orderBy"
                              placeholder={formResources.select}
                              isClearable={false}
                              isDisabled={false}
                              component={FormSelect}
                              options={orderBy}
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                    {this.renderContent()}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Master>
    );
  }
}

const mapStateToProps = state => {
  return {
    propertiesHasErrored: state.propertiesHasErrored,
    propertiesIsLoading: state.propertiesIsLoading,
    properties: state.properties.list,
    propertiesCount: state.properties.counters.PropertyCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    propertiesClearData: () => dispatch(propertiesClearData())
  };
};

(PropertiesListPage = connect(mapStateToProps, { propertiesClearData })(
  withRouter(PropertiesListPage)
)),
  mapDispatchToProps;

export default reduxForm({
  form: 'orderBy',
  destroyOnUnmount: false,
  enableReinitialize: true
})(PropertiesListPage);
