import React, { Fragment } from 'react';
import removeAccents from 'remove-accents';
import { globalResources } from '../resources/resources';

export const isRunningOnServerSide = () => {
  return typeof window === 'undefined';
};

export const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] }
    }),
    {}
  );

export function getPageData(data = [], page = 1, rpp = 0) {
  let pagedata = data;

  if (pagedata && rpp > 0 && pagedata.length > rpp) {
    pagedata = pagedata.slice((page - 1) * rpp, (page - 1) * rpp + rpp);
  } else {
    if (!pagedata) pagedata = [];
  }

  return pagedata;
}

export const removeEmptyStrings = obj => {
  let newObj = {};
  Object.keys(obj).forEach(prop => {
    if (obj[prop] !== '' && obj[prop] !== undefined) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const getParameterValue = (filters, parameterValue) => {
  return filters.find(filter => {
    return normalizeParameter(filter.label) === normalizeParameter(parameterValue);
  });
};

export const normalizeParameter = value => {
  return removeAccents(
    value
      .replace(/-/g, ' ')
      .replace(/,/g, '')
      .toLowerCase()
  );
};

export const normalizeUrlParameter = value => {
  return removeAccents(
    value
      .replace(/ /g, '-')
      .replace(/,/g, '')
      .toLowerCase()
  );
};

export const getFilterObject = filter => {
  return filter
    ? {
        value: filter,
        label: numberWithSpaces(filter)
      }
    : '';
};

export const numberWithSpaces = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const setLocation = (district, county = '', parish = '') => {
  let location = '';

  if (district) {
    location += district;
    if (county) {
      location += ', ' + county;
      if (parish) {
        location += ', ' + parish;
      }
    }
  }

  return location;
};

export const setPrice = (value, coin) => {
  return value ? (
    <Fragment>
      {numberWithSpaces(value)} <span>{coin}</span>
    </Fragment>
  ) : (
    globalResources.priceRequest
  );
};

export const setThumbnail = image => {
  const imageName = image ? image.split(/[/ ]+/).pop() : '';
  const imageUrl = image ? image.slice(0, -imageName.length) : '';

  return imageUrl + 'i' + imageName;
};

export const setTitle = (note, type) => {
  return note ? note : type;
};

export const setDescription = (description, characterLimit) => {
  return description
    ? characterLimit === 0
      ? description
      : description.substring(0, characterLimit) + '...'
    : '';
};

export const setPostCode = postCode => {
  return postCode.slice(0, 4) + '-' + postCode.slice(4, 7);
};

export const RemoveFromArray = (array, field, value) => {
  var result = [];
  for (var j = 0; j < array.length; j++) {
    if (array[j][field] !== value) result.push(array[j]);
  }
  return result;
};

export const RemoveIdenticalFromArray = (array, field) => {
  var result = [];
  for (var j = 0; j < array.length; j++) {
    if (!ContainsFieldValue(result, field, array[j][field])) result.push(array[j]);
  }
  return result;
};
export const ContainsFieldValue = (array, field, value) => {
  for (var j = 0; j < array.length; j++) {
    if (array[j][field] === value) return true;
  }
  return false;
};

// Encodes the <string> for a valid url request
export const urlEncode = function(text) {
  var str = escape(text);
  return str.replace(/[*+\/@]|%20/g, function(s) {
    switch (s) {
      case '*':
        s = '%2A';
        break;
      case '+':
        s = '%2B';
        break;
      case '/':
        s = '%2F';
        break;
      case '@':
        s = '%40';
        break;
      case '%20':
        s = '+';
        break;
      default:
        break;
    }
    return s;
  });
};

// Encodes the <string> for a valid url request
export const JsonUrlEncode = function(text) {
  return urlEncode(JSON.stringify(text));
};

export const isEmpty = obj => {
  if (obj == null) return true;
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;
  if (typeof obj !== 'object') return true;
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
};
