import React, { Fragment, useState } from 'react';
import { requestPropertyListDetail } from '../../actions/properties';
import { recordsPerPageFavorites } from '../../assets/constants';
import { RemoveFromArray, RemoveIdenticalFromArray } from '../../assets/utils';
import { CryptoKey } from '../../assets/config';
import { globalResources } from '../../resources/resources';
import { notifySuccess, notifyWarning } from '../elements/Notify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { AES, enc } from 'crypto-js';

const PropertyFavorite = props => {
  const [listProperty, setListProperty] = useState({});
  const updateListProperty = data => setListProperty(data);

  const [isFavoriteState, setIsFavorite] = useState(
    (() => {
      let result = false;
      const id = props.detail.PROPERTYID;
      var favorites = localStorage.getItem('favorites');
      if (!favorites || favorites === '') {
        favorites = [];
        localStorage.setItem('favorites', CryptoKey ? AES.encrypt('[]', CryptoKey) : '[]');
      } else {
        favorites = JSON.parse(
          CryptoKey ? AES.decrypt(favorites, CryptoKey).toString(enc.Utf8) : favorites
        );
      }
      favorites.forEach(property => {
        if (property && property !== null && property.PROPERTYID === parseInt(id)) result = true;
      });
      return result;
    })()
  );

  function addFavorite() {
    let property = null;
    const id = props.detail.PROPERTYID;

    const saveFavorite = property => {
      if (property && property !== null) {
        var favorites = localStorage.getItem('favorites');
        if (!favorites || favorites === '') {
          favorites = [];
          localStorage.setItem('favorites', CryptoKey ? AES.encrypt('[]', CryptoKey) : '[]');
        } else {
          favorites = JSON.parse(
            CryptoKey ? AES.decrypt(favorites, CryptoKey).toString(enc.Utf8) : favorites
          );
          favorites = RemoveIdenticalFromArray(favorites, 'PROPERTYID');
          favorites = RemoveFromArray(favorites, 'PROPERTYID', parseInt(id));
        }
        if (favorites.length < recordsPerPageFavorites) {
          favorites.unshift(property);
          for (let i = 0; i < favorites.length; i++) {
            favorites[i].ROWNUMBER = i + 1;
          }
          localStorage.setItem(
            'favorites',
            CryptoKey
              ? AES.encrypt(JSON.stringify(favorites), CryptoKey)
              : JSON.stringify(favorites)
          );
          setIsFavorite(true);
          if (!listProperty[id] || listProperty[id] === null) {
            let lp = listProperty;
            lp[id] = property;
            updateListProperty({ listProperty: lp });
          }
          notifySuccess(globalResources.addFavorite);
        } else {
          notifyWarning(globalResources.favoritesFull);
        }
      }
    };

    if (listProperty[id] && listProperty[id] !== null) {
      property = Object.assign({}, listProperty[id]);
    } else {
      if (!property && props.lastList && props.lastList != null && props.lastList.length > 0) {
        property = props.lastList.find(p => p.PROPERTYID === parseInt(id));
        if (property && property !== null && property.PROPERTYID) {
          property = Object.assign({}, property);
        }
        saveFavorite(property);
      }
      if (!property && props.detail) {
        requestPropertyListDetail(props.detail.Reference, id, property => saveFavorite(property));
      }
    }
  }

  function removeFavorite() {
    const id = props.detail.PROPERTYID;
    var favorites = localStorage.getItem('favorites');
    if (!favorites || favorites === '') {
      favorites = [];
      localStorage.setItem('favorites', CryptoKey ? AES.encrypt('[]', CryptoKey) : '[]');
    } else {
      favorites = JSON.parse(
        CryptoKey ? AES.decrypt(favorites, CryptoKey).toString(enc.Utf8) : favorites
      );
      favorites = RemoveFromArray(favorites, 'PROPERTYID', parseInt(id));
      localStorage.setItem(
        'favorites',
        CryptoKey ? AES.encrypt(JSON.stringify(favorites), CryptoKey) : JSON.stringify(favorites)
      );
      setIsFavorite(false);
      notifyWarning(globalResources.removeFavorite);
    }
  }

  return (
    <Fragment>
      <li>
        {!isFavoriteState && <FontAwesomeIcon onClick={() => addFavorite()} icon={regularStar} />}
        {isFavoriteState && <FontAwesomeIcon onClick={() => removeFavorite()} icon={solidStar} />}
      </li>
    </Fragment>
  );
};

export default PropertyFavorite;
