import React, { Fragment } from 'react';
import AliceCarousel from 'react-alice-carousel';
import LazyLoad from 'react-lazyload';

const Advertisement = props => {
  const renderAdd = slot => {
    return (
      <Fragment>
        {slot.images.length === 1 && renderCarousel(slot.images[0])}
        {slot.images.length > 1 && (
          <AliceCarousel
            duration={slot.duration ? slot.duration : 500}
            autoPlayInterval={slot.autoPlayInterval ? slot.autoPlayInterval : 5000}
            showSlideInfo={false}
            mouseDragEnabled={false}
            infinite={true}
            autoPlay={true}
            buttonsDisabled={true}
            dotsDisabled={true}
            keysControlDisabled={true}
            items={slot.images.map(renderCarousel)}
          />
        )}
      </Fragment>
    );
  };

  const renderCarousel = image => {
    return (
      <LazyLoad>
        <img src={image} alt="Add" />
      </LazyLoad>
    );
  };

  let slot = props.slot
    ? Object.assign({}, props.slot)
    : {
        duration: props.duration,
        images: props.images,
        to: props.to,
        subclass: props.subclass,
        autoPlayInterval: props.autoPlayInterval
      };

  return (
    <Fragment>
      {slot.images && slot.images.length > 0 && (
        <section
          className={
            slot.subclass !== undefined ? 'advertisement ' + slot.subclass : 'advertisement'
          }
        >
          {slot.to && slot.to.length > 0 && (
            <a href={slot.to} target="_blank" rel="noopener noreferrer">
              {renderAdd(slot)}
            </a>
          )}
          {(!slot.to || slot.to.length === 0) && <div>{renderAdd(slot)}</div>}
        </section>
      )}
    </Fragment>
  );
};

export default Advertisement;
