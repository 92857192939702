import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdSense from 'react-adsense';
import Master from '../components/elements/Master';
import Banner from '../components/elements/Banner';
import PropertiesListItem from '../components/properties/PropertiesListItem';
import NoMatch from '../components/elements/NoMatch';
import { highlightsFetchData } from '../actions/highlights';
import { globalResources } from '../resources/resources';
import { CountryID } from '../assets/config';
import { Portals } from '../assets/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltRight,
  faGlobeAfrica,
  faGlobeAmericas,
  faGlobeEurope,
} from '@fortawesome/free-solid-svg-icons';

const HomePage = (props) => {
  const renderPropertiesHighlights = () => {
    return props.highlights.map((highlight) => (
      <PropertiesListItem
        customList={props.highlights}
        customListPath="/"
        key={highlight.PROPERTYID}
        property={highlight}
        gallery={true}
      />
    ));
  };

  const head = () => {
    return (
      <Helmet key={Math.random()}>
        <title>Casa e Sol</title>
        <meta property="og:title" content="Casa e Sol" />
        <meta
          name="description"
          content="No portal Imobiliário Casaesol encontra casas para arrendar, moradias para alugar, moradias para venda, casas para venda, apartamentos para venda, apartamentos para arrendar."
        />
      </Helmet>
    );
  };

  const getErrorPage = () => {
    const { erro } = props.match.params;
    if (erro) {
      switch (true) {
        case erro === 'imovel-nao-encontrado':
          return (
            <NoMatch message={'O imóvel que procura não se encontra disponivel de momento.'} />
          );
        default:
          return (
            <NoMatch message={'A página que procura não se encontra disponivel de momento.'} />
          );
      }
    }
  };

  if (props.location.hash.indexOf('#/') >= 0) {
    const newUrl = props.location.hash.replace('#/', '');
    return <Redirect to={newUrl} />;
  }

  const { highlightsFetchData: loadHighlights } = props;

  useEffect(() => {
    loadHighlights();
  }, [loadHighlights]);

  return (
    <Master>
      {head()}
      <Banner />
      {getErrorPage()}
      <section>
        <div className="section-wrapper">
          <header className="section-header">
            <h1>{globalResources.findProperty}</h1>
            <h2>{globalResources.highlights}</h2>
          </header>
          <div className="section-content">
            <div className="section-content-wrapper property-highlights">
              {props.highlights && renderPropertiesHighlights()}
            </div>
          </div>
        </div>
      </section>
      <section className="advertise">
        <div className="section-wrapper">
          <header className="section-header">
            <h1>{globalResources.sellRent}</h1>
            <h2>{globalResources.discloseProperty}</h2>
          </header>
          <div className="section-content">
            <div className="section-content-wrapper">
              <Link to={`/anunciar`}>
                <button className="btn-default btn-secondary btn-arrow">
                  <span className="description">{globalResources.discloseHere}</span>
                  <span className="icon">
                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="find-us">
        <div className="section-wrapper">
          <header className="section-header">
            <h1>Procure imóveis noutros países</h1>
          </header>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="row">
                {CountryID !== Portals.portugal && (
                  <div className="col">
                    <a
                      href="https://www.casaesol.com/"
                      className="card"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faGlobeEurope} />
                      <span>Imóveis em Portugal</span>
                    </a>
                  </div>
                )}
                {/*  {CountryID !== Portals.brasil && (
                  <div className="col">
                    <a
                      href="https://imoveisesol.com.br/"
                      className="card"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faGlobeAmericas} />
                      <span>Imóveis no Brasil</span>
                    </a>
                  </div>
                )} */}
                {CountryID !== Portals.caboVerde && (
                  <div className="col">
                    <a
                      href="https://www.casaesol.cv/"
                      className="card"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faGlobeAfrica} />
                      <span>Imóveis em Cabo Verde</span>
                    </a>
                  </div>
                )}
                {CountryID !== Portals.mocambique && (
                  <div className="col">
                    <a
                      href="https://www.casaesol.co.mz/"
                      className="card"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faGlobeAfrica} />
                      <span>Imóveis em Moçambique</span>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {CountryID === Portals.portugal && (
        <AdSense.Google
          client="ca-pub-5686249372942238"
          slot="6418874912"
          style={{ display: 'block' }}
          format="auto"
          responsive="true"
          layoutKey="-gw-1+2a-9x+5c"
        />
      )}
    </Master>
  );
};

const mapStateToProps = (state) => {
  return {
    highlights: state.highlights.list,
  };
};

const loadData = (store) => {
  return store.dispatch(highlightsFetchData());
};

export default {
  component: connect(mapStateToProps, { highlightsFetchData })(withRouter(HomePage)),
  loadData,
};
