import React, { Fragment, useState } from 'react';
import { globalResources } from '../../resources/resources';
import { numberWithSpaces } from '../../assets/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

const PropertyAgency = ({
  commercialdesignation,
  maincellphone,
  mainphone,
  mainphoto,
  address,
  address_zipcode,
  address_ziplocation,
  ami_license,
  apemip_license
}) => {
  const [contactOpen, setContactToggle] = useState(false);
  const toggleTopMenu = () => setContactToggle(!contactOpen);

  return (
    <div className="contact-request-wrapper">
      <span>{globalResources.agent}</span>
      <div className="contact-agency">
        {mainphoto && (
          <div className="agency-image">
            <img src={mainphoto} alt={commercialdesignation} />
          </div>
        )}
        <div className="agency-description">
          {commercialdesignation && <div className="agency-name">{commercialdesignation}</div>}
          <div className="agency-license">
            {ami_license && <span>AMI: {ami_license}</span>}
            {ami_license && apemip_license && <span>/</span>}
            {apemip_license && <span>AMI: {apemip_license}</span>}
          </div>
          {address && address_zipcode && address_ziplocation && (
            <div className="agency-location">
              <span>{address}</span>
              <span>
                {address_zipcode}, {address_ziplocation}
              </span>
            </div>
          )}
        </div>
        <div className="agency-contats">
          {!contactOpen && (
            <button
              className="btn-default btn-tertiary btn-toggleAgencyContacts"
              onClick={toggleTopMenu}
            >
              {globalResources.seePhoneContact}
            </button>
          )}
          {contactOpen && (
            <Fragment>
              {mainphone && (
                <div className="agency-contact">
                  <FontAwesomeIcon icon={faPhone} />
                  {numberWithSpaces(mainphone)}
                </div>
              )}
              {maincellphone && (
                <div className="agency-contact">
                  <FontAwesomeIcon icon={faMobileAlt} />
                  {numberWithSpaces(maincellphone)}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyAgency;
