import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import removeAccents from 'remove-accents';
import NoSSR from 'react-no-ssr';
import GoogleTranslator from '../google/GoogleTranslator';
import { globalResources } from '../../resources/resources';
import { Country } from '../../assets/config';
import { normalizeUrlParameter } from '../../assets/utils';
import { Portals } from '../../assets/constants';

const Header = () => {
  const [menu_class, setMenuClass] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  const onResize = () => {
    setIsMobile(window.innerWidth < 991);
  };

  const setToggleTopMenuClass = () => {
    if (menu_class === '') {
      setMenuClass('toggled');
    } else {
      setMenuClass('');
    }
  };

  const closeDropDownMenu = () => {
    if (menu_class === 'toggled') {
      setMenuClass('');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize, false);
    setIsMobile(window.innerWidth < 991);
    return function cleanup() {
      window.removeEventListener('resize', onResize, false);
    };
  }, []);

  const top_menu_class = isMobile ? menu_class : '';
  const countryName = removeAccents(normalizeUrlParameter(Country.label));

  return (
    <header className="header-main">
      <nav className={top_menu_class}>
        <div className="row">
          <div className="col nav-brand">
            <NavLink to="/">
              <img src="/images/logo.png" alt="" />
            </NavLink>
          </div>
          <div className="col navbar-left">
            <ul className="navbar">
              <li>
                <NavLink
                  to={
                    Country.value === Portals.portugal
                      ? `/imoveis/comprar/${countryName}`
                      : `/imoveis/compra/${countryName}`
                  }
                  activeClassName="is-active"
                  exact={true}
                  onClick={closeDropDownMenu}
                >
                  {globalResources.buy}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={
                    Country.value === Portals.portugal
                      ? `/imoveis/arrendar/${countryName}`
                      : `/imoveis/arrendamento/${countryName}`
                  }
                  activeClassName="is-active"
                  exact={true}
                  onClick={closeDropDownMenu}
                >
                  {globalResources.rent}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/favoritos"
                  activeClassName="is-active"
                  exact={true}
                  onClick={closeDropDownMenu}
                >
                  {globalResources.favorites}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col navbar-right">
            <ul className="navbar">
              <li>
                <NoSSR>
                  <GoogleTranslator></GoogleTranslator>
                </NoSSR>
              </li>
              <li className="withBorder">
                <NavLink
                  to="/registo"
                  activeClassName="is-active"
                  exact={true}
                  onClick={closeDropDownMenu}
                >
                  {globalResources.announce}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="navbar-toggle" onClick={setToggleTopMenuClass}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
