import React from 'react';
import { createTextMask } from 'redux-form-input-masks';
import PhoneInput from 'react-phone-input-2';
import { formResources } from '../resources/resources';
import { countryPhoneInput } from './constants';

//VALIDATION

export const required = value =>
  value || typeof value === 'number' ? undefined : formResources.required;

export const minLength = min => value =>
  value && value.length < min ? `Deve ter no mínimo ${min} caracteres` : undefined;

export const minLength1 = minLength(1);
export const minLength8 = minLength(8);
export const minLength10 = minLength(10);
export const minLength15 = minLength(15);

export const maxLength = max => value =>
  value && value.length > max ? `Deve ter no máximo ${max} caracteres` : undefined;

export const maxLength20 = maxLength(20);

export const number = value => (value && isNaN(Number(value)) ? 'Deve ser um número' : undefined);

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Email inválido' : undefined;

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Apenas caracteres alfanuméricos' : undefined;

export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Número de telefone inválido, deve ter 9 digitos'
    : undefined;

// MASKS

export const phoneMask = {
  portugal: createTextMask({
    pattern: '999-999-999'
  }),
  caboVerde: createTextMask({
    pattern: '999-99-99'
  }),
  mocambique: createTextMask({
    pattern: '99-999-9999'
  }),
  brasil: createTextMask({
    pattern: '99-99999-9999'
  })
};

export const postCodeMask = {
  brasil: createTextMask({
    pattern: '99999-999'
  }),
  mocambique: createTextMask({
    pattern: '9999'
  }),
  caboVerde: undefined,
  default: createTextMask({
    pattern: '9999-999'
  })
};

export const taxMask = {
  portugal: createTextMask({
    pattern: '999 999 999'
  }),
  mocambique: undefined,
  brasil: createTextMask({
    pattern: '999 999 999 99'
  })
};

// RENDERS

export const createRenderer = render => ({
  input,
  meta: { touched, error, warning, active },
  label,
  ...rest
}) => (
  <div className={[error && touched ? 'error' : '', active ? 'active' : ''].join(' form-group ')}>
    <label htmlFor={input.name}>{label}</label>
    {render(input, label, rest)}
    {touched &&
      ((error && (
        <div className="form-feedback">
          <span>{error}</span>
        </div>
      )) ||
        (warning && (
          <div className="form-feedback">
            <span>{warning}</span>
          </div>
        )))}
  </div>
);

export const createCheckboxRenderer = render => ({
  input,
  meta: { touched, error, warning, active },
  label,
  ...rest
}) => (
  <div
    className={[error && touched ? 'error' : '', active ? 'active' : ''].join(
      ' form-group form-group-checkbox '
    )}
  >
    <label htmlFor={input.name}>{label}</label>
    {render(input, label, rest)}
    {touched &&
      ((error && (
        <div className="form-feedback">
          <span>{error}</span>
        </div>
      )) ||
        (warning && (
          <div className="form-feedback">
            <span>{warning}</span>
          </div>
        )))}
  </div>
);

export const createRadioRenderer = render => ({
  input,
  meta: { touched, error, warning, active },
  label,
  ...rest
}) => (
  <div
    className={[error && touched ? 'error' : '', active ? 'active' : ''].join(
      'form-group form-group-radio '
    )}
  >
    <label htmlFor={input.name}>
      {render(input, label, rest)} {label} <span className="checkmark"></span>
    </label>
    {touched &&
      ((error && (
        <div className="form-feedback">
          <span>{error}</span>
        </div>
      )) ||
        (warning && (
          <div className="form-feedback">
            <span>{warning}</span>
          </div>
        )))}
  </div>
);

export const RenderInput = createRenderer((input, label) => (
  <input {...input} placeholder={label} className="form-control" />
));

export const RenderInputPassword = createRenderer((input, label) => (
  <input {...input} placeholder={label} className="form-control" type="password" />
));

export const RenderTextarea = createRenderer((input, label) => (
  <textarea {...input} placeholder={label} className="form-control" rows="6" />
));

export const RenderCheckbox = createCheckboxRenderer(input => (
  <input {...input} id={input.name} className="form-control" type="checkbox" />
));

export const RenderRadio = createRadioRenderer((input, label) => (
  <input {...input} placeholder={label} className="form-control" type="radio" />
));

export const RenderPhoneInput = createRenderer((input, label) => {
  return (
    <PhoneInput
      {...input}
      placeholder={label}
      dropdownClass="form-dropdown"
      inputClass="form-control"
      preferredCountries={['PT', 'CV', 'MZ', 'BR']}
      defaultCountry={countryPhoneInput}
      masks={{
        pt: '+... ... ... ...',
        cv: '+... ... .. ..',
        mz: '+... .. ... ....',
        br: '+.. ..... ....'
      }}
    />
  );
});
