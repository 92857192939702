import {
  PROPERTY_HAS_ERRORED,
  PROPERTY_IS_LOADING,
  PROPERTY_FETCH_DATA_SUCCESS,
  PROPERTY_FEATURES_HAS_ERRORED,
  PROPERTY_FEATURES_IS_LOADING,
  PROPERTY_FEATURES_FETCH_DATA_SUCCESS,
  PROPERTY_CLEAR_DATA
} from '../actions/types';

const propertyReducerDefaultState = {
  detail: [],
  descriptions: [],
  notes: [],
  address: [],
  images: [],
  families: [],
  labels: [],
  links: [],
  subsidiary: [],
  features: []
};

export function propertyHasErrored(state = false, action) {
  switch (action.type) {
    case PROPERTY_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function propertyIsLoading(state = false, action) {
  switch (action.type) {
    case PROPERTY_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function propertyFeaturesHasErrored(state = false, action) {
  switch (action.type) {
    case PROPERTY_FEATURES_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function propertyFeaturesIsLoading(state = false, action) {
  switch (action.type) {
    case PROPERTY_FEATURES_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function property(state = propertyReducerDefaultState, action) {
  switch (action.type) {
    case PROPERTY_FETCH_DATA_SUCCESS:
      const detail = action.property.Property;
      const descriptions = action.property.PropertyDescriptions;
      const notes = action.property.PropertyNotes;
      const address = action.property.PropertyAddress;
      const images = action.property.PropertyImages;
      const families = action.property.PropertyFamilies;
      const labels = action.property.PropertyLabels;
      const links = action.property.PropertyLinks;
      const subsidiary = action.property.Subsidiary;

      return {
        ...state,
        detail,
        descriptions,
        notes,
        address,
        images,
        families,
        labels,
        links,
        subsidiary
      };

    case PROPERTY_FEATURES_FETCH_DATA_SUCCESS:
      const features = action.propertyFeatures.FFFeatures;

      return {
        ...state,
        features
      };

    case PROPERTY_CLEAR_DATA:
      return {
        ...propertyReducerDefaultState
      };
    default:
      return state;
  }
}
