import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import CaptchaField from '../google/CaptchaField';
import { filtersFetchData } from '../../actions/filters';
import { registerFormFetchData } from '../../actions/registerForm';
import {
  RenderInput,
  RenderInputPassword,
  RenderCheckbox,
  RenderRadio,
  RenderPhoneInput,
  required,
  email,
  minLength8,
  minLength10,
  minLength,
  maxLength20
} from '../../assets/forms';
import FormSelect from './FormSelect';
import { districtLabel, countyLabel, postCodeMaskInput, taxNumber } from '../../assets/constants';
import { formResources } from '../../resources/resources';

class RegisterForm extends Component {
  async componentDidMount() {
    await this.props.fetchData({
      withAssociatedProperties: false
    });
    window.recaptchaOptions = {
      useRecaptchaNet: true
    };
  }

  render() {
    const { handleSubmit, submitting, districts, counties, register_type, isLoading } = this.props;

    const taxMinLength = minLength(taxNumber.MinLength);

    return (
      <div className="register-form">
        <form onSubmit={handleSubmit(registerFormFetchData)}>
          <div className="form-body">
            <div className="row">
              <div className="col">
                <h5>{formResources.dataForAccess}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col pad-right-m">
                <Field
                  name="user_email"
                  label={formResources.email}
                  validate={[required, email]}
                  component={RenderInput}
                />
              </div>
              <div className="col pad-left-m pad-right-m">
                <Field
                  name="user_password"
                  label={formResources.password}
                  validate={[required, minLength8, maxLength20]}
                  component={RenderInputPassword}
                />
              </div>
              <div className="col pad-left-m">
                <Field
                  name="user_confirm_password"
                  label={formResources.passwordConfirm}
                  validate={[required, minLength8, maxLength20]}
                  component={RenderInputPassword}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h5>{formResources.contactDetails}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col pad-right-m">
                <Field
                  name="user_name"
                  label={formResources.name}
                  validate={[required]}
                  component={RenderInput}
                />
              </div>
              <div className="col pad-left-m pad-right-m">
                <Field
                  name="user_phone"
                  label={formResources.phone}
                  validate={[required, minLength10]}
                  component={RenderPhoneInput}
                />
              </div>
              <div className="col pad-left-m">
                <Field
                  name="user_other_phone"
                  label={formResources.otherPhone}
                  validate={[required, minLength10]}
                  component={RenderPhoneInput}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>{formResources.registerType}</label>
                </div>
              </div>
            </div>
            <div className="row row-radio">
              <div className="col">
                <Field
                  name="register_type"
                  label={formResources.agency}
                  component={RenderRadio}
                  type="radio"
                  value="agency"
                />
              </div>
              <div className="col">
                <Field
                  name="register_type"
                  label={formResources.agent}
                  component={RenderRadio}
                  type="radio"
                  value="agent"
                />
              </div>
              <div className="col">
                <Field
                  name="register_type"
                  label={formResources.user}
                  component={RenderRadio}
                  type="radio"
                  value="user"
                  validate={[required]}
                />
              </div>
            </div>
            {register_type === 'agency' && (
              <div className="row">
                <div className="col pad-right-m">
                  <Field
                    name="company_name"
                    label={formResources.company}
                    validate={[required]}
                    component={RenderInput}
                  />
                </div>
                <div className="col pad-left-m pad-right-m">
                  <Field
                    name="company_website"
                    label={formResources.website}
                    component={RenderInput}
                  />
                </div>
                <div className="col pad-left-m"></div>
              </div>
            )}
            <div className="row">
              <div className="col pad-right-m">
                <Field
                  name="address"
                  label={formResources.address}
                  validate={[required]}
                  component={RenderInput}
                />
              </div>
              <div className="col pad-left-m pad-right-m">
                <Field
                  name="postcode"
                  label={formResources.postcode}
                  validate={[required]}
                  component={RenderInput}
                  {...postCodeMaskInput}
                />
              </div>
              <div className="col pad-left-m">
                <Field
                  name="location"
                  label={formResources.address_location}
                  validate={[required]}
                  component={RenderInput}
                />
              </div>
            </div>
            <div className="row">
              <div className="col pad-right-m">
                <Field
                  label={districtLabel}
                  name="district"
                  placeholder={formResources.select}
                  isClearable={true}
                  isDisabled={false}
                  component={FormSelect}
                  options={districts}
                  validate={required}
                  withAssociatedProperties={false}
                />
              </div>
              <div className="col pad-left-m pad-right-m">
                <Field
                  label={countyLabel}
                  name="county"
                  placeholder={formResources.select}
                  isClearable={true}
                  isDisabled={false}
                  component={FormSelect}
                  options={counties}
                  withAssociatedProperties={false}
                />
              </div>
              <div className="col pad-left-m">
                <Field
                  name="tax_number"
                  label={taxNumber.Label}
                  validate={[required, taxMinLength]}
                  component={RenderInput}
                  {...taxNumber.MaskInput}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  name="auth"
                  label={formResources.auth}
                  validate={required}
                  component={RenderCheckbox}
                />
              </div>
            </div>
          </div>
          <div className="form-footer">
            <Field name="captcharesponse" component={CaptchaField} />
            <button
              type="submit"
              disabled={submitting || isLoading}
              className="btn-default btn-primary"
            >
              {formResources.send}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasErrored: state.registerFormHasErrored,
    isLoading: state.registerFormIsLoading,
    districts: state.filters.districts,
    counties: state.filters.counties,
    formFeedback: state.registerForm.formFeedback,
    register_type: selector(state, 'register_type')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchData: params => dispatch(filtersFetchData(params)),
    dispatchChange: (formName, field, value) => dispatch(change(formName, field, value))
  };
};

const selector = formValueSelector('register');

export default reduxForm({
  form: 'register',
  destroyOnUnmount: true,
  enableReinitialize: true,
  initialValues: { register_type: 'user' }
})(connect(mapStateToProps, mapDispatchToProps)(RegisterForm));
