import React, { Component, Fragment } from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { globalResources } from '../../resources/resources';
import { Country } from '../../assets/config';
import { isRunningOnServerSide } from '../../assets/utils';

export let _isMounted = false;

class PropertyLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latlng: null
    };
  }

  async componentDidMount() {
    const lat = this.props.LATITUDE ? this.props.LATITUDE : this.props.LOCATION_LATITUDE;
    const lng = this.props.LONGITUDE ? this.props.LONGITUDE : this.props.LOCATION_LONGITUDE;
    let latLng = [];

    if (lat && lng && this.props.IsGeoreferencePublic) {
      latLng = [lat, lng];
    } else {
      latLng = await this.getLocationLatLng();
    }

    _isMounted = true;
    if (_isMounted) {
      this.setState({
        latlng: latLng
      });
    }
  }

  componentWillUnmount() {
    _isMounted = false;
  }

  async getLocationLatLng() {
    const provider = new OpenStreetMapProvider();
    const results = await provider.search({
      query: `${this.props.location}, ${Country.label}`
    });
    return results && results.length > 0 ? [results[0].raw.lat, results[0].raw.lon] : [];
  }

  renderLocation(latlng) {
    const RL = require('react-leaflet');
    const L = require('leaflet');
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: '/images/leaflet/marker-icon-2x.png',
      iconUrl: '/images/leaflet/marker-icon.png',
      shadowUrl: '/images/leaflet/marker-shadow.png'
    });
    latlng.length > 0 && L.marker(latlng);

    return latlng.length > 0 ? (
      <Fragment>
        <div className="property-map">
          <h4 className="translate">{globalResources.location}</h4>
          {this.props.location && <h2>{this.props.location}</h2>}
          <div className="leaflet-map">
            <RL.Map center={latlng} zoom={15} scrollWheelZoom={false}>
              <RL.TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <RL.Marker position={latlng}>
                {/* <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup> */}
              </RL.Marker>
            </RL.Map>
          </div>
        </div>
      </Fragment>
    ) : (
      <Fragment></Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        {!isRunningOnServerSide() && this.state.latlng && this.renderLocation(this.state.latlng)}
      </Fragment>
    );
  }
}

export default PropertyLocation;
