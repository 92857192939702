import React from 'react';
import { Helmet } from 'react-helmet';
import NoSSR from 'react-no-ssr';
import Master from '../components/elements/Master';
import ContactForm from '../components/forms/ContactForm';
import Breadcrumbs from '../components/elements/Breadcrumbs';
import { globalResources } from '../resources/resources';

const ContactPage = () => {
  const head = () => {
    return (
      <Helmet key={Math.random()}>
        <title>Casa e Sol - Contacto</title>
        <meta property="og:title" content="Casa e Sol - Contacto" />
      </Helmet>
    );
  };

  return (
    <Master>
      {head()}
      <Breadcrumbs page={`Contacto`} />
      <section className="contactUs">
        <div className="section-wrapper">
          <header className="section-header">
            <h3>{globalResources.contactUs}</h3>
          </header>
          <div className="section-content">
            <div className="section-content-wrapper">
              <div className="row">
                <div className="col">
                  <p>
                    O Portal CasaeSol não é o detentor dos imóveis anunciados, pelo que qualquer
                    pedido de informação relacionado com algum dos imóveis deve ser colocado
                    diretamente na ficha do respetivo imóvel.
                  </p>
                  <p>
                    Este formulário destina-se exclusivamente a profissionais que desejem
                    informações sobre como anunciar imóveis ou fazer publicidade no portal.
                  </p>
                  <p>Saiba como anunciar ou publicitar no portal Casa e Sol.</p>
                </div>
              </div>
            </div>
            <div className="section-content-wrapper">
              <NoSSR>
                <ContactForm />
              </NoSSR>
            </div>
          </div>
        </div>
      </section>
    </Master>
  );
};

export default {
  component: ContactPage
};
