import React from 'react';
import { Helmet } from 'react-helmet';
import Master from '../components/elements/Master';
import Breadcrumbs from '../components/elements/Breadcrumbs';

const TermsUsePage = () => {
  const head = () => {
    return (
      <Helmet key={Math.random()}>
        <title>Casa e Sol - Termos de Uso</title>
        <meta property="og:title" content="Casa e Sol - Termos de Uso" />
        <link rel="canonical" href="https://casaesol.co.mz/termos-de-uso/" />
      </Helmet>
    );
  };

  return (
    <Master>
      {head()}
      <Breadcrumbs page={`Termos de Uso`} />
      <section className="terms-use">
        <div className="section-wrapper">
          <header className="section-header">
            <h3>Termos de Uso</h3>
          </header>
          <div className="section-content">
            <div className="section-content-wrapper">
              <p>
                O acesso a este portal imobiliário Casa e Sol, pressupões a aceitação integral e sem
                restrições da Política de utilização e suas condições a seguir definidas.
              </p>
              <p> </p>
              <p>1. DEFIINIÇÕES</p>
              <p>
                Portal: este site onde são divulgados imóveis pelos anunciantes, informação
                publicitária e conteúdo disponibilizados pela Improxy.
              </p>
              <p>
                Utilizador: aquele que acede ao portal com o fim de consultar e procurar a
                informação que o mesmo apresenta, relacionada com o imobiliário.
              </p>
              <p>
                Anunciante: entidade singular ou coletiva que através meios tecnológicos,
                disponibiliza informação sobre imóveis ou informação publicitária no portal.
              </p>
              <p>
                Improxy: entidade detentora do portal imobiliário, cujo nome completo é
                Improxy-Tecnologias de Informação, Lda.
              </p>
              <p> </p>
              <p>2. RESPONSABILIDADES E DEVERES</p>
              <p>2.1. Da Improxy</p>
              <p>
                O Portal foi disponibilizado pela Improxy com a boa intenção de disponibilizar aos
                Utilizadores informação relevante sobre imóveis, disponibilizada pelos Anunciantes,
                podendo conter erros ou imprecisões.
              </p>
              <p>
                A Improxy apenas se responsabiliza por eventuais danos que o Utilizador sofra do
                decorrer utilização do Portal quando esses danos lhe possam ser imputáveis como
                resultado de comprovada atuação dolosa por parte da Improxy.
              </p>
              <p>
                A Improxy é apenas responsável pelo funcionamento do Portal e pelos conteúdos que
                ela própria coloca.
              </p>
              <p>
                No caso de falha de funcionamento, a Improxy não assume qualquer obrigação de
                reposição, correção ou substituição do Portal, do seu conteúdo total ou parcial.
              </p>
              <p>
                O Portal irá funcionar por tempo indeterminado, mas a Improxy reserva o direito de
                poder suspender provisoriamente ou definitivamente o funcionamento do Portal, pelas
                razões que achar adequadas.
              </p>
              <p>
                A Improxy não se responsabiliza pelos danos ou percas de qualquer tipo que possam
                resultar, ações tais como, mas não limitadas a:
              </p>
              <p>
                - mau funcionamento do portal, ou de serviços necessários ao seu funcionamento como
                o acesso à internet, o correio eletrónico, a ligação com bases de dados ou sites
                externos;
              </p>
              <p>
                - influência de virús, malware, phishing ou outros mecanismos intencionais de
                alterar o comportamento do Portal, provocados por entidades externas à Improxy
              </p>
              <p>- inexatidão da informação disponibilizada pelos anunciantes</p>
              <p> </p>
              <p>2.2. Dos anunciantes</p>
              <p>
                Os Anunciantes são totalmente responsáveis pela informação que disponibilizam para
                ser apresentada no Portal.
              </p>
              <p>
                É proibida a publicação de anúncios de imóveis ou publicidade cujas fotografias
                infrinjam quaisquer direitos de autor, de privacidade, de publicidade ou de marcas
                para as quais não estejam autorizados.
              </p>
              <p>2.3. Dos utilizadores</p>
              <p>
                Os Utilizadores deverão usar o Portal para o fim que ele foi criado, que é a
                divulgação de informação relevante para quem procura imóveis.
              </p>
              <p>Assim são interditas as seguintes ações:</p>
              <p>
                - copiar conteúdos, informação, imagens, fotografias e qualquer outra informação do
                portal, sem o consentimento prévio do detentor da informação
              </p>
              <p>
                - utilizar o Portal para fins não legais ou que atentem contra os princípios morais
                e bons costumes geralmente aceites
              </p>
              <p>
                - praticar ataques informáticos ou atos contra o bom funcionamento do Portal, tais
                como, mas não limitados a, tentativas para impedir o acesso, alterar conteúdos e
                alterar funcionamento.
              </p>
              <p> </p>
              <p>3. DADOS PESSOAIS</p>
              <p>
                Para acesso a alguns serviços e informação, o Portal poderá pedir informação pessoal
                como o nome, email e telefone e enviar essa informação aos respetivos Anunciantes.
              </p>
              <p>
                {' '}
                A Improxy poderá reter essa informação por tempo indeterminado. Caso pretenda que
                essa informação seja removida da nossa base de dados, deverá fazer o pedido por
                email para o seguinte endereço: comercial@improxy.com.
              </p>
              <p>
                A gestão da informação pessoal que é enviada com seu consentimento para os
                Anunciantes, será da respetiva responsabilidade, pelo que se pretender a sua
                alteração ou remoção deverá contactar diretamente o respetivo anunciante.
              </p>
              <p> </p>
              <p>4. COOKIES</p>
              <p>
                Este Portal utiliza cookies para permitir um melhor desempenho e uma melhor
                experiência de navegação ao utilizador que o visita.
              </p>
              <p>
                Cookies são pequenos ficheiros de texto que ficam armazenados no dispositivo que usa
                para aceder ao website (computador, smartphone ou tablet) com um navegador de
                internet (browser). Os cookies são usados para melhorar a experiência de navegação
                no website ao utilizador, eliminando a necessidade de introduzir repetidamente as
                mesmas informações e ajudando a determinar quais as mensagens publicitárias que o
                utilizador vê que sejam relevantes e se ajustem aos seus interesses.
              </p>
              <p>
                As informações guardadas não recolhem informações pessoais que permitam reconhecer o
                utilizador pois apenas guardam informações genéricas como as informações sobre as
                preferências.
              </p>
              <p>
                Em qualquer momento o utilizador pode configurar o seu navegador de internet para
                decidir ser notificado sobre a receção de cookies, bem como bloquear a utilização
                dos mesmos. Deverá para o efeito consultar a ajuda do seu navegador de internet para
                saber como o pode fazer.
              </p>
              <p>
                Ao desativar cookies, alguns serviços da web podem deixar de funcionar corretamente,
                impedido o funcionamento total ou parcial do website.
              </p>
              <p>
                Para efeitos estatístico e de controlo de envio, as newsletters enviadas por email
                podem conter uma pequena imagem que permite saber se são abertas e verificar os
                cliques através de links ou anúncios dentro da newsletter. O utilizador pode
                desativar o envio (subscrição) da newsletter através da opção específica no rodapé
                da mesma.
              </p>
              <p>Classificação dos cookies quanto à função:</p>
              <p>
                - necessários, indispensáveis para aceder a áreas específicas do website,
                normalmente a áreas seguras através de login; sem estes cookies, os serviços que os
                exijam não podem ser executados;
              </p>
              <p>
                - de funcionalidade, que permitem relembrar as preferências do utilizador
                relativamente à navegação no website;
              </p>
              <p>
                - analíticos, para analisar a forma como os utilizadores usam o site, permitindo
                destacar informações que podem ser do interesse dos utilizadores, e monitorizar o
                desempenho do site, conhecendo quais as páginas mais populares e qual a forma de
                ligação entre páginas que é mais eficaz.
              </p>
              <p>Classificação dos cookies quanto à validade:</p>
              <p>
                - permanentes, ficam armazenados no navegador de internet dos dispositivos de acesso
                e são usados sempre que o utilizador visita novamente o site;
              </p>
              <p>
                - de sessão, que são gerados e apenas estão disponíveis até encerrar a sessão de
                navegação
              </p>
              <p> </p>
              <p>14. LEI APLICÁVEL E JURISDIÇÃO</p>
              <p>A presente Política de utilização do Portal rege-se pela lei portuguesa.</p>
              <p>
                Para dirimir qualquer questão emergente da Política de utilização do Portal, é
                competente o foro da Comarca de Vila Nova de Gaia, com expressa renúncia de qualquer
                outra.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Master>
  );
};

export default {
  component: TermsUsePage,
};
