import {
  REGISTER_FORM_HAS_ERRORED,
  REGISTER_FORM_IS_LOADING,
  REGISTER_FORM_FETCH_DATA_SUCCESS
} from '../actions/types';

let formFeedback = [];

const registerFormReducerDefaultState = {
  formFeedback
};

export function registerFormHasErrored(state = false, action) {
  switch (action.type) {
    case REGISTER_FORM_HAS_ERRORED:
      return action.hasErrored;
    default:
      return state;
  }
}

export function registerFormIsLoading(state = false, action) {
  switch (action.type) {
    case REGISTER_FORM_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

export function registerForm(state = registerFormReducerDefaultState, action) {
  switch (action.type) {
    case REGISTER_FORM_FETCH_DATA_SUCCESS:
      formFeedback = action.formFeedback;

      return {
        formFeedback
      };

    default:
      return state;
  }
}
