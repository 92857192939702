import React from "react";
import { Helmet } from 'react-helmet';
import Breadcrumbs from "../components/elements/Breadcrumbs";
import Master from "../components/elements/Master";

const PrivacyPolicyPage = () => {
  const head = () => {
    return (
      <Helmet key={Math.random()}>
        <title>Casa e Sol - Política de Privacidade</title>
        <meta property="og:title" content="Casa e Sol - Política de Privacidade" />
      </Helmet>
    );
  };

  return (
    <Master>
      {head()}
      <Breadcrumbs page={`Política de Privacidade`} />
      <section className="privacy-policy">
        <div className="section-wrapper">
          <header className="section-header">
            <h3>Política de Privacidade</h3>
          </header>
          <div className="section-content">
            <div className="section-content-wrapper">
              <p>
                <strong>Introdução</strong>
              </p>
              <p>
                Agradecemos que tenha dedicado algum do seu tempo a ler a Política
                de Privacidade do portal imobiliário Casa e Sol.
              </p>
              <p>
                A proteção de dados é um assunto que levamos muito a sério na
                Improxy, Lda. A visualização e utilização das páginas do website
                do portal Casa e Sol é permitida sem que para isso tenha que
                indicar algum tipo de dados pessoais; contudo, se um titular de
                dados quiser usar os serviços disponibilizados através do portal
                imobiliário Casa e Sol, poderão ser requisitados e tratados dados
                pessoais desse utilizador. Se existir a necessidade de tratamento
                de dados pessoais e não existir uma base legal para esse
                tratamento, iremos requisitar o seu consentimento.
              </p>
              <p>
                O tratamento de dados pessoais, como o nome, endereço, endereço de
                e-mail ou número de telefone de um titular de dados, assim como
                identificadores por via eletrónica, deverá estar sempre em
                conformidade com o Regulamento Geral de Proteção de Dados (RGPD) e
                de acordo com a proteção de dados específicos do país,
                regulamentos aplicáveis à Improxy. Através da presente declaração
                de proteção de dados, a Improxy informa ao público em geral a
                natureza, a intenção e a finalidade dos dados pessoais que são
                recolhidos, usados e tratados. Além disso, os titulares dos dados
                são informados, através desta declaração de proteção de dados, dos
                seus direitos.
              </p>
              <p>
                Como responsável de dados advindos do portal imobiliário Casa e
                Sol, a Improxy implementou inúmeras medidas técnicas e
                organizacionais para garantir a mais completa proteção dos dados
                pessoais tratados através deste site. No entanto, as transmissões
                de dados efetuadas na Internet podem, eventualmente, ter lacunas
                de segurança, sendo que, a proteção absoluta pode não ser
                garantida. Por este motivo, todos os titulares de dados têm
                liberdade para transferir dados pessoais através de meios
                alternativos, por ex. pelo e-mail.
              </p>
              <p>
                A Improxy encoraja-o a reler as declarações de privacidade dos
                sites para os quais existem ligações a partir deste site, para que
                possa entender como esses sites recolhem, usam e compartilham as
                suas informações. O site da Improxy, Casa e Sol, não é responsável
                pelas declarações de privacidade ou por outros conteúdos em sites
                fora do próprio site da Improxy.
              </p>
              <p> </p>
              <p>
                <strong>Definições</strong>
              </p>
              <p>
                Portal: este site onde são divulgados imóveis pelos anunciantes,
                informação publicitária e conteúdo disponibilizados pela Improxy.
              </p>
              <p>
                Utilizador: aquele que acede ao portal com o fim de consultar e
                procurar a informação que o mesmo apresenta, relacionada com o
                imobiliário.
              </p>
              <p>
                Anunciante: entidade singular ou coletiva que através meios
                tecnológicos, disponibiliza informação sobre imóveis ou informação
                publicitária no portal.
              </p>
              <p>
                Improxy: entidade detentora do portal imobiliário, cujo nome
                completo é Improxy-Tecnologias de Informação, Lda.
              </p>
              <p> </p>
              <p>
                A presente declaração de proteção de dados da Improxy baseia-se
                nos termos utilizados pelo legislador europeu para a adoção do
                Regulamento Geral de Proteção de Dados (RGPD). A presente
                declaração de proteção de dados deve ser legível e compreensível
                para o público em geral, bem como para clientes e parceiros de
                negócios. Para termos a garantia dessa compreensão, iremos
                primeiro explicar a terminologia usada.
              </p>
              <p>
                Nesta declaração de proteção de dados, utilizamos os seguintes
                termos:
              </p>
              <p>a) Dados pessoais</p>
              <p>
                Significa qualquer informação relativa a uma pessoa singular que a
                possa identificar ou a torne identificável (“titular de dados”).
                Uma pessoa singular identificável é aquela que pode ser
                identificada, direta ou indiretamente, utilizando por referência
                um identificador como um nome, um número de identificação, dados
                de localização, um identificador on-line ou a um ou mais fatores
                específicos ao físico, fisiológico, identidade genética, mental,
                econômica, cultural ou social dessa pessoa.
              </p>
              <p>b) Dados do utilizador</p>
              <p>
                Dados do utilizador entende-se pela pessoa física identificada ou
                identificável, cujos dados pessoais são tratados pelo responsável
                de dados responsável pelo tratamento.
              </p>
              <p>c) Tratamento</p>
              <p>
                É qualquer operação ou conjunto de operações que são realizadas
                nos dados pessoais ou em conjuntos de dados pessoais, seja ou não
                por meios automatizados, tais como recolha, registo, organização,
                estruturação, armazenamento, adaptação ou alteração, recuperação,
                consulta, uso, divulgação por transmissão, divulgação ou
                disponibilização de qualquer outra forma, alinhamento ou
                combinação, restrição, eliminação ou destruição.
              </p>
              <p>d) Limitação de tratamento</p>
              <p>
                É a marcação de dados pessoais armazenados com o objetivo de
                limitar o seu tratamento no presente e futuro.
              </p>
              <p>e) Definição de perfilização</p>
              <p>
                Significa qualquer forma de tratamento automatizado de dados
                pessoais que consiste na utilização de dados pessoais para avaliar
                determinados aspetos pessoais relacionados com uma pessoa
                singular, em particular para analisar ou prever aspetos relativos
                ao desempenho dessa pessoa no trabalho, situação económica, saúde,
                preferências pessoais, interesses, confiabilidade, comportamento,
                localização ou potenciais movimentos.
              </p>
              <p>f) Pseudoanonimato</p>
              <p>
                É o tratamento de dados pessoais de tal forma a que os dados
                pessoais não possam ser atribuídos a um indivíduo específico sem o
                uso de informações adicionais, desde que essas informações
                adicionais sejam mantidas separadamente e estejam sujeitas a
                medidas técnicas e organizacionais para garantir que os dados
                pessoais não são atribuídos a uma pessoa singular identificada ou
                identificável.
              </p>
              <p>g) Responsável pelo tratamento</p>
              <p>
                É a pessoa física ou jurídica, autoridade pública, agência ou
                outro organismo que, sozinho ou em conjunto com outros, determina
                os fins e meios do tratamento de dados pessoais. Se os fins e os
                meios desse tratamento forem determinados pela legislação da União
                ou do Estado-Membro, o responsável pelo tratamento ou os critérios
                específicos para a sua nomeação podem ser estabelecidos pelo
                direito da União ou do Estado-Membro.
              </p>
              <p>h) Subcontratante</p>
              <p>
                É a pessoa singular ou coletiva, autoridade pública, agência ou
                outro organismo que trata dados pessoais em nome do responsável
                pelo tratamento.
              </p>
              <p>i) Destinatário</p>
              <p>
                É a pessoa singular ou coletiva, autoridade pública, agência ou
                outro organismo, para o qual os dados pessoais são divulgados,
                sejam terceiros ou não. No entanto, as autoridades públicas que
                possam receber dados pessoais no âmbito de um inquérito específico
                em conformidade com a legislação da União ou do Estado-Membro não
                são consideradas destinatários.
              </p>
              <p>j) Terceiros</p>
              <p>
                É uma pessoa singular ou coletiva, autoridade pública, agência ou
                organismo que não seja o titular dos dados, o responsável pelo
                tratamento, o subcontratante e as pessoas que, sob a autoridade
                direta do responsável pelo tratamento ou subcontratante, estão
                autorizados a tratar dados pessoais.
              </p>
              <p>k) Consentimento</p>
              <p>
                É qualquer indicação dada, pelo titular dos dados, livremente,
                específica, informada e inequívoca dos desejos do titular dos
                dados pelos quais ele ou ela, por uma declaração ou por uma ação
                afirmativa clara, acorda e cede a informação para o tratamento de
                dados pessoais relativos ao indivíduo.
              </p>
              <p> </p>
              <p>Nome e contacto do responsável de dados</p>
              <p>
                Para efeitos do Regulamento Geral de Proteção de Dados (RGPD),
                outras leis de proteção de dados aplicáveis nos Estados-Membros da
                União Europeia e outras disposições relacionadas com a proteção de
                dados, a identificação do Responsável de Dados é:
              </p>
              <p>Improxy II Software, Lda.</p>
              <p>NIF: 513555331</p>
              <p>Rua Dr. Ilídio Sardoeira, 28 C - Sala 1.1</p>
              <p>4400-107 Vila Nova de Gaia</p>
              <p>Telefone: 223749100</p>
              <p>Email: comercial@improxy.com</p>
              <p> </p>
              <p>Nome e endereço do encarregado pela Proteção de Dados</p>
              <p>O encarregado pela Proteção de Dados da Improxy:</p>
              <p>Rua Dr. Ilídio Sardoeira, 28 C - Sala 1.1</p>
              <p>4400-107 Vila Nova de Gaia</p>
              <p>Telefone: 223749100</p>
              <p>Email: dpo@improxy.com</p>
              <p> </p>
              <p>
                Qualquer utilizador pode, a qualquer momento, contactar
                diretamente o responsável pela proteção de dados com todas as
                questões e sugestões relativas à proteção de dados.
              </p>
              <p> </p>
              <p>
                <strong>Cookies</strong>
              </p>
              <p>
                As páginas de Internet do portal Casa e Sol utilizam cookies.
                Cookies são arquivos de texto que são armazenados num sistema de
                computador através de um navegador da Internet.
              </p>
              <p>
                Muitos sites e servidores da Internet usam cookies. Muitos cookies
                contêm um chamado ID de cookie. Um ID de cookie é um identificador
                exclusivo do cookie. Ele consiste numa cadeia de caracteres
                através da qual as páginas e os servidores da Internet podem ser
                atribuídos ao navegador de Internet específico no qual o cookie
                foi armazenado. Essa informação permite que sites e servidores da
                Internet visitados diferenciem o navegador individual do
                utilizador de outros navegadores da Internet que contêm outros
                cookies. Um navegador da Internet específico pode ser reconhecido
                e identificado usando o ID exclusivo do cookie.
              </p>
              <p>
                Através do uso de cookies, a Improxy pode fornecer aos usuários
                deste site serviços mais amigáveis ??que não seriam possíveis sem
                a configuração do cookie.
              </p>
              <p>
                Por meio de um cookie, as informações no site podem ser otimizadas
                com o usuário em mente. Os cookies permitem-nos, como mencionado
                anteriormente, reconhecer os usuários do site. O objetivo desse
                reconhecimento é facilitar aos usuários a utilização do site. O
                usuário do site que usa cookies, por exemplo não precisa inserir
                os dados de acesso sempre que o site for acedido, porque isso é
                assumido pelo site, e o cookie é, portanto, armazenado no sistema
                de computador do usuário.
              </p>
              <p>
                O titular dos dados pode, a qualquer momento, impedir a utilização
                de cookies configurando o navegador da Internet utilizado,
                podendo, assim, negar permanentemente a utilização desses cookies.
                Além disso, os cookies já definidos podem ser excluídos a qualquer
                momento através de um navegador da Internet ou de outros programas
                de software. Isso é possível em todos os navegadores de Internet
                mais populares. Se o titular dos dados desativar a configuração de
                cookies no navegador da Internet utilizado, algumas funções do
                site Casa e Sol poderão não ficar totalmente disponíveis.
              </p>
              <p>Classificação dos cookies quanto à função:</p>
              <p>
                - Necessários, indispensáveis para aceder a áreas específicas do
                website, normalmente a áreas seguras através de login; sem estes
                cookies, os serviços que os exijam não podem ser executados.
              </p>
              <p>
                - De funcionalidade, que permitem relembrar as preferências do
                utilizador relativamente à navegação no website.
              </p>
              <p>
                - Analíticos, para analisar a forma como os utilizadores usam o
                site, permitindo destacar informações que podem ser do interesse
                dos utilizadores, e monitorizar o desempenho do site, conhecendo
                quais as páginas mais populares e qual a forma de ligação entre
                páginas que é mais eficaz.
              </p>
              <p>Classificação dos cookies quanto à validade:</p>
              <p>
                - Permanentes, ficam armazenados no navegador de internet dos
                dispositivos de acesso e são usados sempre que o utilizador visita
                novamente o site.
              </p>
              <p>
                - De sessão, que são gerados e apenas estão disponíveis até
                encerrar a sessão de navegação.
              </p>
              <p> </p>
              <p>Segue a lista de cookies utilizados pelo website Casa e Sol:</p>
              <p>
                _ga: cookie utilizado pelo Google Analytics, permite distinguir
                utilizadores únicos ao criar números identificadores para cada
                utilizador. Encontra-se em todas as páginas do website e
                monitoriza os dados da visita, da sessão e da campanha para
                acrescentar os dados ao relatório. Permanece durante o período de
                2 anos.
              </p>
              <p>
                _gid: cookie utilizado pelo Google Analytics, permite distinguir
                utilizadores. Tem a duração de 24 horas.
              </p>
              <p> </p>
              <p>
                <strong>Recolha de dados e informações gerais</strong>
              </p>
              <p>
                O portal Casa e Sol recolhe uma série de dados e informações
                gerais quando um titular de dados ou sistema automatizado acede ao
                site. Esses dados e informações gerais são armazenados nos
                arquivos de log do servidor. A obtenção de dados poderá ser (1) os
                tipos de navegadores e versões utilizadas, (2) o sistema
                operacional utilizado pelo sistema de acesso, (3) o site a partir
                do qual um sistema de acesso chega ao portal (os chamados
                referenciadores), (4) sub -sites, (5) data e hora de acesso ao
                site da Internet, (6) endereço de Internet (endereço IP), (7)
                provedor de acesso à Internet do sistema de acesso e (8) quaisquer
                outros dados e informações que podem ser usadas em caso de ataques
                aos nossos sistemas de tecnologia da informação.
              </p>
              <p>
                Ao utilizar estes dados e informações gerais, a Improxy não tira
                conclusões sobre o titular dos dados. Pelo contrário, esta
                informação é necessária para (1) entregar o conteúdo do portal
                corretamente, (2) otimizar o conteúdo do portal, bem como a sua
                publicidade, (3) garantir a viabilidade a longo prazo dos sistemas
                de tecnologia da informação e tecnologia do site e (4) fornecer às
                autoridades policiais as informações necessárias para o processo
                criminal em caso de um ciberataque. Portanto, a Improxy analisa
                estatisticamente dados e informações recolhidas anonimamente, com
                o objetivo de aumentar a proteção de dados e segurança de dados da
                empresa, e garantir um excelente nível de proteção para os dados
                pessoais que tratamos. Os dados anônimos dos arquivos de log do
                servidor são armazenados separadamente de todos os dados pessoais
                fornecidos pelo utilizador.
              </p>
              <p> </p>
              <p>
                <strong>Contacto através do portal</strong>
              </p>
              <p>
                O portal Casa e Sol contém formulário que permite um rápido
                contato eletrónico com a Improxy. Se um titular de dados entrar em
                contato, os dados pessoais transmitidos pela pessoa em questão
                serão armazenados automaticamente. Esses dados pessoais
                transmitidos voluntariamente por um titular de dados ao
                responsável de dados são armazenados com o objetivo de tratar ou
                contactar o titular dos dados. Não há transferência desses dados
                pessoais para terceiros.
              </p>
              <p> </p>
              <p>
                <strong>
                  Rotinas de eliminação e de bloqueio de dados pessoais
                </strong>
              </p>
              <p>
                O responsável pelo tratamento de dados tratará e armazenará os
                dados pessoais da pessoa em causa apenas durante o período
                necessário para atingir a finalidade de armazenamento, ou, na
                medida em que tal seja concedido pelo legislador europeu ou outros
                legisladores em leis ou regulamentos aos quais o responsável de
                dados está sujeito.
              </p>
              <p>
                Se o objetivo de armazenamento não for aplicável, ou se expirar um
                período de armazenamento prescrito pelo legislador europeu ou
                outro legislador competente, os dados pessoais são rotineiramente
                bloqueados ou apagados de acordo com os requisitos legais.
              </p>
              <p> </p>
              <p>
                <strong>Direitos do utilizador</strong>
              </p>
              <p>a) Direito de confirmação</p>
              <p>
                Cada titular de dados tem o direito conferido pelo legislador
                europeu de obter do responsável pelo tratamento a confirmação de
                que os dados pessoais relativos ao mesmo estão ou não a ser
                tratados. Se um titular de dados desejar se valer desse direito de
                confirmação, ele poderá, a qualquer momento, entrar em contato com
                qualquer funcionário do responsável de dados.
              </p>
              <p>b) Direito ao acesso</p>
              <p>
                Cada titular de dados tem o direito conferido pelo legislador
                europeu de obter do responsável pelo tratamento informações
                gratuitas sobre os seus dados pessoais armazenados a qualquer
                momento e uma cópia dessas informações. Além disso, as diretivas e
                os regulamentos europeus concedem ao titular dos dados o acesso às
                seguintes informações:
              </p>
              <p>- O propósito do tratamento de dados;</p>
              <p>- As categorias de dados pessoais em causa;</p>
              <p>
                - Os destinatários ou categorias de destinatários a quem os dados
                pessoais foram ou serão divulgados, em especial os destinatários
                em países terceiros ou organizações internacionais;
              </p>
              <p>
                - Sempre que possível, o período previsto para o qual os dados
                pessoais serão armazenados ou, se tal não for possível, os
                critérios utilizados para determinar esse período;
              </p>
              <p>
                - A existência do direito de solicitar a retificação ou apagamento
                do tratamento de dados pessoais, ou a restrição do tratamento de
                dados pessoais relativos ao titular dos dados, ou de se opor a
                esse tratamento;
              </p>
              <p>
                - A existência do direito de apresentar queixa junto de uma
                autoridade de supervisão;
              </p>
              <p>
                - Onde os dados pessoais são recolhidos da pessoa em causa, com
                informação disponível sobre a sua origem;
              </p>
              <p>
                - A existência de automatismos de tomada de decisões, incluindo
                definição de perfis, referidas nos artigos nº 1 e 4 do artigo 22º
                do RGPD e, pelo menos nesses casos, informações significativas
                sobre a lógica utilizada, bem como o significado e as
                consequências previstas de tal tratamento para o titular dos
                dados.
              </p>
              <p>
                Para além disso, o titular dos dados tem o direito de obter
                informações sobre se os dados pessoais são transferidos para um
                país terceiro ou para uma organização internacional. Se for esse o
                caso, o titular dos dados terá o direito de ser informado das
                garantias adequadas relativas à transferência da informação.
              </p>
              <p>
                Se o titular de dados desejar, a qualquer momento, valer-se desse
                direito de acesso, ele poderá entrar em contato com qualquer
                funcionário do responsável de dados.
              </p>
              <p>c) Direito de retificação</p>
              <p>
                Cada titular de dados tem o direito conferido pelo legislador
                europeu de obter do responsável pelo tratamento, sem demora, a
                retificação de dados pessoais incorretos que lhe digam respeito.
                Tendo em conta o fim do tratamento, o titular dos dados tem o
                direito de fornecer dados pessoais para completar a sua
                informação, incluindo através de uma declaração suplementar.
              </p>
              <p>
                Se um titular de dados desejar exercer este direito de
                retificação, ele poderá, a qualquer momento, entrar em contato com
                qualquer funcionário do responsável de dados.
              </p>
              <p>d) Direito de eliminação (Direito ao esquecimento)</p>
              <p>
                Cada titular de dados tem o direito conferido pelo legislador
                europeu de obter do responsável pelo tratamento a eliminação de
                dados pessoais que lhe digam respeito, sem demora, e o responsável
                pelo tratamento tem a obrigação de apagar os dados pessoais sem
                demora, se ocorrer um dos seguintes motivos (aplica-se, desde que
                o tratamento não seja necessário):
              </p>
              <p>
                - Os dados pessoais não são necessários tendo em vista a
                finalidade para que foram registados e arquivados.
              </p>
              <p>
                – O titular dos dados retira o consentimento para o tratamento dos
                dados, de acordo com os termos do artigo 6.º, nº 1, alínea a), do
                RGPD, ou do artigo 9.º, nº 2, alínea a), do RGPD e quando não
                existe outro fundamento jurídico para a continuação do
                armazenamento dos dados.
              </p>
              <p>
                - Quando o titular dos dados se opõe ao tratamento dos dados
                segundo os termos do artigo 21º, nº1, do RGPD, e caso não existam
                razões legítimas para o armazenamento de dados, ou caso o titular
                dos dados restrinja o tratamento de dados nos termos do artigo
                21º, nº 2, do RGPD.
              </p>
              <p>– Os dados pessoais foram tratados ilegalmente.</p>
              <p>
                - Os dados pessoais sejam alvo de eliminação obrigatória para
                cumprimento de ação legal na legislação da União Europeia ou de
                algum Estado-Membro à qual o responsável pelo tratamento de dados
                está sujeito.
              </p>
              <p>
                - Os dados pessoais tenham sido recolhidos em relação à oferta de
                serviços de sociedade de informação referidos no artigo 8º, nº 1,
                do RGPD.
              </p>
              <p>
                Se uma das razões acima mencionadas se aplicar, e um titular de
                dados desejar solicitar a eliminação de dados pessoais armazenados
                pela Improxy, ele ou ela poderá, a qualquer momento, entrar em
                contato com qualquer funcionário do responsável de dados. Um
                funcionário da Improxy assegurará imediatamente que o pedido de
                cancelamento seja atendido imediatamente.
              </p>
              <p>
                Caso o responsável pelo tratamento de dados tenha tornado público
                os dados pessoais e seja obrigado, nos termos do artigo 17.º, n.º
                1, a apagar esses dados, o responsável pelo tratamento, tendo em
                conta a tecnologia disponível e os custos de implementação, tomará
                medidas razoáveis, incluindo medidas técnicas para informar outros
                responsáveis de dados. Os responsáveis de dados que tratem os
                dados pessoais que o titular dos dados solicitou eliminação, devem
                seguir e proceder de forma acordada, eliminando, quaisquer
                ligações, cópias ou replicas desses dados pessoais, desde que o
                tratamento não seja necessário. Um funcionário da Improxy
                providenciará as medidas necessárias em casos individuais.
              </p>
              <p>e) Direito à restrição de tratamento</p>
              <p>
                Cada titular de dados tem o direito, conferido pelo legislador
                europeu, de obter do responsável pelo tratamento de dados, a
                restrição de tratamento, sempre que se verifique uma das seguintes
                situações:
              </p>
              <p>
                – A precisão dos dados pessoais é contestada pelo titular dos
                dados, sendo dado um período que permite ao responsável de dados
                verificar a exatidão dos dados pessoais.
              </p>
              <p>
                – O tratamento é ilegal e o titular dos dados se opõe ao
                apagamento dos dados pessoais e solicita, em vez disso, a
                restrição do seu uso.
              </p>
              <p>
                – O responsável de dados não precisa mais dos dados pessoais para
                fins de tratamento, mas esses dados são requisitados pelo seu
                titular para o exercício ou defesa de reivindicações legais.
              </p>
              <p>
                – O titular dos dados opôs-se ao tratamento nos termos do artigo
                21º, nº 1, do RGPD, na pendência da verificação da questão de
                saber se os fundamentos legítimos do responsável pelo tratamento
                prevalecem sobre os do titular de dados.
              </p>
              <p>
                Se uma das condições acima mencionadas for atendida e um titular
                de dados desejar solicitar a restrição do tratamento de dados
                pessoais armazenados pela Improxy, ele poderá, a qualquer momento,
                entrar em contato com qualquer funcionário do responsável de
                dados. O colaborador da Improxy providenciará a restrição do
                tratamento.
              </p>
              <p>f) Direito à portabilidade</p>
              <p>
                Cada titular de dados tem o direito conferido pelo legislador
                europeu de receber os dados pessoais que facultou, que foram
                fornecidos a um responsável pelo tratamento, num formato
                estruturado, habitualmente utilizado e legível pelo dispositivo.
                Tem o direito de transmitir esses dados a outro responsável pelo
                tratamento, sem impedimento do responsável pelo tratamento a que
                os dados pessoais foram fornecidos, desde que o tratamento se
                baseie no consentimento previsto no artigo 6.º, n.º 1, alínea a).
                RGPD ou a alínea a) do nº 2 do artigo 9.º do RGPD, ou num contrato
                nos termos do artigo 6.o, nº 1, alínea b), do RGPD, sendo o
                tratamento efetuado por meios automatizados, desde que: o
                tratamento não seja utilizado para o desempenho de uma tarefa
                realizada no interesse público ou no exercício da autoridade
                oficial do responsável pelo tratamento.
              </p>
              <p>
                Além disso, ao exercer o seu direito à portabilidade de dados nos
                termos do artigo 20.º, n.º 1, do RGPD, o titular dos dados tem o
                direito de transmitir dados pessoais diretamente de um responsável
                de dados para outro, sempre que tecnicamente exequível e quando o
                fizer sem afetar adversamente os direitos e liberdades dos outros.
              </p>
              <p>
                A fim de reivindicar o direito à portabilidade de dados, o titular
                dos dados pode, a qualquer momento, contactar qualquer colaborador
                da Improxy.
              </p>
              <p>g) Direito de contestação</p>
              <p>
                Cada titular de dados tem o direito, atribuído pelo legislador
                europeu, à contestação, a qualquer momento, por motivos
                relacionados com a sua situação particular, relativamente ao
                tratamento de dados pessoais que lhe digam respeito, baseado nas
                alíneas e) ou f) do artigo 6.º, n.º 1, do RGPD. Aplica-se
                igualmente à criação de perfis com base nessas disposições.
              </p>
              <p>
                A Improxy deixará de tratar os dados pessoais em caso de objeção,
                a menos que demonstre motivos legítimos convincentes para o
                tratamento que se sobreponham aos interesses, direitos e
                liberdades do titular dos dados, ou para o estabelecimento,
                exercício ou defesa de reivindicações legais.
              </p>
              <p>
                Se a Improxy tratar dados pessoais para fins de marketing direto,
                o titular dos dados terá o direito de se opor a qualquer momento
                ao tratamento de dados pessoais relativos a esse tipo de
                marketing. Isso aplica-se ao perfil que está relacionado a esse
                marketing direto. Se o titular dos dados fizer objeções à Improxy
                para o tratamento para fins de marketing direto, a Improxy não
                tratará mais os dados pessoais para esses fins.
              </p>
              <p>
                Além disso, a pessoa em causa tem o direito, por motivos
                relacionados com a sua situação particular, de se opor ao
                tratamento de dados pessoais que lhe sejam imputados pela Improxy
                para fins de investigação científica ou histórica, ou para fins
                estatísticos nos termos do Artigo 89.º, n.º 1, do RGPD, a menos
                que o tratamento seja necessário para o desempenho de uma tarefa
                realizada por razões de interesse público.
              </p>
              <p>
                Para exercer o direito de contestação, o titular dos dados pode
                contatar qualquer funcionário da Improxy. Além disso, a pessoa em
                causa é livre no contexto da utilização de serviços da sociedade
                da informação, e não obstante a Diretiva 2002/58 / CE, para
                utilizar o seu direito de contestação por meios automatizados,
                utilizando especificações técnicas.
              </p>
              <p>
                h) Tomada de decisão individual automatizada, incluindo definição
                de perfil
              </p>
              <p>
                Cada titular de dados tem o direito que o legislador europeu
                concede de não ser objeto de uma decisão baseada unicamente em
                tratamento automatizado, incluindo a definição de perfis, que
                produzam efeitos jurídicos em relação a si ou que afetem
                significativamente a pessoa, desde que (1) não seja necessário
                para celebrar ou executar um contrato entre o titular dos dados e
                um responsável pelo tratamento de dados ou (2) não estiver
                autorizado pela legislação da União ou do Estado-Membro a que o
                responsável esteja sujeito e também medidas adequadas para
                salvaguardar os direitos e liberdades e os interesses legítimos da
                pessoa em causa, ou (3) não se baseia no consentimento explícito
                da pessoa em causa.
              </p>
              <p>
                Se a decisão (1) for necessária para a celebração ou execução de
                um contrato entre o titular dos dados e um responsável pelo
                tratamento de dados, ou (2) se basear no consentimento explícito
                do titular dos dados, a Improxy implementará medidas para
                salvaguardar os direitos e liberdades da pessoa em causa e os
                interesses legítimos, pelo menos o direito de obter intervenção
                humana por parte do responsável pelo tratamento, para expressar o
                seu ponto de vista e contestar a decisão.
              </p>
              <p>
                Se o titular dos dados desejar exercer os direitos relativos à
                tomada de decisão individual automatizada, ele poderá, a qualquer
                momento, contatar qualquer funcionário da Improxy.
              </p>
              <p>i) Direito de revogação do consentimento de proteção de dados</p>
              <p>
                Cada titular de dados terá o direito que o legislador europeu
                confere de retirar o seu consentimento para o tratamento dos seus
                dados pessoais a qualquer momento.
              </p>
              <p>
                Se o titular dos dados desejar exercer o direito de retirar o
                consentimento, ele poderá, a qualquer momento, contatar qualquer
                funcionário da Improxy.
              </p>
              <p> </p>
              <p>
                <strong>
                  Informação de proteção de dados sobre a rede social Facebook
                </strong>
              </p>
              <p>
                Neste site, o responsável de dados integrou componentes da empresa
                Facebook. O Facebook é uma rede social.
              </p>
              <p>
                Uma rede social é um local para partilha social na Internet, uma
                comunidade on-line, que geralmente permite que os usuários se
                comuniquem entre si e interajam num espaço virtual. Uma rede
                social pode servir como uma plataforma para a troca de opiniões e
                experiências, ou permitir que a comunidade da Internet forneça
                informações pessoais ou relacionadas a negócios. O Facebook
                permite que os usuários desta rede social criem perfis privados,
                façam carregamento de fotos e criem redes por meio de solicitações
                de amizade.
              </p>
              <p>
                A empresa operadora do Facebook é a Facebook, Inc., 1 Hacker Way,
                Menlo Park, CA 94025, Estados Unidos. Para residentes fora dos
                Estados Unidos ou do Canadá, a responsável de dados é a Facebook
                Ireland Ltd., 4 Grand Canal Square, Grande Canal Harbor, Dublin 2,
                Irlanda.
              </p>
              <p>
                A cada chamada efetuada para uma das páginas individuais deste
                site da Internet, operado pelo responsável de dados e no qual um
                componente do Facebook (plug-ins do Facebook) foi integrado, o
                navegador da Web no sistema de tecnologia da informação da pessoa
                em questão é automaticamente solicitado a descarregar a exibição
                do componente correspondente do Facebook através do próprio
                Facebook. Uma visão geral de todos os plug-ins do Facebook pode
                ser visitada em https://developers.facebook.com/docs/plugins/.
                Durante o curso deste procedimento técnico, o Facebook recolhe
                informação de que página específica do nosso site foi visitado
                pelo utilizador.
              </p>
              <p>
                Se o titular dos dados estiver ligado ao mesmo tempo no Facebook,
                o Facebook detetará todas as ligações para o site da Improxy pelo
                detentor dos dados – e por todo o tempo da visita do utilizador no
                website da Improxy – assim como que páginas específicas foram
                visitadas pela pessoa em causa. Essas informações são recolhidas
                através das ferramentas do Facebook e associadas à respetiva conta
                do Facebook do titular dos dados. Se a pessoa em causa clicar num
                dos botões do Facebook integrados no nosso website, por ex. o
                botão “Gostar”, ou se o titular de dados enviar um comentário, o
                Facebook corresponderá essa informação à conta de usuário pessoal
                do Facebook do titular dos dados e armazenará os dados pessoais.
              </p>
              <p>
                O Facebook irá receber continuamente, através das ferramentas do
                Facebook, informações sobre as visitas ao portal imobiliário Casa
                e Sol feitas pelo titular dos dados, desde que o titular de dados
                esteja ligado no Facebook durante o período de visita ao site.
                Este processo irá ocorrer independentemente do sujeito clicar no
                ícone do Facebook ou não. Se tal transmissão de informações para o
                Facebook não for desejável para o titular dos dados, poderá evitar
                o envio dessa informação desligando a sua conta do Facebook antes
                de visitar o portal imobiliário Casa e Sol.
              </p>
              <p>
                A diretriz de proteção de dados publicada pelo Facebook,
                disponível em https://facebook.com/about/privacy/, fornece
                informações sobre a recolha, tratamento e uso de dados pessoais
                pelo Facebook. Além disso, são explicadas as opções de
                configuração oferecidas pelo Facebook para proteger a privacidade
                dos dados. Mais ainda, são disponibilizadas diferentes opções de
                configuração para permitir a eliminação da transmissão de dados
                para o Facebook. Estas medidas podem ser utilizadas pelo titular
                dos dados para eliminar a transmissão de dados para o Facebook.
              </p>
              <p> </p>
              <p>
                <strong>
                  Informação de proteção de dados sobre a aplicação e uso do
                  Google Analytics (com função de anonimização)
                </strong>
              </p>
              <p>
                No portal Casa e Sol, o responsável de dados integrou o componente
                do Google Analytics (com a função de anonimização). O Google
                Analytics é um serviço de análise da web. Web analytics é o
                processo de recolha e análise de dados sobre o comportamento dos
                visitantes dos sites. Um serviço de análise da Web recolhe e
                concilia, dados sobre o site a partir do qual um utilizador chegou
                (o chamado referenciador), quais subpáginas foram visitadas ou com
                que frequência e por quanto tempo uma subpágina foi visualizada. A
                análise da Web é usada principalmente para a otimização de um site
                e para realizar uma análise de custo-benefício da publicidade na
                Internet.
              </p>
              <p>
                A operadora do componente Google Analytics é a Google Inc., 1600
                Amphitheatre Pkwy, Mountain View, CA 94043-1351, Estados Unidos.
              </p>
              <p>
                Para a análise da web por meio do Google Analytics, o responsável
                de dados usa o aplicativo “_gat. _anonymizeIp”. Por meio deste
                aplicativo, o endereço IP da conexão de Internet do titular dos
                dados é abreviado pelo Google e anonimizado ao aceder ao site a
                partir de um Estado Membro da União Europeia ou de outro Estado
                Contratante ao Acordo sobre o Espaço Econômico Europeu.
              </p>
              <p>
                O objetivo do componente do Google Analytics é analisar o tráfego
                no site. O Google usa os dados e informações recolhidas, entre
                outros, para avaliar o uso do portal imobiliário Casa e Sol e para
                fornecer relatórios on-line, que mostram as atividades no site e
                para fornecer outros serviços relacionados ao uso do site na
                Internet.
              </p>
              <p>
                O Google Analytics coloca um cookie no sistema de tecnologia do
                detentor dos dados. A definição de cookies é explicada acima. Com
                a configuração do cookie, o Google está habilitado a analisar o
                uso do portal imobiliário Casa e Sol. A cada ligação feita para
                uma das páginas individuais deste site da Internet, operado pelo
                responsável de dados e no qual um componente do Google Analytics
                foi integrado, o navegador da Internet no sistema de tecnologia da
                informação do sujeito dos dados enviará os dados automaticamente
                por meio da ferramenta do Google Analytics para fins de
                publicidade on-line e a liquidação de comissões para o Google.
                Durante o curso deste procedimento técnico, a empresa Google
                adquire conhecimento de informações pessoais, como o endereço IP
                do titular dos dados, que serve ao Google, entre outras coisas,
                para perceber a origem dos visitantes e cliques e,
                subsequentemente, criar acordos de comissão.
              </p>
              <p>
                O cookie é usado para armazenar informações pessoais, como o tempo
                de acesso, a localização a partir da qual o acesso foi feito e a
                frequência de visitas ao site pelo utilizador. A cada visita ao
                site, esses dados pessoais, incluindo o endereço IP utilizado pelo
                titular dos dados, serão transmitidos ao Google nos Estados Unidos
                da América. Esses dados pessoais são armazenados pelo Google. O
                Google pode passar esses dados pessoais recolhidos por meio do
                procedimento técnico para terceiros.
              </p>
              <p>
                O titular dos dados pode, como dito acima, impedir a utilização de
                cookies do portal imobiliário Casa e Sol, a qualquer momento, por
                meio de ajuste correspondente no navegador da web que utilizar e,
                assim, negar permanentemente a utilização de cookies. Tal ajuste
                no navegador da Internet usado também impedirá que o Google
                Analytics instale um cookie no sistema de tecnologia de informação
                do utilizador. Além disso, os cookies que já estão em uso pelo
                Google Analytics podem ser excluídos a qualquer momento através do
                navegador da Web ou através de outros programas.
              </p>
              <p>
                Mais ainda, o titular dos dados tem a possibilidade de se opor a
                uma recolha de dados que são gerados pelo Google Analytics,
                relacionado ao uso deste site, bem como o tratamento desses dados
                pelo Google. Para tal, o titular dos dados deve descarregar um
                complemento ao navegador no link
                https://tools.google.com/dlpage/gaoptout e instalá-lo. Esse
                complemento ao navegador informa o Google Analytics através de
                JavaScript que quaisquer dados e informações sobre as visitas de
                páginas da Internet não podem ser transmitidos ao Google
                Analytics. A instalação do complemento ao navegador é considerada
                uma objeção ao Google. Se o sistema de tecnologia da informação do
                titular dos dados for posteriormente, formatado ou novamente
                instalado, o titular dos dados deverá reinstalar os complementos
                ao navegador para desativar o Google Analytics. Se o complemento
                ao navegador foi desinstalado ou desativado pelo titular dos dados
                ou por qualquer outra pessoa que seja atribuível à sua esfera de
                competência, é possível executar a reinstalação ou reativação dos
                complementos ao navegador.
              </p>
              <p>
                Mais informações e os métodos de proteção de dados aplicáveis ??do
                Google podem ser obtidas em
                https://www.google.com/intl/pt-BR/policies/privacy/ e em
                http://www.google.com/analytics/terms/us. html. O Google Analytics
                é explicado na ligação a seguir https://www.google.com/analytics/.
              </p>
              <p>
                <strong>Base legal para o tratamento</strong>
              </p>
              <p>
                Art. 6 (1) do RGPD serve como base legal para operações de
                tratamento para as quais obtemos consentimento para um propósito
                de tratamento específico. Se o tratamento de dados pessoais for
                necessário para a execução de um contrato do qual o titular dos
                dados faz parte, como é o caso, por exemplo, quando as operações
                de tratamento são necessárias para o fornecimento de bens ou para
                fornecer qualquer outro serviço, o tratamento é autorizado com
                base no artigo 6.º, n.º 1, b RGPD. O mesmo se aplica a tais
                operações de tratamento que são necessárias para a execução de
                medidas pré-contratuais, por exemplo, no caso de consultas
                relativas aos nossos produtos ou serviços. A nossa empresa é
                sujeita a uma obrigação legal pelo qual o tratamento de dados
                pessoais é necessário, como para o cumprimento das obrigações
                fiscais, tratamento baseado no art. 6 (1) lit. c RGPD. Em casos
                raros, o tratamento de dados pessoais pode ser necessário para
                proteger os interesses vitais do titular dos dados ou de outra
                pessoa singular. Este seria o caso, por exemplo, se um visitante
                fosse ferido na entidade e o seu nome, idade, dados de seguro de
                saúde ou outras informações vitais tivessem que ser transmitidos
                ??para um médico, hospital ou outro. Esta ação e respetivo
                tratamento seriam baseados no art. 6 (1) lit. do RGPD. Por último,
                as operações de tratamento de dados podem basear-se no n.º 1 do
                artigo 6.º do código RGPD. Esta base jurídica é utilizada para
                operações de tratamento não abrangidas por qualquer dos
                fundamentos jurídicos acima referidos, se o tratamento for
                necessário para os fins legítimos prosseguidos pela Improxy ou por
                um terceiro, salvo se esses interesses forem anulados pelos
                interesses ou direitos e liberdades fundamentais da pessoa em
                causa que requerem proteção de dados pessoais. Tais operações de
                tratamento são particularmente admissíveis porque foram
                especificamente mencionadas pelo legislador europeu. Considerasse
                que um interesse legítimo poderia ser assumido se o titular dos
                dados for um cliente do responsável pelo tratamento (considerando
                47º, frase 2, RGPD).
              </p>
              <p> </p>
              <p>
                <strong>Legítimos interesses da Improxy e de terceiros</strong>
              </p>
              <p>
                Baseado no artigo 6.º, n.º 1, do RGPD o legítimo interesse da
                Improxy é realizar os negócios, atuais e de futuro, a favor do
                bem-estar de todos os seus funcionários e acionistas.
              </p>
              <p> </p>
              <p>
                <strong>Período de armazenamento dos dados pessoais</strong>
              </p>
              <p>
                O critério utilizado para determinar o período de armazenamento de
                dados pessoais é o respetivo período de retenção legal. Após o
                término desse período, os dados correspondentes são rotineiramente
                excluídos, desde que não sejam necessários para o cumprimento do
                contrato ou para o início de um contrato.
              </p>
              <p> </p>
              <p>
                <strong>
                  Fornecimento de dados pessoais como requisito obrigatório ou
                  contratual; Requisito necessário para celebrar um contrato;
                  Obrigação do titular dos dados em fornecer dados pessoais;
                  possíveis consequências da falha de fornecimento de tais dados.
                </strong>
              </p>
              <p>
                Esclarecemos que o fornecimento de dados pessoais é parcialmente
                exigido por lei (por exemplo, regulamentos fiscais) ou pode também
                resultar de cláusulas contratuais (por exemplo, informações sobre
                o parceiro contratual). Por vezes, para finalizar um contrato,
                pode ser necessário que o titular dos dados nos forneça dados
                pessoais, que devem ser posteriormente tratados pela Improxy. O
                titular dos dados é, por exemplo, obrigado a fornecer dados
                pessoais quando a Improxy assina um contrato com o titular. A não
                disponibilização dos dados pessoais terá como consequência a não
                conclusão do contrato. Antes dos dados pessoais serem fornecidos
                pelo titular dos dados, o titular dos dados deve entrar em contato
                com qualquer funcionário. O funcionário esclarece ao titular dos
                dados se a disposição dos dados pessoais é exigida por lei ou
                contrato ou se é necessária para a conclusão do contrato, se
                existe uma obrigação de fornecer os dados pessoais e as
                consequências da falta de cedência de dados para realização do
                contrato.
              </p>
              <p> </p>
              <p>
                <strong>Tomadores de decisão automáticos</strong>
              </p>
              <p>
                Como entidade responsável, não utilizamos tomadores de decisão
                automáticos nem de criação de perfis automáticos.
              </p>
              <p> </p>
              <p>
                <strong>Alterações nesta declaração</strong>
              </p>
              <p>
                Ocasionalmente, iremos atualizar a presente Política de
                Privacidade considerando os comentários da empresa e dos clientes.
                Aconselhamos a rever periodicamente a presente declaração para que
                fique atualizado sobre como a Improxy protege as suas informações.
              </p>
              <p> </p>
              <p>
                <strong>Informações de contacto</strong>
              </p>
              <p>
                O portal imobiliário Casa e Sol aprecia os seus comentários a
                respeito da presente Política de Privacidade. Se acredita que a
                Improxy não cumpriu com a presente declaração, entre em contacto
                connosco pelo e-mail dpo@improxy.com.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Master>
  )
};

export default {
  component: PrivacyPolicyPage
};
