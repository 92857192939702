import { SET_LANGUAGE } from '../actions/types';

let lang = 'pt';

const resourcesReducerDefaultState = {
  lang
};

export default (state = resourcesReducerDefaultState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      if (action.payload) {
        lang = action.payload;
      }

      return {
        lang
      };

    default:
      return state;
  }
};
