import React, { Component } from 'react';
import { ReCAPTCHA } from 'react-google-recaptcha';
import { googleSiteKey } from '../../assets/config';

class CaptchaField extends Component {
  componentDidMount() {
    this.refs.captcha.execute();
  }

  render() {
    return (
      <div>
        <ReCAPTCHA
          ref="captcha"
          size="invisible"
          sitekey={googleSiteKey}
          onChange={this.props.input.onChange}
        />
      </div>
    );
  }
}

export default CaptchaField;
