import { Handler, DefaultParameters, Modules, Actions, CountryID } from '../assets/config';
import { apiRequest } from './api';
import {
  HIGHLIGHTS_HAS_ERRORED,
  HIGHLIGHTS_IS_LOADING,
  HIGHLIGHTS_FETCH_DATA_SUCCESS,
  HIGHLIGHTS_CLEAR_DATA
} from './types';
import { recordsPerPage } from '../assets/constants';

function highlightsHasErrored(bool) {
  return {
    type: HIGHLIGHTS_HAS_ERRORED,
    hasErrored: bool
  };
}

function highlightsIsLoading(bool) {
  return {
    type: HIGHLIGHTS_IS_LOADING,
    isLoading: bool
  };
}

function highlightsFetchDataSuccess(highlights) {
  return {
    type: HIGHLIGHTS_FETCH_DATA_SUCCESS,
    highlights
  };
}

export function highlightsFetchData(filtersParameters = {}) {
  const moduleAction = {
    module: Modules.properties,
    action: Actions.list
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    recordsPerPage: recordsPerPage,
    pageNumber: 0,
    locationID: CountryID,
    ...filtersParameters
  };

  return async dispatch => {
    dispatch(highlightsIsLoading(true));
    await apiRequest(Handler, data)
      .then(response => {
        if (response.data.message) {
          throw Error(response.statusText);
        }
        dispatch(highlightsIsLoading(false));
        return response;
      })
      .then(response => response.data)
      .then(highlights => dispatch(highlightsFetchDataSuccess(highlights)))
      .catch(e => {
        dispatch(highlightsHasErrored(true));
        dispatch(highlightsIsLoading(false));
      });
  };
}

export function highlightsClearData() {
  return {
    type: HIGHLIGHTS_CLEAR_DATA
  };
}
