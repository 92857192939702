import {
  Handler,
  DefaultParameters,
  ContactDefaultParameters,
  Modules,
  Actions
} from '../assets/config';
import { currencySymbol, CountryID } from '../assets/config';
import { apiRequest } from './api';
import { reset } from 'redux-form';
import {
  ANNOUNCE_FORM_HAS_ERRORED,
  ANNOUNCE_FORM_IS_LOADING,
  ANNOUNCE_FORM_FETCH_DATA_SUCCESS
} from './types';
import { JsonUrlEncode, setLocation, numberWithSpaces } from '../assets/utils';
import { notifySuccess, notifyError } from '../components/elements/Notify';
import { formResources } from '../resources/resources';

function announceFormHasErrored(bool) {
  return {
    type: ANNOUNCE_FORM_HAS_ERRORED,
    hasErrored: bool
  };
}

function announceFormIsLoading(bool) {
  return {
    type: ANNOUNCE_FORM_IS_LOADING,
    isLoading: bool
  };
}

function announceFormFetchDataSuccess(formFeedback) {
  return {
    type: ANNOUNCE_FORM_FETCH_DATA_SUCCESS,
    formFeedback
  };
}

export function announceFormFetchData(values, dispatch) {
  let replacements = Object.assign({}, values, {
    property_purpose: values.property_purpose.label,
    property_type: values.property_type.label,
    property_district_id: values.property_district.value,
    property_district: values.property_district.label,
    property_county: values.property_county ? values.property_county.label : '',
    property_parish: values.property_parish ? values.property_parish.label : '',
    property_bedrooms: values.property_bedrooms.label,
    country_id: CountryID,
    property_description: values.property_description ? values.property_description : ''
  });
  const locationLevels = setLocation(
    replacements.property_district,
    replacements.property_county,
    replacements.property_parish
  );
  const price = `${numberWithSpaces(parseFloat(replacements.property_price))} ${currencySymbol}`;
  const area = `${numberWithSpaces(parseFloat(replacements.property_area))} m2`;

  replacements = {
    ...replacements,
    locationLevels: locationLevels,
    property_price: price,
    property_area: area
  };

  const moduleAction = {
    module: Modules.contacts,
    action: Actions.sendEmail
  };

  const data = {
    ...moduleAction,
    ...DefaultParameters,
    ...ContactDefaultParameters,
    template: 'sell_rent',
    replacements: JsonUrlEncode(replacements)
  };

  dispatch(announceFormIsLoading(true));
  return apiRequest(Handler, data)
    .then(response => {
      if (response.data.message) {
        throw Error(response.statusText);
      }
      dispatch(announceFormIsLoading(false));
      return response;
    })
    .then(response => response.data)
    .then(formFeedback => {
      switch (formFeedback) {
        case true:
          notifySuccess(formResources.feedback.success);
          dispatch(reset('announce'));
          break;
        default:
          notifyError(formResources.feedback.error);
          break;
      }
      dispatch(announceFormFetchDataSuccess(formFeedback));
    })
    .catch(e => {
      notifyError(formResources.feedback.error);
      dispatch(announceFormHasErrored(true));
      dispatch(announceFormIsLoading(false));
    });
}
