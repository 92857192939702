import { SEARCH_FETCH_DATA_SUCCESS } from '../actions/types';

const searchReducerDefaultState = {};

export function search(state = searchReducerDefaultState, action) {
  switch (action.type) {
    case SEARCH_FETCH_DATA_SUCCESS:
      const search = action.search;
      return {
        ...search
      };
    default:
      return state;
  }
}
