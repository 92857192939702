import React, { useState, Fragment } from 'react';
import ShowMoreText from 'react-show-more-text';
import { setDescription } from '../../assets/utils';
import { globalResources } from '../../resources/resources';

const PropertyDescription = ({ description }) => {
  const [textExpanded, setExpandedToggle] = useState(false);
  const toggleExpanded = () => setExpandedToggle(!textExpanded);

  const descriptionText = setDescription(description, 0);

  return (
    <Fragment>
      {descriptionText && (
        <div className="property-item-resume notranslate">
          <h4 className="translate">{globalResources.description}</h4>
          <ShowMoreText
            lines={10}
            more="Ler mais"
            less="Ler menos"
            anchorClass={textExpanded ? 'read-less translate' : 'read-more translate'}
            onClick={toggleExpanded}
            expanded={false}
          >
            <div className="translate">
              {descriptionText.split('\n').map((item, key) => {
                return (
                  <Fragment key={key}>
                    {item}
                    <br />
                  </Fragment>
                );
              })}
            </div>
          </ShowMoreText>
        </div>
      )}
    </Fragment>
  );
};

export default PropertyDescription;
