import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import Modal from 'react-modal';
import CaptchaField from '../google/CaptchaField';
import { formResources, globalResources } from '../../resources/resources';
import { suggestFormFetchData } from '../../actions/suggestForm';
import { RenderTextarea, RenderInput, required, email } from '../../assets/forms';
import { numberWithSpaces } from '../../assets/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

class SuggestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestFormIsOpen: false
    };
    this.openSuggestForm = this.openSuggestForm.bind(this);
    this.closeSuggestForm = this.closeSuggestForm.bind(this);
  }

  openSuggestForm() {
    this.setState({ suggestFormIsOpen: true });
  }

  closeSuggestForm() {
    this.setState({ suggestFormIsOpen: false });
  }

  render() {
    const { handleSubmit, submitting, property, isLoading } = this.props;
    const propertyData = {
      property_purpose: property.MAINPURPOSE,
      property_title: property.NOTE ? property.NOTE : property.TYPE,
      property_price:
        numberWithSpaces(property.MAINPURPOSE_PRICE) + ' ' + property.MAINPURPOSE_COIN,
      property_bedrooms: property.Bedrooms
        ? property.Bedrooms > 1
          ? property.Bedrooms + ' quartos'
          : property.Bedrooms + ' quarto'
        : '',
      property_district: property.LOCATIONSECONDLEVEL,
      property_county: property.LOCATIONTHIRDLEVEL,
      property_parish: property.LOCATIONFOURTHLEVEL,
      property_reference: property.Reference,
      property_image: property.mainphoto,
      property_url: property.PROPERTYID,
      property_condition: property.CONDITION,
      property_floor_area: property.FloorArea ? property.FloorArea : '',
      property_nett_area: property.NettArea ? property.NettArea : '',
      property_construction_year: property.ConstructionYear ? property.ConstructionYear : ''
    };

    window.recaptchaOptions = {
      useRecaptchaNet: true
    };

    Modal.setAppElement('body');

    return (
      <li>
        <FontAwesomeIcon
          title={globalResources.suggestForm}
          icon={faEnvelope}
          onClick={this.openSuggestForm}
        />
        <Modal
          isOpen={this.state.suggestFormIsOpen}
          onAfterOpen={this.afterOpenSuggestForm}
          onRequestClose={this.closeSuggestForm}
          className="SuggestFormModal"
          overlayClassName="Overlay"
          contentLabel="Suggest Form Modal"
        >
          <div className="contact-request-wrapper">
            <button className="close" onClick={this.closeSuggestForm}>
              X
            </button>
            <span>{globalResources.suggestForm}</span>
            <div className="property-request-contact">
              <form
                onSubmit={handleSubmit((values, dispatch) => {
                  suggestFormFetchData(values, dispatch, propertyData);
                  this.closeSuggestForm();
                })}
              >
                <div className="form-body">
                  <Field
                    name="from_name"
                    label={formResources.name}
                    validate={required}
                    component={RenderInput}
                  />
                  <Field
                    name="from_email"
                    label={formResources.email}
                    validate={[required, email]}
                    component={RenderInput}
                  />
                  <Field
                    name="to_name"
                    label={formResources.name_to}
                    validate={required}
                    component={RenderInput}
                  />
                  <Field
                    name="to_email"
                    label={formResources.email_to}
                    validate={[required, email]}
                    component={RenderInput}
                  />
                  <Field name="message" label={formResources.message} component={RenderTextarea} />
                </div>
                <div className="form-footer">
                  <Fragment>
                    <Field name="captcharesponse" component={CaptchaField} />
                    <button
                      type="submit"
                      disabled={submitting || isLoading}
                      className="btn-default btn-tertiary"
                    >
                      {formResources.send}
                    </button>
                  </Fragment>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </li>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasErrored: state.suggestFormHasErrored,
    isLoading: state.suggestFormIsLoading,
    feedback: state.suggestForm.feedback
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFormData: formData => dispatch(suggestFormFetchData(formData))
  };
};

export default reduxForm({ form: 'suggest', destroyOnUnmount: true })(
  connect(mapStateToProps, mapDispatchToProps)(SuggestForm)
);
